import React, { useEffect, useState } from "react";
import { Typography } from "@cuda-networks/bds-core";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import Tooltip from "@cuda-networks/bds-core/dist/Tooltip";
import { isStringNullOrEmpty } from "../../utility";
import { IAppState } from "../../store/store";
import { useSelector } from "react-redux";
import AccountIcon from "../Accounts/AccountIcon";
import MspType from "../../models/MspType";

const AccountIconsCell = (props: any) => {
  const mspAccounts = useSelector((state: IAppState) => state.accountState.mspAccounts);
  const [accName, setAccName] = useState("");

  useEffect(() => {
    if (props.showAccountName && props.accountName) {
      setAccName(props.accountName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mspAccounts, props]);

  if (props.showAccountName) {
    return (
      <td className={"AccountNameCell"}>
        {!isStringNullOrEmpty(accName) && (
          <Grid item container>
            <Grid container item xs={3} style={{ alignContent: "center" }}>
              <AccountIcon entityType={MspType.Customer} customClassName="centerVertically" />
            </Grid>
            <Grid container item xs={9}>
              <Tooltip title={accName}>
                <Typography variant="body1" gutterBottom noWrap className="centerVertically">
                  {accName}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        )}
      </td>
    );
  } else {
    return <td></td>;
  }
};

export default AccountIconsCell;

import MspType from "../../models/MspType";

export enum UserIconType {
  PartnerUser = 0,
  SubpartnerUser = 1,
  SmbUser = 2,
  BaUser = 3,
}

export const getUserIconType = (loggedInAccountId: number, loggedInAccountType: string, selectedAccountId: number, selectedAccountType: string, selectedAccountParentId: number, userExplicitId: number): number => {
  if (loggedInAccountType === MspType.Customer) {
    return UserIconType.SmbUser;
  }

  if (loggedInAccountType === MspType.BillingAggregator) {
    return UserIconType.BaUser;
  }

  if (loggedInAccountId === userExplicitId) {
    return UserIconType.PartnerUser;
  }

  if (loggedInAccountType === MspType.Partner) {
    if ((selectedAccountType === MspType.Subpartner && userExplicitId === selectedAccountId) || userExplicitId === selectedAccountParentId) {
      return UserIconType.SubpartnerUser;
    }
  }
  return UserIconType.SmbUser;
};

import IAccount from "../../../models/IAccount";
import MspType from "../../../models/MspType";
import IProduct from "../../../models/Products/IProduct";

export function getProductTableColumnsSettings(isBaLoggedIn: boolean, selectedAccount: IAccount | undefined, selectedAccountToFilterProductsForBA: IAccount | undefined) {
  let showEdit = true;
  let showBaColumns = false;
  let showSerialCol = false;
  let showContractCol = false;
  let colSpanOverageGroupSize = 1;
  let colSpanNameGroupSize = 4;
  let colSpanStatusGroupSize = 2;
  let showUnassignedProductIcon = false;
  let showDetailComponent = false;
  if (isBaLoggedIn) {
    ({ showEdit, colSpanStatusGroupSize, colSpanNameGroupSize, showBaColumns, showContractCol, colSpanOverageGroupSize, showUnassignedProductIcon, showSerialCol, showDetailComponent } = getProductTableColumnsSettingsWhenBa(selectedAccountToFilterProductsForBA, showBaColumns, showContractCol, colSpanOverageGroupSize));
  } else {
    ({ showSerialCol, showContractCol, colSpanNameGroupSize, showUnassignedProductIcon, colSpanStatusGroupSize, showDetailComponent } = showUnassignedProductIconWhenMsp(selectedAccount, showSerialCol, showContractCol, colSpanNameGroupSize, showUnassignedProductIcon, colSpanStatusGroupSize));
  }
  return { showEdit, showUnassignedProductIcon, showBaColumns, showSerialCol, showContractCol, colSpanOverageGroupSize, colSpanNameGroupSize, colSpanStatusGroupSize, showDetailComponent };
}

function showUnassignedProductIconWhenMsp(selectedAccount: IAccount | undefined, showSerialCol: boolean, showContractCol: boolean, colSpanNameGroupSize: number, showUnassignedProductIcon: boolean, colSpanStatusGroupSize: number) {
  if (selectedAccount) {
    if (selectedAccount.type === MspType.Customer) {
      showSerialCol = true;
    } else if (selectedAccount.type === MspType.Partner) {
      showContractCol = true;
    } else {
      colSpanNameGroupSize = 3;
      showUnassignedProductIcon = true;
      colSpanStatusGroupSize = 3;
    }
  }
  return { showSerialCol, showContractCol, colSpanNameGroupSize, showUnassignedProductIcon, colSpanStatusGroupSize, showDetailComponent: false };
}

function getProductTableColumnsSettingsWhenBa(selectedAccountToFilterProductsForBA: IAccount | undefined, showBaColumns: boolean, showContractCol: boolean, colSpanOverageGroupSize: number) {
  let colSpanStatusGroupSize = 1;
  let showUnassignedProductIcon = false;
  let showSerialCol = false;
  let showDetailComponent = true;
  let colSpanNameGroupSize = 5;
  if (selectedAccountToFilterProductsForBA && selectedAccountToFilterProductsForBA.type !== MspType.Partner) {
    colSpanNameGroupSize = 4;
    if (selectedAccountToFilterProductsForBA.type === MspType.Subpartner) {
      colSpanStatusGroupSize = 2;
      showUnassignedProductIcon = true;
    } else if (selectedAccountToFilterProductsForBA.type === MspType.Customer) {
      showSerialCol = true;
      showDetailComponent = false;
    }
  } else {
    showBaColumns = true;
    showContractCol = true;
    colSpanOverageGroupSize = 3;
  }
  return { showEdit: false, colSpanStatusGroupSize, colSpanNameGroupSize, showBaColumns, showContractCol, colSpanOverageGroupSize, showUnassignedProductIcon, showSerialCol, showDetailComponent };
}

export function getUpdatedStateForProducts(isBaLoggedIn: boolean, products: IProduct[]): IProduct[] {
  if (isBaLoggedIn) {
    return products.map((product: IProduct) => ({
      ...product,
      name: product.name + " (" + product.sku + ")",
    }));
  } else {
    return products;
  }
}

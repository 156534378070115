import React, { useState } from "react";
import AssignedProductsTable from "./AssignedProductsTable";
import { searchByAccountId } from "../../../Utilities/accountsHelper";
import { useDispatch, useSelector } from "react-redux";
import { getCustomers, setExpandedPartnerAction, setSelectedAccountAction } from "../../../actions/accountActions";
import { IAppState } from "../../../store/store";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, LinearProgress } from "@cuda-networks/bds-core";
import { enterKey, handleBackdropClick } from "../../../utility";
import IAccount from "../../../models/IAccount";
import { setViewMspAccountsAction } from "../../../actions/generalActions";

interface ISubmitDialogProps {
  onCancel: () => void;
  showDialog: boolean;
}

const AssignedProductsDialog: React.FC<ISubmitDialogProps> = ({ showDialog, onCancel }) => {
  const dispatch = useDispatch();
  const mspAccounts = useSelector((state: IAppState) => state.accountState.mspAccounts);
  const loadingSubpartnersWithProducts = useSelector((state: IAppState) => state.productState.loadingSubpartnersWithProducts);
  const [isFocused, setIsFocused] = useState(false);
  const [isSelectedSubpartnerLoading, setIsSelectedSubpartnerLoading] = useState(false);

  const loadChildren = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getCustomers(account.id, true));
      resolve(result);
    });

  const handleGoToAccount = (accountId: number): void => {
    const goToAcc = searchByAccountId(mspAccounts, accountId);
    if (goToAcc) {
      setIsSelectedSubpartnerLoading(true);
      loadChildren(goToAcc).then(result => {
        if (result) {
          dispatch(setViewMspAccountsAction(false));
          dispatch(setExpandedPartnerAction(goToAcc));
          dispatch(setSelectedAccountAction(goToAcc));
          onCancel();
        }
        setIsSelectedSubpartnerLoading(false);
      });
    }
  };

  const handleOnFocusedSearch = (isSearchbarFocused: boolean) => {
    setIsFocused(isSearchbarFocused);
  };

  return (
    <Dialog onKeyUp={(event: any) => (!isFocused ? enterKey(event, onCancel) : "")} data-testid="assignedProductsDialog" open={showDialog} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancel)} maxWidth="md">
      <DialogTitle data-testid="assignedProductsDialogTitle" style={{ backgroundColor: "primary" }}>
        Subpartners' Catalog
      </DialogTitle>
      <DialogContent>
        {loadingSubpartnersWithProducts ? (
          <div data-testid="loadingAssignedSubpartners">
            <LinearProgress />
          </div>
        ) : (
          <div data-testid="assignedProductsTable">
            <AssignedProductsTable onGoToAccount={handleGoToAccount} onFocusedSearch={handleOnFocusedSearch} showLoading={isSelectedSubpartnerLoading} />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <div style={{ flex: "1 0 0" }} />
        <Button data-testid="assignedProductsCloseButton" size="large" onClick={onCancel} disabled={isSelectedSubpartnerLoading}>
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignedProductsDialog;

export class NewProductBundles {
  bundles: string[];

  constructor() {
    this.bundles = ["BEO-ADVD-MSP", "BEO-PREM-MSP", "BEO-PRMP-MSP", "BEO-IMP1-MSP", "BEO-DFP1-MSP"];
  }

  getBundles(): string[] {
    return this.bundles;
  }

  isNewBundle(bundleSku: string | undefined): boolean {
    if (bundleSku) return this.bundles.includes(bundleSku);

    return false;
  }
}

import React from "react";
import * as CoreIcons from "@cuda-networks/bds-core/dist/Icons/Core";
import MspType from "../../models/MspType";

interface IAccountIconProps {
  entityType: string;
  customClassName?: string;
}

const AccountIcon: React.FC<IAccountIconProps> = ({ entityType, customClassName }) => {
  const classN = customClassName ?? "center";

  function getIcon() {
    switch (entityType) {
      case MspType.Customer:
        return (
          <div data-testid="customerIcon">
            <CoreIcons.UserGroup className={classN} />
          </div>
        );
      case MspType.Partner:
        return (
          <div data-testid="partnerIcon">
            <CoreIcons.User color={"primary"} className={classN}></CoreIcons.User>
          </div>
        );
      case MspType.Subpartner:
        return (
          <div data-testid="subpartnerIcon">
            <CoreIcons.User className={classN}></CoreIcons.User>
          </div>
        );
      case MspType.BillingAggregator:
        return (
          <div data-testid="aggregatorIcon">
            <CoreIcons.SupervisedUserCircle color={"primary"} className={classN}></CoreIcons.SupervisedUserCircle>
          </div>
        );
      default:
        return null;
    }
  }

  return <div className="AccountIcons">{getIcon()}</div>;
};

export default AccountIcon;

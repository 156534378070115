import { Typography, Grid, Tooltip } from "@cuda-networks/bds-core";
import IAccount from "../../models/IAccount";
import React, { useEffect, useState } from "react";
import IAccountSlim from "../../models/IAccountSlim";

interface IM365LinkIcon {
  account?: IAccount | IAccountSlim;
}

const M365LinkIcon: React.FC<IM365LinkIcon> = ({ account }) => {
  const [showLink, setShowLink] = useState(false);
  const [colorStyle, setColorStyle] = useState({ color: "red" });
  const [text, setText] = useState("Unlinked");

  useEffect(() => {
    setShowLink(account !== null && account?.m365AuthLinked !== undefined);
    if (account?.m365AuthLinked && account?.m365AuthLinked === 1) {
      setColorStyle({ color: "green" });
      setText("Linked");
    }
  }, [account]);

  const m365LinkButtonTooltipText = (account?: IAccount | IAccountSlim) => {
    if (account?.m365AuthLinked && account?.m365AuthLinked === 1) {
      return `Last updated on: ${account?.m365AuthUpdateTime}`;
    }
    return `A Product/Service requires a linked M365 account for accurate billing information.`;
  };

  if (showLink) {
    return (
      <Grid container item direction="row">
        <div className={"headerM365LinkUserStatus"}>
          <Tooltip title={m365LinkButtonTooltipText(account)}>
            <Typography data-testid="m365StatusTxt" variant="subtitle2" noWrap style={colorStyle}>
              {text}
            </Typography>
          </Tooltip>
        </div>
      </Grid>
    );
  } else {
    return null;
  }
};

export default M365LinkIcon;

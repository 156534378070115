import React from "react";

const BBSProductNameCell = (props: any) => {
  return (
    <td>
      <div>{props.dataItem.subname}</div>
      <div style={{ fontWeight: "lighter", fontSize: "10px" }}>{props.dataItem.name}</div>
    </td>
  );
};

export default BBSProductNameCell;

import { Grid, Typography } from "@cuda-networks/bds-core";
import React, { useEffect, useState } from "react";
import DropDown from "../../../DropDown";
import { setAddEditUserDialogWidth } from "../../../../Utilities/usersHelper";
import UserPrivileges from "./Privileges/UserPrivileges";
import MspType from "../../../../models/MspType";
import IAccount from "../../../../models/IAccount";
import UserRole, { IUserRoleValue, UserRoleValues } from "../../../../models/UserRole";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store/store";

interface IRolesStepProps {
  userParentAccount: IAccount | undefined;
  role: UserRole | string | undefined;
  roleError: string;
  handleRoleChange: (value: number) => void;
  isEditingLoggedInUser: boolean;
  isAddFirstUser: boolean;
  handleOnOpenDropdown: (value: boolean) => void;
  height: number;
  isActionInProgress: boolean;
}

const LabelValue = (label: any) => {
  return (
    <Typography variant="h6" style={{ paddingBottom: "10px", fontWeight: 500 }}>
      {label}
    </Typography>
  );
};

const RolesStep: React.FC<IRolesStepProps> = ({ userParentAccount, role, roleError, handleRoleChange, isEditingLoggedInUser, isAddFirstUser, handleOnOpenDropdown, height, isActionInProgress }) => {
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const [isReadOnlyUser, setIsReadOnlyUser] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState(getDropdownOptionIdFromUserRole(userParentAccount?.type, role));
  const availableRoles = getCurrentRoles(userParentAccount).map(x => x.label);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(isEditingLoggedInUser || isAddFirstUser || isReadOnlyUser || isActionInProgress);
  }, [isEditingLoggedInUser, isAddFirstUser, isReadOnlyUser, isActionInProgress]);

  useEffect(() => {
    if (userParentAccount?.type === MspType.Customer) {
      setIsReadOnlyUser(true);
      setSelectedRoleId(1);
      handleRoleChange(UserRole.ReadOnly);
    } else {
      setIsReadOnlyUser(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userParentAccount]);

  useEffect(() => {
    if (isAddFirstUser) {
      setSelectedRoleId(1);
      handleRoleChange(UserRole.Admin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddFirstUser]);

  const handleSelectionChanged = (value: number) => {
    setSelectedRoleId(value);
    handleRoleChange(getUserRoleFromDropdownSelectedOptionId(userParentAccount?.type, value));
  };

  return (
    <div data-testid="addEditLoginRolesPrivilegesStep" style={{ height: height }}>
      <Grid item container spacing={3}>
        <Grid container item xs={12} style={{ paddingTop: "20px", height: "80px" }}>
          <Grid xs={setAddEditUserDialogWidth("role")} item container>
            <Grid item xs={4} style={{ paddingRight: "8px" }}>
              <div data-testid="userRoleDropdown">
                <DropDown label={LabelValue("ROLE")} required={false} options={availableRoles} selectedOptionId={selectedRoleId} error={roleError} handleChange={handleSelectionChanged} disabled={disabled} onOpen={handleOnOpenDropdown} />
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} container direction="column" style={{ paddingTop: "15px" }}>
          <UserPrivileges role={role} isBaLoggedIn={userParentAccount?.type === MspType.BillingAggregator} isSubpartner={selectedAccount?.type === MspType.Subpartner!} isSubpartnerLoggedIn={mspAccountLoggedIn.type === MspType.Subpartner}></UserPrivileges>
        </Grid>
      </Grid>
    </div>
  );
};

export default RolesStep;

export function getCurrentRoles(account: IAccount | undefined): IUserRoleValue[] {
  let availableRoles: IUserRoleValue[] = UserRoleValues;
  if (account?.type === MspType.Customer) {
    availableRoles = UserRoleValues.filter(x => x.value === UserRole.ReadOnly);
  } else if (account?.type === MspType.BillingAggregator) {
    availableRoles = availableRoles.filter(x => x.value === UserRole.BillingAggregatorAdmin || x.value === UserRole.BillingAggregatorTech || x.value === UserRole.BillingAggregatorFinance);
  } else {
    availableRoles = availableRoles.filter(x => x.value !== UserRole.BillingAggregatorAdmin && x.value !== UserRole.BillingAggregatorTech && x.value !== UserRole.BillingAggregatorFinance && x.value !== UserRole.ReadOnly);
  }

  return availableRoles;
}

export function getDropdownOptionIdFromUserRole(userParentAccountType: string | undefined, role: string | UserRole | undefined): number {
  if (Number(role) === 0) {
    return 0;
  }
  switch (userParentAccountType) {
    case MspType.BillingAggregator: {
      return Number(role) - 5;
    }
    case MspType.Customer: {
      return Number(role) - 4;
    }
    case MspType.Partner:
    case MspType.Subpartner: {
      return Number(role);
    }
    default: {
      return 0;
    }
  }
}

export function getUserRoleFromDropdownSelectedOptionId(userParentAccountType: string | undefined, optionId: number): UserRole {
  if (optionId === 0) {
    return UserRole.Unknown;
  }
  switch (userParentAccountType) {
    case MspType.BillingAggregator: {
      return (optionId + 5) as UserRole;
    }
    case MspType.Customer: {
      return (optionId + 4) as UserRole;
    }
    case MspType.Partner:
    case MspType.Subpartner: {
      return optionId as UserRole;
    }
    default: {
      return UserRole.Unknown;
    }
  }
}

import React, { useState } from "react";
import IFilterCheckbox from "../../../models/IFilterCheckbox";
import { Checkbox, CheckboxLabel, TextField } from "@cuda-networks/bds-core";
import FilterAccountsOptions from "../../../models/FilterAccountsOptions";
import { Typography } from "@cuda-networks/bds-core";

interface IFilterAccountsCheckboxesProps {
  options: IFilterCheckbox[];
  userAssociatedWithThem?: string;
  userAssociatedWithThemError?: string;
  onCheckboxOptionsChange: (newCheckboxOptions: IFilterCheckbox[], index: number) => void;
  onLoginUserAssociatedWithThem?: (userAssociatedWithEmail: string) => void;
}
const FilterAccountsCheckboxes: React.FC<IFilterAccountsCheckboxesProps> = ({ options, onCheckboxOptionsChange, onLoginUserAssociatedWithThem, userAssociatedWithThem, userAssociatedWithThemError }) => {
  const [checkboxOptions, setCheckboxOptions] = useState(options);
  const [userAssociatedWithEmail, setUserAssociatedWithEmail] = useState(userAssociatedWithThem);

  const onToggleColumn = (id: number) => {
    const newCheckboxOptions = checkboxOptions.map((option: IFilterCheckbox, idx: any) => {
      if (idx === id) {
        return { ...option, checked: !option.checked };
      } else {
        return option;
      }
    });
    setCheckboxOptions(newCheckboxOptions);
    onCheckboxOptionsChange(newCheckboxOptions, id);
  };

  const handleLoginUserAssociatedWithThem = (value: string) => {
    setUserAssociatedWithEmail(value);
    if (onLoginUserAssociatedWithThem) {
      onLoginUserAssociatedWithThem(value);
    }
  };

  return (
    <div className={"k-column-list"} style={{ maxHeight: "400px" }}>
      {checkboxOptions.map((option: IFilterCheckbox, idx: any) => (
        <div key={option.label + idx + "div"}>
          <div className={"k-column-list-item"}>
            <span>
              <CheckboxLabel key={option.label + idx + "label"} label={option.label} size="small" disabled={option.disabled} control={<Checkbox key={option.label + idx + "checkbox"} data-testid="filterAccountsOption" size="medium" checked={option.checked} onChange={() => onToggleColumn(idx)} />} />
              {(option.label === FilterAccountsOptions.M365LinkedOnly || option.label === FilterAccountsOptions.M365UnlinkedOnly) && (
                <Typography variant="caption" className="m365OnlyCheckboxHelperText" data-testid="m365OnlyCheckboxHelperText">
                  (Results might take up to one minute)
                </Typography>
              )}
            </span>
          </div>
          {option.checked && option.label === FilterAccountsOptions.LoginUserAssociated && (
            <div style={{ paddingBottom: "25px" }}>
              <TextField data-testid="searchLoginUserAssociatedWithThem" fullWidth size={"large"} error={userAssociatedWithThemError!.length > 0} helperText={userAssociatedWithThemError} value={userAssociatedWithEmail} onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleLoginUserAssociatedWithThem(ev.target.value)} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FilterAccountsCheckboxes;

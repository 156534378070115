import React, { useEffect, useState } from "react";
import { Link } from "@cuda-networks/bds-core";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import LivePerson from "../Utilities/LivePerson";

const HelpFooter = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    LivePerson(() => {
      setScriptLoaded(true);
    });
  });

  return (
    <Grid container>
      <Grid item container xs={7}>
        <div>
          Questions?{" "}
          <Link href="https://www.barracudamsp.com/partners/support-and-enablement" target="_blank">
            Visit Partner Support and Enablement
          </Link>
          {scriptLoaded ? (
            <div style={{ float: "right" }}>
              <Grid item container>
                <Grid item>&nbsp;or&nbsp;</Grid>
                <Grid item style={{ marginTop: "3px" }}>
                  <div id="LP_DIV_1452506647525" className="LPMcontainer LPMoverlay" />
                </Grid>
                <Grid item>with a support representative.</Grid>
              </Grid>
            </div>
          ) : (
            "."
          )}
        </div>
      </Grid>
      <Grid item container spacing={2} xs={5} justifyContent="flex-end" direction="row">
        <Grid item>
          <Link href="https://smartermsp.com/" target="_blank">
            RSS Feed
          </Link>
        </Grid>
        <Grid item>
          <div className="verticalLine" />
        </Grid>
        <Grid item>
          <Link href="https://www.barracudamsp.com/about/news" target="_blank">
            News
          </Link>
        </Grid>
        <Grid item>
          <div className="verticalLine" />
        </Grid>
        <Grid item>
          <Link href="https://www.barracudamsp.com/partners/support-and-enablement" target="_blank">
            Contact Us
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HelpFooter;

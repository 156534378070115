import React, { useEffect, useState } from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import { LinearProgress, Link, Typography, Card, CardContent } from "@cuda-networks/bds-core";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/store";
import IntegrationsLogsTable from "./IntegrationsLogsTable";
import { getLogs24Hours, getNumberOfNotices } from "../../../Utilities/integrationsUtilities";

const IntegrationsLogsTab = () => {
  const loadingIntegrationLogs = useSelector((state: IAppState) => state.integrationsLogsState.loadingIntegrationLogs);
  const logsTableState = useSelector((state: IAppState) => state.integrationsLogsState.logsTableState);
  const integrationLogs = useSelector((state: IAppState) => state.integrationsLogsState.integrationLogs);
  const logsIn24Hours = getLogs24Hours(integrationLogs);
  const [filterValue, setFilterValue] = useState("");
  const integrationsLogsNotices = getNumberOfNotices(logsIn24Hours);

  const handleSetFilter = (value: string) => {
    setFilterValue(value);
  };

  useEffect(() => {
    setFilterValue("");
    // eslint-disable-next-line
  }, [logsTableState]);

  return (
    <Grid item container spacing={3} direction="column" className={"IntegrationsLogsTab"}>
      <Grid item xs={12}>
        {loadingIntegrationLogs ? (
          <div data-testid={"loadingIntegrationLogs"}>
            <LinearProgress />
          </div>
        ) : (
          <div>
            {logsIn24Hours.length > 0 && (
              <Grid container item xs={12} style={{ marginBottom: "25px" }}>
                <Card variant="outlined" elevation={0} style={{ textAlign: "left", border: 0, width: "100%" }}>
                  <CardContent style={{ backgroundColor: "whitesmoke" }}>
                    <Grid container item xs={12}>
                      <Grid container item xs={12}>
                        <Typography data-testid="logsInTheLast24HoursLbl" variant="body2" gutterBottom>
                          {logsIn24Hours.length} New Logs in the last 24 Hours
                        </Typography>
                      </Grid>
                      {integrationsLogsNotices.nrOfAccounts > 0 && (
                        <Grid container item xs={3}>
                          <Typography variant="body2" gutterBottom>
                            ACCOUNTS:{" "}
                            <Link href="#" variant="body2" onClick={() => handleSetFilter("Accounts")} data-testid="accountLogsInTheLast24HoursLbl">
                              {integrationsLogsNotices.nrOfAccounts} NEW
                            </Link>
                          </Typography>
                        </Grid>
                      )}
                      {integrationsLogsNotices.nrOfTickets > 0 && (
                        <Grid container item xs={3}>
                          <Typography variant="body2" gutterBottom>
                            TICKETS:{" "}
                            <Link href="#" variant="body2" onClick={() => handleSetFilter("Tickets")} data-testid="ticketsLogsInTheLast24HoursLbl">
                              {integrationsLogsNotices.nrOfTickets} NEW
                            </Link>
                          </Typography>
                        </Grid>
                      )}
                      {integrationsLogsNotices.nrOfBilling > 0 && (
                        <Grid container item xs={3}>
                          <Typography variant="body2" gutterBottom>
                            BILLING:{" "}
                            <Link href="#" variant="body2" onClick={() => handleSetFilter("Billing")} data-testid="billingLogsInTheLast24HoursLbl">
                              {integrationsLogsNotices.nrOfBilling} NEW
                            </Link>
                          </Typography>
                        </Grid>
                      )}
                      {(integrationsLogsNotices.nrOfAccounts > 0 || integrationsLogsNotices.nrOfTickets > 0 || integrationsLogsNotices.nrOfBilling > 0) && (
                        <Grid container item xs={3}>
                          <Typography variant="body2" gutterBottom>
                            <Link href="#" variant="body2" onClick={() => handleSetFilter("clearAll")} data-testid="clearFiltersForLogsInTheLast24HoursLbl">
                              CLEAR ALL FILTERS
                            </Link>
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}
            <Grid data-testid="integrationsLogsTable" container item xs={12} className={"enableHorizontalScrollbar"}>
              <IntegrationsLogsTable filterValue={filterValue} />
            </Grid>
          </div>
        )}
      </Grid>
    </Grid>
  );
};
export default IntegrationsLogsTab;

import React, { useState } from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store/store";
import { LinearProgress, Switch, SwitchLabel, CircularProgress } from "@cuda-networks/bds-core";
import InvoicesTable from "./InvoicesTable";
import ManageCreditCardsButton from "./ManageCreditCardsButton";
import { getCreditCards, setDefaultCard, setPaymentMethod, setRecurringCard } from "../../actions/financeActions";
import IAccount from "../../models/IAccount";
import IExistingCreditCard from "../../models/Invoices/IExistingCreditCard";
import { Backdrop } from "@material-ui/core";
import { PaymentMethod } from "../../models/Invoices/PaymentMethod";
import { getCountriesAction } from "../../actions/accountActions";
import TabTitle from "../TabTitle";
import DetailsTabs from "../../models/DetailsTabs";
import { ActionMessages, ActionTypes } from "../../actions/ActionTypes";
import { setSnackBarMessage } from "../../actions/generalActions";
import ActionMessageType from "../../models/ActionMessageType";

const FinanceTab = () => {
  const dispatch = useDispatch();
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const loadingInvoices = useSelector((state: IAppState) => state.financeState.loadingInvoices);
  const loadingPaymentMethod = useSelector((state: IAppState) => state.financeState.loadingPaymentMethod);
  const paymentMethod = useSelector((state: IAppState) => state.financeState.paymentMethod);
  const countries = useSelector((state: IAppState) => state.accountState.countries);

  const [isEditingPaymentMethod, setIsEditingPaymentMethod] = useState(false);
  const [showManageCardDialog, setShowManageCardDialog] = useState(false);
  const [isLoadingManageCreditCards, setIsLoadingManageCreditCards] = useState(false);

  const loadCreditCards = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const existingCreditCards = dispatch(getCreditCards(account));
      resolve(existingCreditCards);
    });

  const setDefaultCreditCard = (account: IAccount, cardToken: string) =>
    new Promise<any>((resolve, reject) => {
      const response = dispatch(setDefaultCard(account, cardToken));
      resolve(response);
    });

  const setRecurringCreditCard = (account: IAccount, cardToken: string) =>
    new Promise<any>((resolve, reject) => {
      const response = dispatch(setRecurringCard(account, cardToken));
      resolve(response);
    });

  const changePaymentMethod = (account: IAccount, paymentMethod: PaymentMethod) =>
    new Promise<any>((resolve, reject) => {
      const response = dispatch(setPaymentMethod(account, paymentMethod));
      resolve(response);
    });

  const loadCountries = () =>
    new Promise<any>((resolve, reject) => {
      const newAccountId = dispatch(getCountriesAction());
      resolve(newAccountId);
    });

  const handleChangePaymentMethod = (newValue: boolean): void => {
    if (selectedAccount) {
      setIsEditingPaymentMethod(true);
      loadCreditCards(selectedAccount).then(cards => {
        if (cards.length > 0) {
          let defaultCardIndex = cards.findIndex((x: IExistingCreditCard) => x.default === true);
          if (defaultCardIndex < 0) {
            setDefaultCreditCard(selectedAccount, cards[cards.length - 1].token).then(result => {
              setRecurringAndChangePayment(selectedAccount, cards[cards.length - 1].token);
            });
          } else {
            setRecurringAndChangePayment(selectedAccount, cards[defaultCardIndex].token);
          }
        } else {
          if (countries.length <= 0) {
            loadCountries().then(countriesResult => {
              setIsEditingPaymentMethod(false);
              setShowManageCardDialog(true);
            });
          } else {
            setIsEditingPaymentMethod(false);
            setShowManageCardDialog(true);
          }
        }

        function setRecurringAndChangePayment(selectedAccount: IAccount, cardToken: string) {
          setRecurringCreditCard(selectedAccount, cardToken).then(setRecurringResult => {
            if (setRecurringResult) {
              let newPaymentMethod = newValue === true ? PaymentMethod.ByCreditCard : PaymentMethod.ByInvoice;
              changePaymentMethod(selectedAccount, newPaymentMethod).then(changePaymentResult => {
                if (changePaymentResult) {
                  dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.SetPaymentMethod].successMessage, type: ActionMessageType.Success }));
                }
                setIsEditingPaymentMethod(false);
              });
            } else {
              setIsEditingPaymentMethod(false);
            }
          });
        }
      });
    }
  };

  const handleAddNewCard = () => {
    setShowManageCardDialog(false);
    if (selectedAccount) {
      setIsEditingPaymentMethod(true);
      changePaymentMethod(selectedAccount, PaymentMethod.ByCreditCard).then(changePaymentResult => {
        if (changePaymentResult) {
          dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.SetPaymentMethod].successMessage, type: ActionMessageType.Success }));
        }
        setIsEditingPaymentMethod(false);
      });
    }
  };

  const handleOnCancel = () => {
    setShowManageCardDialog(false);
    setIsEditingPaymentMethod(false);
  };

  const handleOnOpenMnanageCreditCards = (isLoading: boolean) => {
    setIsLoadingManageCreditCards(isLoading);
  };

  const handleOnOpenMakePayment = (isLoading: boolean) => {
    setIsLoadingManageCreditCards(isLoading);
  };

  return (
    <Grid item container spacing={3} direction="column" className={"financeTab"}>
      <Grid item container>
        <Grid container item xs={10}>
          <TabTitle tabType={DetailsTabs.Finance} item={selectedAccount} />
        </Grid>
        <Grid container item xs={12} direction="row" justifyContent="space-between" alignItems="flex-start">
          <div style={{ display: "inline-flex" }}>
            <SwitchLabel label={"Automatic Payment"} control={<Switch data-testid="automaticPayment" disabled={paymentMethod !== PaymentMethod.ByInvoice || isEditingPaymentMethod || loadingInvoices || loadingPaymentMethod || isLoadingManageCreditCards} checked={paymentMethod !== PaymentMethod.ByInvoice} onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleChangePaymentMethod(ev.target.checked)} />} style={{ marginLeft: "15px" }} />
            {(isEditingPaymentMethod || loadingPaymentMethod) && <CircularProgress size="25px" style={{ marginTop: "auto", marginBottom: "auto" }} />}
          </div>
          <ManageCreditCardsButton disabled={isEditingPaymentMethod || isLoadingManageCreditCards} isAddingFirstCard={showManageCardDialog} onAddFristNewCard={handleAddNewCard} onCancel={handleOnCancel} onOpenManageCreditCards={handleOnOpenMnanageCreditCards} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div style={{ position: "relative" }}>
          <Backdrop data-testid="loadingAutomaticPayment" className={"parentOpacity"} open={isEditingPaymentMethod || isLoadingManageCreditCards} style={{ position: "absolute", zIndex: 4000 }}></Backdrop>
          {loadingInvoices || loadingPaymentMethod ? (
            <div data-testid={"loadingInvoices"}>
              <LinearProgress />
            </div>
          ) : (
            <Grid data-testid="invoicesTable" container item xs={12} className={"enableHorizontalScrollbar"}>
              <InvoicesTable onOpenMakePayment={handleOnOpenMakePayment} />
            </Grid>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
export default FinanceTab;

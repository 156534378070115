import React, { useEffect, useState } from "react";
import { TextField, IconButton } from "@cuda-networks/bds-core";
import InputAdornment from "@material-ui/core/InputAdornment";
import * as CoreIcons from "@cuda-networks/bds-core/dist/Icons/Core";
import { useDispatch, useSelector } from "react-redux";
import { cancelLoadCustomerParentAndItsChildrenAction, cancelLoadPartnerChildrenAction, setAccountFiltersAction } from "../../../actions/accountActions";
import { IAppState } from "../../../store/store";
import { setshowEmptyPanelAction } from "../../../actions/accountActions";

interface IFilterAccountsByNameProps {
  disabled: boolean;
  placeHolder: string;
}

const FilterAccountsByName: React.FC<IFilterAccountsByNameProps> = ({ disabled, placeHolder }) => {
  const dispatch = useDispatch();
  const filters = useSelector((state: IAppState) => state.accountState.filters);
  const [showClearButton, setShowClearButton] = useState(false);
  const [customerName, setCustomerName] = useState("");

  useEffect(() => {
    if (filters !== undefined) {
      if (filters.name !== undefined && filters.name.length > 0) {
        setShowClearButton(filters.name.length > 0);
        setCustomerName(filters.name);
      } else {
        setShowClearButton(false);
        setCustomerName("");
      }
    }
  }, [filters]);

  const onAccountSearched = (value: string) => {
    setCustomerName(value);
    const searchedValue = value.trim();
    let newFilters = { ...filters, name: searchedValue };
    dispatch(setAccountFiltersAction(newFilters));
    setShowClearButton(searchedValue.length > 0);
    dispatch(setshowEmptyPanelAction(true));
  };

  const renderClearSearchButton = () => {
    let adornment = null;
    if (showClearButton === true) {
      adornment = (
        <InputAdornment position="start">
          <IconButton data-testid={"clearSearchAccounts"} onClick={onHandleClearSearch} disabled={disabled}>
            <CoreIcons.Close></CoreIcons.Close>
          </IconButton>
        </InputAdornment>
      );
    }
    return adornment;
  };

  const onHandleClearSearch = () => {
    onAccountSearched("");
    dispatch(cancelLoadCustomerParentAndItsChildrenAction());
    dispatch(cancelLoadPartnerChildrenAction());
  };

  return (
    <TextField
      data-testid="searchTxt"
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CoreIcons.Filter />
          </InputAdornment>
        ),
        endAdornment: <div style={{ display: "inline-block" }}>{renderClearSearchButton()}</div>,
      }}
      placeholder={placeHolder}
      size={"large"}
      disabled={disabled}
      value={customerName}
      onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => onAccountSearched(ev.target.value)}
      style={{ padding: "10px" }}
    />
  );
};

export default FilterAccountsByName;

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, LinearProgress } from "@cuda-networks/bds-core";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IIntegrationAccount from "../../../models/Integrations/IIntegrationAccount";
import IIntegrationCompany from "../../../models/Integrations/IIntegrationCompany";
import { IAppState } from "../../../store/store";
import { enterKey, handleBackdropClick } from "../../../utility";
import TruncatedText from "../../TruncatedText";
interface IIntegrationsAccountsActionDialogProps {
  onCancel: () => void;
  showIntegrationsAccountsActionDialog: boolean;
  isActionInProgress: boolean;
  selectedIntegraionAccount: IIntegrationAccount | undefined;
  action: string;
  onSubmitAction: (company?: IIntegrationCompany) => void;
  loadingCompanies: boolean;
}
const IntegrationsAccountsActionDialog: React.FC<IIntegrationsAccountsActionDialogProps> = ({ showIntegrationsAccountsActionDialog, onCancel, isActionInProgress, selectedIntegraionAccount, action, onSubmitAction, loadingCompanies }) => {
  const integrationAccountsCompanies = useSelector((state: IAppState) => state.integrationsAccountsState.integrationAccountsCompanies);
  const [selectedOption, setSelectedOption] = useState<IIntegrationCompany | null>();
  const [companyError, setCompanyError] = useState("");
  const [showConfirmButton, setShowConfirmButton] = useState(true);
  const [buttonVariant, setButtonVariant] = useState("text");
  const [buttonLabel, setButtonLabel] = useState("CANCEL");
  const [requiredFieldsFilled, setRequiredFieldsFilled] = useState(false);
  const handleChangeTextField = (value: any) => {
    setCompanyError("");
  };
  const handleChangeAutoComplete = (event: any, value: any) => {
    setCompanyError("");
    setSelectedOption(value);
  };
  const integrationAccountActionContent = () => {
    if (selectedIntegraionAccount) {
      if (action === "Unlink") {
        return (
          <Typography data-testid="unlinkMessage" variant="subtitle1" noWrap>
            Are you sure you want to unlink account
            <TruncatedText value={selectedIntegraionAccount.mspAccountName} bold={true} maxLength={20} />
            &nbsp;from company
            <TruncatedText value={selectedIntegraionAccount.companyName} bold={true} maxLength={20} />?
          </Typography>
        );
      } else {
        if (integrationAccountsCompanies.length !== 0) {
          return (
            <div>
              <Typography data-testid="linkActionLabel" variant="subtitle1" noWrap>
                Choose a ConnectWise company to link
                <TruncatedText value={selectedIntegraionAccount.mspAccountName} bold={true} maxLength={20} />?
              </Typography>
              <Autocomplete id="muiAutoComplete" data-testid="muiAutoComplete" disabled={isActionInProgress} noOptionsText={"No records available"} onChange={handleChangeAutoComplete} options={integrationAccountsCompanies} getOptionLabel={option => option.name} renderInput={params => <TextField {...params} error={companyError.length > 0} onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => handleChangeTextField(ev.target.value)} helperText={companyError} fullWidth />} />
            </div>
          );
        } else {
          return (
            <Typography data-testid="linkMessage" variant="subtitle1">
              No available companies. Additional companies (not currently mapped) must be added to ConnectWise Manage to appear here.
            </Typography>
          );
        }
      }
    }
  };
  useEffect(() => {
    if (integrationAccountsCompanies.length === 0 && action === "Link") {
      setShowConfirmButton(false);
      setButtonVariant("contained");
    } else {
      setButtonVariant("text");
      setShowConfirmButton(true);
    }
    // eslint-disable-next-line
  }, [integrationAccountsCompanies]);

  useEffect(() => {
    if (action === "Link") {
      setRequiredFieldsFilled(selectedOption ? false : true);
    }
    // eslint-disable-next-line
  }, [selectedOption]);

  useEffect(() => {
    if (integrationAccountsCompanies.length === 0 && loadingCompanies === false && action === "Link") {
      setButtonLabel("CLOSE");
    } else {
      setButtonLabel("CANCEL");
    }
    // eslint-disable-next-line
  }, [integrationAccountsCompanies, loadingCompanies]);

  const handleConfirmAction = () => {
    let isError = false;
    if (!selectedOption && action === "Link") {
      isError = true;
      setCompanyError("Select a company");
    }

    if (!isError) {
      if (action === "Link" && selectedOption) {
        onSubmitAction(selectedOption);
      } else {
        onSubmitAction();
      }
    }
  };

  return (
    <Dialog onKeyUp={(event: any) => enterKey(event, onCancel)} disableEscapeKeyDown={isActionInProgress} className="integrationAccountActionDialog" data-testid="integrationAccountActionDialog" open={showIntegrationsAccountsActionDialog} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancel)} maxWidth={"md"}>
      <div style={{ minWidth: "500px" }}>
        <DialogTitle data-testid="integrationAccountActionTitle"> {action.toUpperCase() + " ACCOUNT"} </DialogTitle>
        <DialogContent style={{ paddingTop: 26, paddingBottom: 19, paddingRight: 27, paddingLeft: 27 }}>
          {loadingCompanies ? (
            <div data-testid="loadingIntegrationAccountCompanies">
              <LinearProgress />
            </div>
          ) : (
            integrationAccountActionContent()
          )}
        </DialogContent>
        <DialogActions>
          <div>
            <Button data-testid="cancelIntegrationAccountDialog" variant={buttonVariant} size="large" disabled={isActionInProgress} onClick={onCancel}>
              {buttonLabel}
            </Button>
            {showConfirmButton && (
              <Button data-testid="confirmIntegrationAccountDialog" primary="true" isLoading={isActionInProgress} disabled={isActionInProgress || requiredFieldsFilled} type={"submit"} onClick={handleConfirmAction}>
                CONFIRM
              </Button>
            )}
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default IntegrationsAccountsActionDialog;

import { Reducer } from "redux";
import { IntegrationsAccountsActions, IntegrationsAccountsActionTypes } from "../../actions/integrations/integrationsAccountsActions";
import IIntegrationAccount from "../../models/Integrations/IIntegrationAccount";
import { State } from "@progress/kendo-data-query";
import IIntegrationCompany from "../../models/Integrations/IIntegrationCompany";

export interface IIntegrationsAccountsState {
  showUnlinkedAccounts: boolean;
  loadingIntegrationAccounts: boolean;
  loadingIntegrationAccountsDataStateChanged: boolean;
  integrationAccounts: IIntegrationAccount[];
  integrationAccountsCount: number;
  integrationUnlinkedAccountsCount: number;
  loadingIntegrationAccountsCanceled: boolean;
  integrationAccountsTableState: State;
  integrationAccountsCompanies: IIntegrationCompany[];
}

const initialIntegrationAccountsState: IIntegrationsAccountsState = {
  showUnlinkedAccounts: false,
  loadingIntegrationAccounts: false,
  loadingIntegrationAccountsDataStateChanged: false,
  integrationAccounts: [],
  integrationAccountsCount: 0,
  integrationUnlinkedAccountsCount: -1,
  loadingIntegrationAccountsCanceled: false,
  integrationAccountsTableState: { sort: [{ field: "mspAccountName", dir: "asc" }], take: 10, skip: 0, filter: undefined },
  integrationAccountsCompanies: [],
};

export const integrationsAccountsReducer: Reducer<IIntegrationsAccountsState, IntegrationsAccountsActions> = (state = initialIntegrationAccountsState, action: IntegrationsAccountsActions) => {
  switch (action.type) {
    case IntegrationsAccountsActionTypes.SET_SWOW_INTEGRATION_ACCOUNTS:
      return {
        ...state,
        showUnlinkedAccounts: action.showUnlinkedAccounts,
      };
    case IntegrationsAccountsActionTypes.GET_INTEGRATION_ACCOUNTS:
      return {
        ...state,
        loadingIntegrationAccounts: action.loadingIntegrationAccounts,
        integrationAccounts: action.integrationAccounts,
        integrationAccountsCount: action.integrationAccountsCount,
        integrationUnlinkedAccountsCount: action.integrationUnlinkedAccountsCount,
        loadingIntegrationAccountsDataStateChanged: action.loadingIntegrationAccountsDataStateChanged,
      };
    case IntegrationsAccountsActionTypes.SET_LOAD_INTEGRATION_ACCOUNTS_CANCELED:
      return {
        ...state,
        loadingIntegrationAccountsCanceled: action.loadingIntegrationAccountsCanceled,
      };
    case IntegrationsAccountsActionTypes.SET_INTEGRATION_ACCOUNTS_TABLE_PROPS:
      return {
        ...state,
        integrationAccountsTableState: action.integrationAccountsTableState,
      };
    case IntegrationsAccountsActionTypes.GET_INTEGRATION_ACCOUNTS_COMPANIES: {
      return {
        ...state,
        integrationAccountsCompanies: action.integrationAccountsCompanies,
      };
    }
    case IntegrationsAccountsActionTypes.LINK_ACCOUNT:
    case IntegrationsAccountsActionTypes.UNLINK_ACCOUNT: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

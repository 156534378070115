import { GridSize } from "@material-ui/core";
import produce from "immer";
import { IUserToUpdate } from "../components/Users/UsersTab";
import { AccountAccess } from "../models/AccountAccess";
import IAccount from "../models/IAccount";
import ILoggedUser from "../models/ILoggedUser";
import IUser from "../models/IUser";
import MspType from "../models/MspType";
import UserRole, { UserRoleValues } from "../models/UserRole";
import { searchByAccountId } from "./accountsHelper";
import { AccountAccessStepLabel, EntitlementsStepLabel, NameAndEmailStepLabel, RolesAndPrivilegesStepLabel } from "../businessLogic/components/Users/AddEditUser/AddEditLoginUserDialog";

export const updateUsersList = (currentUsers: IUser[], updatedUser: IUser, newRole: { role: string }): IUser[] => {
  const userToEdit = currentUsers.find(value => value.id === updatedUser.id);
  const userToEditIndex = currentUsers.findIndex(value => value.id === updatedUser.id);

  const roleId = UserRoleValues.find(value => value.authDbValue === newRole.role)?.value;
  const editedUser = {
    ...userToEdit,
    name: updatedUser.name,
    email: updatedUser.email,
    role: roleId,
    userManagement: updatedUser.userManagement,
    billingAdministration: updatedUser.billingAdministration,
    entitlements: updatedUser.entitlements,
    roleDisplay: getDisplayUserRoleById(roleId),
  };

  const nextStateUsers = produce(currentUsers, draft => {
    draft.splice(userToEditIndex, 1, editedUser as any);
  });
  return nextStateUsers;
};

export const buildPayloadToUpdateUser = (user: IUser, userToEdit: IUser, loggedUser: ILoggedUser): IUserToUpdate => {
  let updatedUser: IUserToUpdate = {
    updateRoleOrEntitlements: true,
  };

  if (userToEdit?.name === user.name && userToEdit?.email !== user.email) {
    updatedUser.email = user.email;
  } else if (userToEdit?.name !== user.name && userToEdit?.email === user.email) {
    updatedUser.name = user.name;
  } else if (userToEdit?.name !== user.name && userToEdit?.email !== user.email) {
    updatedUser.name = user.name;
    updatedUser.email = user.email;
  }
  updatedUser.updateRoleOrEntitlements = userToEdit?.id !== loggedUser.id;
  return updatedUser;
};

export const getAccountAccess = (account: IAccount): string => {
  switch (account.type) {
    case MspType.Partner:
      return AccountAccess.Global;
    case MspType.Subpartner:
      return AccountAccess.GlobalSubpartner;
    case MspType.Customer:
      return AccountAccess.Customer;
    default:
      throw new Error("Unknown account type");
  }
};

export const getAccountAccessForUserExplicitId = (mspAccounts: IAccount[], user: IUser): string => {
  const explicitAccount = searchByAccountId(mspAccounts, user.explicitAccountId);
  if (explicitAccount) {
    return getAccountAccess(explicitAccount);
  } else {
    return "";
  }
};

export function updateUsersListWithExtraInfo(usersToDisplay: IUser[], usersWithExtraInfo: IUser[]): IUser[] {
  let results: IUser[] = [];
  usersToDisplay.forEach((x: any) => {
    const extraDetailsUserIndex = usersWithExtraInfo.findIndex((y: any) => y.id === x.id);
    if (extraDetailsUserIndex > -1) {
      results.push({ ...x, role: usersWithExtraInfo[extraDetailsUserIndex].role, roleDisplay: usersWithExtraInfo[extraDetailsUserIndex].roleDisplay, billingAdministration: usersWithExtraInfo[extraDetailsUserIndex].billingAdministration, userManagement: usersWithExtraInfo[extraDetailsUserIndex].userManagement, entitlements: usersWithExtraInfo[extraDetailsUserIndex].entitlements, accountAccess: usersWithExtraInfo[extraDetailsUserIndex].accountAccess, errorWhileLoading: usersWithExtraInfo[extraDetailsUserIndex].errorWhileLoading });
    } else {
      results.push(x);
    }
  });
  return results;
}

export function isMspRole(role: string | UserRole | undefined) {
  if (role) {
    const val = UserRoleValues.find(value => value.authDbValue === role);
    if (val) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function getDisplayUserRoleByAuthDbValue(userAccount: any): string {
  if (isMspRole(userAccount?.roles[0])) {
    const x = UserRoleValues.find(value => value.authDbValue === userAccount?.roles[0])?.label;
    if (x !== undefined) {
      return x;
    } else {
      return userAccount?.roles[0];
    }
  } else {
    return userAccount?.roles[0];
  }
}

export function getRoleFromUserAccount(userAccount: any): UserRole {
  if (isMspRole(userAccount?.roles[0])) {
    const authDbValue = UserRoleValues.find(value => value.authDbValue === userAccount?.roles[0])?.value as UserRole;
    if (authDbValue !== undefined) {
      return authDbValue;
    }
  }
  return UserRole.Unknown;
}

export function getDisplayUserRoleById(roleId: number | undefined): string {
  if (roleId !== undefined && roleId >= UserRole.Admin && roleId <= UserRole.BillingAggregatorTech) {
    const x = UserRoleValues.find(value => value.value === roleId)?.label;
    if (x !== undefined) {
      return x;
    } else {
      return "";
    }
  } else {
    return "";
  }
}

export function getEditUserTooltip(user: IUser): string {
  if (user.errorWhileLoading !== undefined) {
    return user.errorWhileLoading;
  } else if (user.role === undefined || user.role === UserRole.Unknown) {
    return "This is not a MSP user";
  }
  return "";
}

export function computeUsersList(usersToDisplay: IUser[], newUser: IUser): IUser[] {
  const duplicateUserIndex = usersToDisplay.findIndex((y: any) => y.email === newUser.email);
  if (duplicateUserIndex > -1) {
    usersToDisplay.splice(duplicateUserIndex, 1, newUser);
    return [...usersToDisplay];
  } else {
    return [...usersToDisplay, newUser];
  }
}

export function getEditUserIsDisabled(user: IUser): boolean {
  return user.errorWhileLoading !== undefined || user.role === undefined || user.role === UserRole.Unknown;
}

export function setAddEditUserDialogWidth(element: string): GridSize {
  return element === "name" || element === "email" ? 8 : 12;
}

export function getLdapMfaStatuses(user: IUser): { ldapStatus: string; mfaStatus: string } {
  let ldapStatus = "";
  let mfaStatus = "";

  if (user.ldapLoginRequired !== undefined) {
    ldapStatus = user.ldapLoginRequired ? "Yes" : "No";
  }

  if (user.mfa !== undefined) {
    mfaStatus = user.mfa ? "Yes" : "No";
  }

  return {
    ldapStatus,
    mfaStatus,
  };
}

export function checkRequiredFieldForAddEditUser(stepName: string, userName: string, userEmail: string, role: UserRole | string | undefined) {
  switch (stepName) {
    case NameAndEmailStepLabel:
      return userName.length === 0 || userEmail.length === 0;
    case RolesAndPrivilegesStepLabel:
      return role !== undefined && role === UserRole.Unknown;
    case AccountAccessStepLabel:
    case EntitlementsStepLabel:
    default:
      return false;
  }
}

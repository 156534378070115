import { CityValidationRule, NameValidationRule, EmailValidationRule, PhoneValidationRule, StateValidationRule, ZipValidationRule, AccountCompanyNameValidationRule } from "../../../fieldsValidationRules";
import IAddress from "../../../models/IAddress";
import { isStringNullOrEmpty } from "../../../utility";

export interface IFieldValidator {
  name: string;
  error: string;
  index: number;
}

export const validateAddress = (address: IAddress, isContactAddress: boolean): IFieldValidator[] => {
  let errorFields: IFieldValidator[] = [];
  if (address.email.length < 1) {
    errorFields.push({ name: "email", index: 2, error: "Enter email" });
  } else if (!EmailValidationRule.TildeRegExpression.test(address.email)) {
    errorFields.push({ name: "email", index: 2, error: "Invalid email" });
  } else if (!EmailValidationRule.RegularExpression.test(address.email)) {
    errorFields.push({ name: "email", index: 2, error: "Invalid email" });
  }

  if (address.name.length < 1) {
    errorFields.push({ name: "name", index: 1, error: `Enter ${isContactAddress ? "contact" : "billing"} name` });
  } else if (!NameValidationRule.RegularExpression.test(address.name)) {
    errorFields.push({ name: "name", index: 1, error: `${isContactAddress ? "Contact" : "Billing"} name contains invalid characters` });
  }

  if (isContactAddress) {
    if (!address.company || address.company.length < 1) {
      errorFields.push({ name: "company", index: 3, error: "Enter company name" });
    } else if (address.company?.split(/\s+/).length > 5) {
      if (!AccountCompanyNameValidationRule.RegularExpressionMoreThanFiveWords.test(address.company)) {
        errorFields.push({ name: "company", index: 3, error: "Company name contains invalid characters" });
      }
    } else if (address.company?.split(/\s+/).length < 6) {
      if (!AccountCompanyNameValidationRule.RegularExpressionUnderFiveWords.test(address.company)) {
        errorFields.push({ name: "company", index: 3, error: "Company name contains invalid characters" });
      }
    }
  }

  if (address.phone.length < 1) {
    errorFields.push({ name: "phone", index: 9, error: "Enter phone number" });
  } else if (address.phone.length < PhoneValidationRule.MinLength) {
    errorFields.push({ name: "phone", index: 9, error: "Phone must have at least 10 characters" });
  } else if (address.phone.length > PhoneValidationRule.MaxLength) {
    errorFields.push({ name: "phone", index: 9, error: "Phone must have at most 16 characters" });
  } else if (!PhoneValidationRule.RegularExpression.test(address.phone)) {
    errorFields.push({ name: "phone", index: 9, error: "Invalid phone number" });
  }

  if (address.street.length < 1) {
    errorFields.push({ name: "street", index: 4, error: "Enter address" });
  } else if (address.street.indexOf("<") !== -1 || address.street.indexOf(">") !== -1) {
    errorFields.push({ name: "street", index: 4, error: "Address contains invalid characters" });
  }

  if (address.street2) {
    if (address.street2.indexOf("<") !== -1 || address.street2.indexOf(">") !== -1) {
      errorFields.push({ name: "street2", index: 4, error: "Address2 contains invalid characters" });
    }
  }
  if (address.street3) {
    if (address.street3.indexOf("<") !== -1 || address.street3.indexOf(">") !== -1) {
      errorFields.push({ name: "street3", index: 4, error: "Address3 contains invalid characters" });
    }
  }
  if (address.street4) {
    if (address.street4.indexOf("<") !== -1 || address.street4.indexOf(">") !== -1) {
      errorFields.push({ name: "street4", index: 4, error: "Address4 contains invalid characters" });
    }
  }

  if (address.city.length < 1) {
    errorFields.push({ name: "city", index: 5, error: "Enter city" });
  } else if (!CityValidationRule.RegularExpression.test(address.city)) {
    errorFields.push({ name: "city", index: 5, error: "City contains invalid characters" });
  }

  if (address.country === "") {
    errorFields.push({ name: "country", index: 6, error: "Select a country" });
  } else {
    if (address.country === "United States" || address.country === "Canada") {
      if (isStringNullOrEmpty(address.state)) {
        errorFields.push({ name: "state", index: 7, error: "Select a state" });
      } else if (!StateValidationRule.RegularExpression.test(address.state)) {
        errorFields.push({ name: "state", index: 7, error: "State contains invalid characters" });
      }
      errorFields.push(...zipValidation(address.zip, address.country));
    }
  }

  return errorFields;
};

export const validateEmailAddress = (email: string): boolean => {
  return email.length > 4 && EmailValidationRule.TildeRegExpression.test(email) && EmailValidationRule.RegularExpression.test(email);
};
function zipValidation(zip: string, countryCode: string): IFieldValidator[] {
  let results: IFieldValidator[] = [];
  if (zip.length < 1) {
    results.push({ name: "zip", index: 8, error: "The country you selected requires a valid Zip Code" });
  } else {
    if (countryCode === "United States") {
      if (!ZipValidationRule.USRegularExpression.test(zip)) {
        results.push({ name: "zip", index: 8, error: "The Zip Code is not in the correct format" });
      }
    } else if (countryCode === "Canada") {
      if (!ZipValidationRule.CARegularExpression.test(zip)) {
        results.push({ name: "zip", index: 8, error: "The Zip Code is not in the correct format" });
      }
    }
  }
  return results;
}

import React, { useEffect, useState } from "react";
import { MenuItem, TextField } from "@cuda-networks/bds-core";
import { isStringNullOrEmpty } from "../utility";

interface ISelectProps {
  label: string | React.ReactNode;
  options: string[];
  selectedOptionId: number;
  error: string;
  handleChange: (id: number) => void;
  onOpen?: (isOpen: boolean) => void;
  disabled: boolean;
  required?: boolean;
  className?: string;
  firstOption?: string;
  inputProps?: any;
  size?: string;
}

const DropDown: React.FC<ISelectProps> = ({ label, options, selectedOptionId, error, handleChange, disabled, required, onOpen, className, firstOption, inputProps, size }) => {
  const [dropdownRef, setDropdownRef] = useState<any>();
  const defaultOption = {
    value: firstOption ? firstOption : "--Select--",
    label: firstOption ? firstOption : "--Select--",
    idx: 0,
    isSelected: false,
    isDisabled: firstOption ? false : true,
  };
  const loadedOptions = options.map((s, index) => ({
    value: s,
    label: s,
    idx: index + 1,
    isSelected: false,
    isDisabled: false,
  }));
  const availableOptions = [defaultOption, ...loadedOptions];

  let selectedObject = availableOptions[selectedOptionId];
  const onHandleStateChange: any = (_e: any, newValue: any) => {
    selectedObject.isSelected = !selectedObject.isSelected;
    handleChange(Number(newValue.key.substring(2)));
  };
  if (selectedObject) {
    selectedObject.isSelected = !selectedObject.isSelected;
  }

  useEffect(() => {
    if (onOpen) {
      if (dropdownRef) {
        onOpen(true);
      } else {
        onOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);

  useEffect(() => {
    if (!isStringNullOrEmpty(className)) {
      let element = document.querySelector(".FocusActive .MuiInputBase-input") as HTMLElement | null;
      element?.focus();
    }
  }, [className]);

  return (
    <TextField select required={required === undefined || required === true} label={label} InputProps={inputProps} disabled={disabled} value={selectedObject?.label} onChange={onHandleStateChange} helperText={error} error={error.length > 0} variant="outlined" size={size === undefined || size?.length === 0 ? "small" : size} fullWidth className={className}>
      {availableOptions.map(availableOption => {
        return (
          <MenuItem
            ref={(element: any) => {
              setDropdownRef(element);
            }}
            key={availableOption.idx}
            value={availableOption.label}
            size={"small"}
            disabled={availableOption.isDisabled}
          >
            {availableOption.label}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default DropDown;

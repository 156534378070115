import { SortDescriptor } from "@progress/kendo-data-query";
import BackupConditionStatus from "../models/Integrations/BackupConditionStatus";
import { ConnectWiseFeaturesValues } from "../models/Integrations/ConnectWiseFeatures";
import ConnectWiseLogsError from "../models/Integrations/ConnectWiseLogsError";
import { ConnectWiseLogTypeValues } from "../models/Integrations/ConnectWiseLogType";
import IIntegrationAccount from "../models/Integrations/IIntegrationAccount";
import IIntegrationLog from "../models/Integrations/IIntegrationLog";
import IntegrationAccountStatus from "../models/Integrations/IntegrationAccountStatus";
import IntegrationStatus from "../models/Integrations/IntegrationStatus";
import IntegrationType from "../models/IntegrationType";

export function getIntegrationStatus(status: IntegrationStatus, type: IntegrationType): string {
  switch (status) {
    case IntegrationStatus.Active:
      return "Active";
    case IntegrationStatus.ConnectionError:
      return type === IntegrationType.ConnectWise ? "Cannot reach ConnectWise" : "Cannot reach Autotask";
    case IntegrationStatus.InvalidField:
      return type === IntegrationType.ConnectWise ? "Management Solution not found" : "Barracuda MSP Username field not found";
    case IntegrationStatus.NewLogs:
      return "New logs in the last 24 hours";
    case IntegrationStatus.InvalidCredentials:
      return type === IntegrationType.ConnectWise ? "ConnectWise credentials error" : "Autotask credentials error";
    case IntegrationStatus.ConnectWiseInvalidCompany:
      return "Company not found";
    case IntegrationStatus.ConnectWiseErrorDuplicateAccounts:
      return "Duplicate accounts error";
    case IntegrationStatus.Undefined:
    default:
      return "Not Setup";
  }
}

export function integrationLogsMapper(logs: any[]): IIntegrationLog[] {
  let integrationCwLogs: IIntegrationLog[] = [];
  logs.forEach((log: any) => {
    integrationCwLogs.push({
      id: log.id,
      integrationId: log.integrationId,
      date: new Date(log.timeStamp * 1e3),
      actionDescription: log.action,
      feature: ConnectWiseFeaturesValues.find(value => value.value.toString() === log.actionCode)?.label as string,
      type: ConnectWiseLogTypeValues.find(value => value.value.toString() === log.errorCode)?.label as string,
      description: log.errorDescription,
    });
  });

  return integrationCwLogs;
}

export function getLogFeatureMessage(actionCode: string): string {
  if (actionCode === "Billing") {
    return "billing information cannot be adjusted";
  } else if (actionCode === "Tickets") {
    return "tickets cannot be sent";
  } else {
    return "";
  }
}

export function processLogs(log: IIntegrationLog): string {
  try {
    let descriptionObj = JSON.parse(log.description);
    if (!descriptionObj.params?.details?.code) {
      switch (descriptionObj.class) {
        case ConnectWiseLogsError.ConnectWiseGenericError:
          return `ConnectWise reported a failure with the response ${descriptionObj.params?.message}.`;
        case ConnectWiseLogsError.ConnectWiseCommunicationFailureLogMessage:
        case ConnectWiseLogsError.ConnectWiseCredentialFailureLogMessage:
          return "Cannot connect to ConnectWise. Please ensure that your Company ID, URL, Login Name and Password are correct.";
        case ConnectWiseLogsError.ConnectWiseInvalidMngSlnLogMessage:
          return "The Management Solution entered cannot be found.  Please ensure your Management Solution is created in ConnectWise and entered correctly.";
        case ConnectWiseLogsError.ConnectWiseBadUsernameMapLogMessage:
          return `${descriptionObj.params?.username} is not currently associated with a company in ConnectWise and ${getLogFeatureMessage(log.feature)}.`;
        case ConnectWiseLogsError.ConnectWiseTicketUnmappedConditionStatusLogMessage:
          return `${getBackupConditionStatus(descriptionObj.params?.condition_status)} tickets are not currently mapped and will not be sent to ConnectWise.`;
        case ConnectWiseLogsError.ConnectWiseTicketOptionDoesNotExistLogMessage:
          return `The ${descriptionObj.params?.option} associated with ${getBackupConditionStatus(descriptionObj.params?.condition_status)} no longer exists in ConnectWise and a ${getBackupConditionStatus(descriptionObj.params?.condition_status)} ticket for ${descriptionObj.params?.username} could not be sent.`;
        case ConnectWiseLogsError.ConnectWiseTicketSuccessLogMessage:
          return `${getBackupConditionStatus(descriptionObj.params?.condition_status)} ticket successfully sent for ${descriptionObj.params?.username}.`;
        case ConnectWiseLogsError.ConnectWiseBillingInactiveCrossReference:
          return `${descriptionObj.params?.level} is not cross-referenced correctly in ConnectWise for ${descriptionObj.params?.type}.  Please ensure the level mapped to ${descriptionObj.params?.plan_name} exists in ConnectWise with the correct Product, Agreement type and Type ${descriptionObj.params?.type}.  Billing information cannot be sent to ConnectWise for ${descriptionObj.params?.username}.`;
        case ConnectWiseLogsError.ConnectWiseBillingMissingAgreement:
          return `The company associated with ${descriptionObj.params?.username} does not have the correct agreement created in ConnectWise and billing information cannot be adjusted.`;
        case ConnectWiseLogsError.ConnectWiseBillingLevelNotSet:
          return `${descriptionObj.params?.username} is set to the ${descriptionObj.params?.plan_name} plan which is not mapped to a Level.  Billing information cannot be sent to ConnectWise.`;
        case ConnectWiseLogsError.ConnectWiseBillingLevelNotSetCuda:
          return `${descriptionObj.params?.username} is set to the ${descriptionObj.params?.plan_name} service which is not mapped to a Level. Billing information cannot be sent to ConnectWise.`;
        case ConnectWiseLogsError.ConnectWiseFailedPastPlanMessage:
          return `A plan previously associated with ${descriptionObj.params?.username} is no longer mapped to a valid level.  Please ensure all past agreement additions are set to zero in ConnectWise and then reset ${descriptionObj.params?.username}'s previous plans from the Accounts tab in the Barracuda MSP portal.`;
        case ConnectWiseLogsError.ConnectWiseBillingSuccessLogMessage:
          return `${descriptionObj.params?.usage_source} billing information successfully adjusted for ${descriptionObj.params?.username}.`;
        case ConnectWiseLogsError.ConnectWiseBillingDuplicateAccountsLogMessage:
          return "An account in ConnectWise is linked to more than one Barracuda MSP account. Review the account page to determine and correct conflicts.";
        default:
          return "Unknown";
      }
    } else {
      return descriptionObj.params?.details?.message;
    }
  } catch (e) {
    return log.description;
  }
}

export function getBackupConditionStatus(conditionStatus: BackupConditionStatus): string {
  switch (conditionStatus) {
    case BackupConditionStatus.Passed:
      return "Backup Completed";
    case BackupConditionStatus.Running:
      return "Backup Running";
    case BackupConditionStatus.Warning:
      return "Backup Warning";
    case BackupConditionStatus.Failed:
      return "Backup Failed";
    case BackupConditionStatus.NoRecent:
    case BackupConditionStatus.ConnectWiseNoRecent:
      return "No Recent Backups";
    case BackupConditionStatus.Missed:
    case BackupConditionStatus.ConnectWiseMissesBackup:
      return "Backup Missed";
    default:
      return "Unknown";
  }
}

export function getCwAccountIntegrationStatus(status: IntegrationAccountStatus, type: IntegrationType): string {
  switch (status) {
    case IntegrationAccountStatus.Linked:
      return "Linked";
    case IntegrationAccountStatus.ResetPastPlans:
      return "Past Plans Error";
    default:
      return "Unlinked";
  }
}

export function getNumberOfNotices(integrationLogs: IIntegrationLog[]): any {
  let noticesObj = { nrOfAccounts: 0, nrOfTickets: 0, nrOfBilling: 0 };
  integrationLogs.forEach((logs: IIntegrationLog) => {
    if (logs.feature === "Accounts") {
      noticesObj.nrOfAccounts++;
    } else if (logs.feature === "Tickets") {
      noticesObj.nrOfTickets++;
    } else {
      noticesObj.nrOfBilling++;
    }
  });
  return noticesObj;
}

export function getIntegrationSorting(sort: Array<SortDescriptor> | undefined): { sortBy: string | undefined; asc: boolean | undefined } {
  let sortField: string | undefined;
  let ascending: boolean | undefined;
  if (sort && sort.length > 0) {
    sortField = sort[0].field;
    ascending = sort[0].dir === "asc";
  }
  return { sortBy: sortField, asc: ascending };
}

export function getIntegrationFiltering(filter: any | undefined): { forAccount: string | undefined; forCompany: string | undefined } {
  let filterForAccount: string | undefined = undefined;
  let filterForCompany: string | undefined = undefined;
  if (filter && filter.filters) {
    if (filter.filters.length !== 0) {
      filterForAccount = getFilterBy(filter, "mspAccountName");
      filterForCompany = getFilterBy(filter, "companyName");
    }
  }
  return { forAccount: filterForAccount, forCompany: filterForCompany };
}

function getFilterBy(filter: any, by: string): string | undefined {
  const filterLength = filter.filters.length;
  const filter1 = filter.filters[0].filters[0].field;
  if (filter1 === by) {
    return filter.filters[0].filters[0].value;
  } else {
    if (filterLength > 1) {
      const filter2 = filter.filters[1].filters[0].field;
      if (filter2 === by) {
        return filter.filters[1].filters[0].value;
      }
    }
  }
  return undefined;
}

export function isFilterOn(filterTable: any): boolean {
  const filterBy = getIntegrationFiltering(filterTable);
  return filtersPresent(filterBy);
}

export function isInLast24Hours(date: Date): boolean {
  const timeStamp = Math.round(new Date().getTime() / 1000);
  const timeStampYesterday = timeStamp - 24 * 3600;
  if (date.getTime() >= new Date(timeStampYesterday * 1000).getTime()) {
    return true;
  } else {
    return false;
  }
}

export function getLogs24Hours(logs: IIntegrationLog[]): IIntegrationLog[] {
  let result: IIntegrationLog[] = [];
  logs.forEach((log: IIntegrationLog) => {
    if (isInLast24Hours(new Date(log.date))) {
      result.push(log);
    }
  });
  return result;
}

export function filtersPresent(filterBy: { forAccount: string | undefined; forCompany: string | undefined }): boolean {
  return filterBy.forAccount !== undefined || filterBy.forCompany !== undefined;
}

export function getIntegrationAccountsActionName(integrationAccount: IIntegrationAccount | undefined): string {
  switch (integrationAccount?.status) {
    case IntegrationAccountStatus.Linked:
      return "Unlink";
    case IntegrationAccountStatus.Unlinked:
      return "Link";
    default:
      return "Reset past plans";
  }
}

import React from "react";

const ProductOveragesCell = (props: any) => {
  if (props.rowType === "groupHeader") {
    return null;
  }

  return <td className={props.dataItem.overages > 0 ? "" : "DarkRedColor"}>{props.dataItem.overages > 0 && "+" + props.dataItem.overages}</td>;
};

export default ProductOveragesCell;

import React from "react";
import DetailsTabs from "../models/DetailsTabs";
import { IDetailsTabs } from "./InfoPanel";
import ProductsTab from "./Products/ProductsTab";
import AccountDetailsTab from "./Accounts/AccountDetailsTab";
import FinanceTab from "./Finance/FinanceTab";
import ApiCredentialsTab from "./ApiCredentials/ApiCredentialsTab";
import UsersTab from "./Users/UsersTab";
import UserRole from "../models/UserRole";
import IAccount from "../models/IAccount";
import MspType from "../models/MspType";
import { getRoleFromUserAccount } from "../Utilities/usersHelper";

export const ProductsTabItem: IDetailsTabs = {
  id: 0,
  tab: {
    label: DetailsTabs.Products,
    value: <ProductsTab />,
  },
};

export const AccountDetailsTabItem: IDetailsTabs = {
  id: 0,
  tab: {
    label: DetailsTabs.AccountsDetails,
    value: <AccountDetailsTab />,
  },
};

export const UsersTabItem: IDetailsTabs = {
  id: 0,
  tab: {
    label: DetailsTabs.Users,
    value: <UsersTab />,
  },
};

export const FinanceTabItem: IDetailsTabs = {
  id: 0,
  tab: {
    label: DetailsTabs.Finance,
    value: <FinanceTab />,
  },
};

export const ApiCredentialsTabItem: IDetailsTabs = {
  id: 0,
  tab: {
    label: DetailsTabs.ApiCredentials,
    value: <ApiCredentialsTab />,
  },
};

export function getDisplayedTabs(mspAccountLoggedIn: IAccount, selectedAccount: IAccount | undefined): IDetailsTabs[] {
  if (selectedAccount === undefined) {
    return [];
  }
  const userRole = getRoleFromUserAccount(mspAccountLoggedIn);
  switch (mspAccountLoggedIn.type) {
    case MspType.BillingAggregator: {
      return getDisplayedTabsWhenLoginIsBillingAggregator(userRole, selectedAccount);
    }
    case MspType.Customer: {
      return getDisplayedTabsWhenLoginIsCustomer();
    }
    case MspType.Partner: {
      return getDisplayedTabsWhenLoginIsPartner(userRole, selectedAccount);
    }
    case MspType.Subpartner: {
      return getDisplayedTabsWhenLoginIsSubpartner(userRole, selectedAccount);
    }
  }
  return [];
}

function getDisplayedTabsWhenLoginIsBillingAggregator(userRole: UserRole, selectedAccount: IAccount): IDetailsTabs[] {
  switch (userRole) {
    case UserRole.BillingAggregatorAdmin: {
      if (selectedAccount.type === MspType.BillingAggregator) {
        return [ProductsTabItem, UsersTabItem, AccountDetailsTabItem, FinanceTabItem];
      }
      if (selectedAccount.type === MspType.Partner) {
        return [ProductsTabItem, AccountDetailsTabItem];
      }
      break;
    }
    case UserRole.BillingAggregatorFinance: {
      if (selectedAccount.type === MspType.BillingAggregator) {
        return [ProductsTabItem, AccountDetailsTabItem, FinanceTabItem];
      }
      if (selectedAccount.type === MspType.Partner) {
        return [ProductsTabItem, AccountDetailsTabItem];
      }
      break;
    }
    case UserRole.BillingAggregatorTech: {
      if (selectedAccount.type === MspType.BillingAggregator) {
        return [AccountDetailsTabItem];
      }
      if (selectedAccount.type === MspType.Partner) {
        return [ProductsTabItem, AccountDetailsTabItem];
      }
      break;
    }
  }
  return [];
}

function getDisplayedTabsWhenLoginIsCustomer(): IDetailsTabs[] {
  return [ProductsTabItem, AccountDetailsTabItem];
}

function getDisplayedTabsWhenLoginIsPartner(userRole: UserRole, selectedAccount: IAccount): IDetailsTabs[] {
  switch (userRole) {
    case UserRole.Admin: {
      if (selectedAccount.type === MspType.Partner) {
        return [ProductsTabItem, UsersTabItem, AccountDetailsTabItem, FinanceTabItem, ApiCredentialsTabItem];
      }
      if (selectedAccount.type === MspType.Subpartner || selectedAccount.type === MspType.Customer) {
        return [ProductsTabItem, UsersTabItem, AccountDetailsTabItem];
      }
      break;
    }
    case UserRole.Finance: {
      if (selectedAccount.type === MspType.Partner) {
        return [ProductsTabItem, AccountDetailsTabItem, FinanceTabItem];
      }
      if (selectedAccount.type === MspType.Subpartner || selectedAccount.type === MspType.Customer) {
        return [ProductsTabItem, AccountDetailsTabItem];
      }
      break;
    }
    case UserRole.Tech:
    case UserRole.TechDoNotDelete: {
      if (selectedAccount.type === MspType.Partner || selectedAccount.type === MspType.Subpartner || selectedAccount.type === MspType.Customer) {
        return [ProductsTabItem, AccountDetailsTabItem];
      }
      break;
    }
  }
  return [];
}

function getDisplayedTabsWhenLoginIsSubpartner(userRole: UserRole, selectedAccount: IAccount): IDetailsTabs[] {
  switch (userRole) {
    case UserRole.Admin: {
      if (selectedAccount.type === MspType.Subpartner) {
        return [ProductsTabItem, UsersTabItem, AccountDetailsTabItem, ApiCredentialsTabItem];
      }
      if (selectedAccount.type === MspType.Customer) {
        return [ProductsTabItem, UsersTabItem, AccountDetailsTabItem];
      }
      break;
    }
    case UserRole.Finance:
    case UserRole.Tech:
    case UserRole.TechDoNotDelete: {
      if (selectedAccount.type === MspType.Subpartner || selectedAccount.type === MspType.Customer) {
        return [ProductsTabItem, AccountDetailsTabItem];
      }
      break;
    }
  }
  return [];
}

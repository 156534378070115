import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Button, Grid, LinearProgress } from "@cuda-networks/bds-core";
import ApiCredentialsPanelDialog from "./ApiCredentialsPanelDialog";
import ApiCredentialsItem from "./ApiCredentialsItem";
import { IApiCredentialApplication, getApiCredentialApplications } from "../../actions/apiCredentialActions";
import { IAppState } from "../../store/store";
import TabTitle from "../TabTitle";
import DetailsTabs from "../../models/DetailsTabs";

interface IApiCredentialsPanelProps {}

const ApiCredentialsTab: React.FC<IApiCredentialsPanelProps> = () => {
  const dispatch = useDispatch();
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const applicationsToDisplay = useSelector((state: IAppState) => state.apiCredentialApplicationState.applicationsToDisplay);
  const loadingApplications = useSelector((state: IAppState) => state.apiCredentialApplicationState.loadingApplications);

  const [items, setItems] = useState<IApiCredentialApplication[]>([]);

  useEffect(() => {
    const justAddedApps = applicationsToDisplay.filter(value => value.justAdded === true);
    const oldApps = applicationsToDisplay.filter(value => value.justAdded === undefined);

    oldApps.sort((a, b) => {
      const clientSecretA = a.email.toLowerCase();
      const clientSecretB = b.email.toLowerCase();

      return clientSecretA > clientSecretB ? 1 : -1;
    });

    const combinedApps = [...justAddedApps, ...oldApps];

    setItems(combinedApps);
  }, [applicationsToDisplay]);

  useEffect(() => {
    dispatch(getApiCredentialApplications());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = () => {
    setDialogOpen(true);
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSave = (name: string, email: string, application: string) => {
    console.info(`app ${application} added.`);
  };

  return (
    // <Card>
    <>
      {/* <div style={{ margin: "20px" }}>
        <Grid container item xs={12} style={{ justifyContent: "space-between", alignItems: "center" }}>
          <Grid container item style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", padding: "1px" }}>
            <Typography data-testid="ApiCredentialsTitle" variant="h6" style={{ color: "#111111", fontWeight: 700 }}>
              API Credentials
            </Typography>
            <Typography data-testid="ApiCredentialsSubtitle" noWrap variant="h6" style={{ color: "#111111" }}>
              Explanation text for API Credentials goes here.
            </Typography>
          </Grid>
          <Grid container item style={{ flexDirection: "column", alignItems: "flex-end", alignSelf: "flex-end" }}>
            <Button data-testid="AddApiCredentialsButton" color="primary" variant="contained" onClick={onClick} style={{ marginRight: "5px" }}>
              ADD API CREDENTIALS
            </Button>
          </Grid>
        </Grid>
      </div> */}
      <Grid item container spacing={3} direction="column" className={"UsersTab"}>
        <Grid item container>
          <Grid container item xs={10}>
            <TabTitle tabType={DetailsTabs.ApiCredentials} item={selectedAccount} />
          </Grid>
          <Grid container item style={{ flexDirection: "column", alignItems: "flex-end", alignSelf: "flex-end" }}>
            <Button data-testid="AddApiCredentialsButton" color="primary" variant="contained" onClick={onClick} style={{ marginRight: "15px" }}>
              ADD API CREDENTIALS
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {loadingApplications ? (
          <div data-testid={"loadingApplications"} style={{ marginBottom: "15px", marginTop: "15px", marginRight: "0px" }}>
            <LinearProgress />
          </div>
        ) : (
          <>
            {items.length < 1 ? (
              <Typography data-testid={"noApiCredentials"}>No API Credentials generated yet</Typography>
            ) : (
              items.map((element: any) => {
                return <ApiCredentialsItem key={"apiCredentialsItem" + element.email + element.clientSecret} emailParam={element.email} nameParam={element.name} applicationParam={element.application} clientIdParam={element.clientId} clientSecretParam={element.clientSecret} justAdded={element.justAdded} showSecret={element.showSecret} />;
              })
            )}
          </>
        )}
      </Grid>

      <ApiCredentialsPanelDialog open={dialogOpen} onClose={handleDialogClose} onSave={handleSave} />
    </>
    // </Card>
  );
};

export default ApiCredentialsTab;

import { Grid, Link } from "@material-ui/core";
import React from "react";
import { Card, Typography, CardContent } from "@cuda-networks/bds-core";
import * as Favicons from "@cuda-networks/bds-core/dist/Logos/Favicons";
import AppsOutage from "../assets/AppsOutage.svg";

const ServiceUnavailable = () => {
  return (
    <div className="ServiceUnavailableContainerCentered">
      <Card data-testid="serviceUnavailableCard" className={"ServiceUnavailableCard"}>
        <CardContent style={{ height: 375 }}>
          <Grid container spacing={3} xs={12}>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item data-testid="FaviconApp">
                  <Favicons.FaviconApp className="FaviconAppIcon" />
                </Grid>
                <Grid item>
                  <div>
                    <Typography className="FavIconBarracudaText">Barracuda</Typography>
                    <Typography className="FavIconMSPAppText">MSP App</Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="ServiceUnavailableSpacer">
              <img src={AppsOutage} alt="Icon Apps Outage" />
            </Grid>
            <Grid item xs={12}>
              <Typography data-testid="serviceUnavailableMessage" variant="h4" className="ServiceUnavailableMessage">
                Services are currently unavailable.
              </Typography>
              <Typography data-testid="serviceUnavailableLinkMessage" variant="body1">
                For more details visit{" "}
                <Link href="https://status.barracuda.com" data-testid="serviceUnavailableLink" target="_blank" underline="always">
                  https://status.barracuda.com
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default ServiceUnavailable;

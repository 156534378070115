enum ConnectWiseLogsError {
  ConnectWiseCommunicationFailureLogMessage = "ConnectWiseCommunicationFailureLogMessage",
  ConnectWiseGenericError = "ConnectWiseGenericError",
  ConnectWiseCredentialFailureLogMessage = "ConnectWiseCredentialFailureLogMessage",
  ConnectWiseInvalidMngSlnLogMessage = "ConnectWiseInvalidMngSlnLogMessage",
  ConnectWiseBadUsernameMapLogMessage = "ConnectWiseBadUsernameMapLogMessage",
  ConnectWiseTicketUnmappedConditionStatusLogMessage = "ConnectWiseTicketUnmappedConditionStatusLogMessage",
  ConnectWiseTicketOptionDoesNotExistLogMessage = "ConnectWiseTicketOptionDoesNotExistLogMessage",
  ConnectWiseTicketSuccessLogMessage = "ConnectWiseTicketSuccessLogMessage",
  ConnectWiseBillingInactiveCrossReference = "ConnectWiseBillingInactiveCrossReference",
  ConnectWiseBillingMissingAgreement = "ConnectWiseBillingMissingAgreement",
  ConnectWiseBillingLevelNotSet = "ConnectWiseBillingLevelNotSet",
  ConnectWiseBillingLevelNotSetCuda = "ConnectWiseBillingLevelNotSetCuda",
  ConnectWiseFailedPastPlanMessage = "ConnectWiseFailedPastPlanMessage",
  ConnectWiseBillingSuccessLogMessage = "ConnectWiseBillingSuccessLogMessage",
  ConnectWiseBillingDuplicateAccountsLogMessage = "ConnectWiseBillingDuplicateAccountsLogMessage",
}

export default ConnectWiseLogsError;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import { Card, CardContent, CircularProgress, IconButton } from "@cuda-networks/bds-core";
import { Backdrop } from "@material-ui/core";
import AddEditAccountDialog from "./AddEditAccount/AddEditAccountDialog";
import MspType from "../../models/MspType";
import IAccount from "../../models/IAccount";
import AccountsTable, { resetCw } from "./AccountsTable";
import { IAppState } from "../../store/store";
import { addAccountAction, getCountriesAction, navigateToAccountAction, saveStateBeforeFilterAction, setDisplayCustomersByAccountId, setDisplayMSPAccountsAction, setExpandedPartnerAction, setM365AuthAction, setSelectedAccountAction, filterAccountsAction, setOwnerAccountFiltersAction, setAccountFiltersAction, setLoadingFilteringAccounts, cancelCurrentFilterAccountsAction } from "../../actions/accountActions";
import { setHasSubpartnersAction, setSelectedTabName, setSnackBarMessage, setViewBillingExclusionsListAction, setViewMspAccountsAction } from "../../actions/generalActions";
import DetailsTabs from "../../models/DetailsTabs";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import AddAccountButton from "./AddEditAccount/AddAccountButton";
import { getSettingsForNewAcount } from "../../businessLogic/components/Accounts/AccountPanel";
import { setButtonColor } from "../../utility";
import { handleM356Auth, getAccountTypeAsTitle, areFiltersActive } from "../../Utilities/accountsHelper";
import FilterAccountListDialog from "./FilterAccounts/FilterAccountListDialog";
import AccountFilterChips from "./FilterAccounts/AccountFilterChips";
import FilterAccountsByName from "./FilterAccounts/FilterAccountsByName";
import BackTo from "../BackTo";
import TooltipElement from "../Users/TooltipElement";
import * as CoreIcons from "@cuda-networks/bds-core/dist/Icons/Core";
import { computeProdSkuFilterOptions, getOrders, getOwnerAccountInfoForFiltering, shouldDisableFilter } from "../../businessLogic/components/Accounts/FilterAccounts/FilterAccountListDialog";
import { IAccountFilters } from "../../models/IAccountFilters";
import ProductFamily from "../../models/Products/ProductFamily";
import { getOrdersForAccountsFilteringAction } from "../../actions/productActions";
import IOrderSlim from "../../models/Products/IOrderSlim";
import { getAccountInfoAction } from "../../actions/accountActions";
import ActionMessageType from "../../models/ActionMessageType";
import { ActionMessages, ActionTypes } from "../../actions/ActionTypes";

const AccountPanel = () => {
  const dispatch = useDispatch();
  const loadedIntegration = useSelector((state: IAppState) => state.integrationsState.loadedIntegration);
  const viewSearchResults = useSelector((state: IAppState) => state.generalState.viewSearchResults);
  const viewMspAccounts = useSelector((state: IAppState) => state.generalState.viewMspAccounts);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const loadingFilteredAccounts = useSelector((state: IAppState) => state.accountState.loadingFilteredAccounts);
  const mspAccounts = useSelector((state: IAppState) => state.accountState.mspAccounts);
  const accountsNames = useSelector((state: IAppState) => state.accountState.accountsNames);
  const expandedPartner = useSelector((state: IAppState) => state.accountState.expandedPartner);
  const hasSubpartners = useSelector((state: IAppState) => state.generalState.hasSubpartners);
  const countries = useSelector((state: IAppState) => state.accountState.countries);
  const isBaLoggedIn = useSelector((state: IAppState) => state.generalState.isBaLoggedIn);
  const filters = useSelector((state: IAppState) => state.accountState.filters);
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [showAddAccount, setShowAddAccount] = useState(false);
  const [viewBackLink, setViewBackLink] = useState(false);
  const [title, setTitle] = useState("");
  const [showFilterAccount, setShowFilterAccount] = useState(false);
  const loadingAccountId = useSelector((state: IAppState) => state.accountState.loadingAccountId);
  const loadingPartnerAccounts = useSelector((state: IAppState) => state.accountState.loadingPartnerAccounts);
  const loadingOrdersForAccountId = useSelector((state: IAppState) => state.productState.loadingOrdersForAccountId);
  const [filterDisabled, setFilterDisabled] = useState(false);
  const accountsOrders = useSelector((state: IAppState) => state.productState.accountsOrders);

  const loadCountries = () =>
    new Promise<any>((resolve, reject) => {
      const newAccountId = dispatch(getCountriesAction());
      resolve(newAccountId);
    });

  const handleOpenDialog = () => {
    setShowAddAccount(!showAddAccount);
    if (countries.length <= 0) {
      setLoadingAdd(true);
      loadCountries().then(result => {
        setLoadingAdd(false);
      });
    }
  };

  const handleOnAddClicked = (partnerId: number, newEntity: IAccount, linkToM365?: boolean, notes?: string) => {
    setIsActionInProgress(true);
    addNewAccount(partnerId, newEntity, linkToM365, notes);
  };

  const handleOnCancel = () => {
    setShowAddAccount(!showAddAccount);
  };

  const addAccount = (partnerId: number, newEntity: IAccount, notes?: string) =>
    new Promise<any>((resolve, reject) => {
      const newAccountId = dispatch(addAccountAction(partnerId, newEntity, notes));
      resolve(newAccountId);
    });

  const connectM365Auth = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(setM365AuthAction(account));
      resolve(result);
    });

  const addNewAccount = (partnerId: number, newEntity: IAccount, linkToM365?: boolean, notes?: string) => {
    addAccount(partnerId, newEntity, notes).then(newAccountId => {
      const success = newAccountId > 0;
      const newAccount = { ...newEntity, id: newAccountId };
      if (linkToM365) {
        connectM365Auth(newAccount).then(successLink => {
          handleM356Auth(success, successLink, newAccount, handleAccountCreation);
        });
      } else {
        handleAccountCreation(success, newAccount, newAccountId);
      }
    });
  };

  const handleAccountCreation = (accountCreationSuccess: boolean, newAccount: IAccount, newAccountId: number) => {
    setIsActionInProgress(false);
    setShowAddAccount(!accountCreationSuccess);
    if (accountCreationSuccess) {
      dispatch(setSelectedAccountAction(newAccount));
      if (newAccount.type === MspType.Subpartner) {
        dispatch(setHasSubpartnersAction(true));
        dispatch(setViewMspAccountsAction(false));
        dispatch(setDisplayCustomersByAccountId(newAccount));
        dispatch(setExpandedPartnerAction({ ...newAccount, id: newAccountId }));
        dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.AddSubpartner].successMessage, type: ActionMessageType.Success }));
      } else {
        dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.AddAccount].successMessage, type: ActionMessageType.Success }));
      }
      dispatch(getAccountInfoAction(newAccount));
      dispatch(navigateToAccountAction(newAccount.id));
      dispatch(setSelectedTabName(DetailsTabs.AccountsDetails));
      dispatch(setViewBillingExclusionsListAction(false));

      if (loadedIntegration) {
        resetCw(dispatch);
      }
    }
  };

  const getOrdersForFilterOptions = (accountId: number) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getOrdersForAccountsFilteringAction(accountId));
      resolve(result);
    });

  const handleBackToAccounts = (event: React.SyntheticEvent) => {
    event.preventDefault();
    dispatch(setViewMspAccountsAction(true));
    const prevSelected = selectedAccount;
    const prevExpanded = expandedPartner;
    batch(() => {
      dispatch(setExpandedPartnerAction(undefined));
      dispatch(setSelectedAccountAction(undefined));
      dispatch(saveStateBeforeFilterAction(undefined));
      dispatch(setDisplayMSPAccountsAction());
      if (areFiltersActive(filters)) {
        dispatch(cancelCurrentFilterAccountsAction());
        dispatch(setOwnerAccountFiltersAction(mspAccountLoggedIn.id, false));
        if (filters?.csProdSkus || filters?.bbsProdSkus) {
          updateFiltersWithPartnerSkusAndFilter();
        } else {
          dispatch(filterAccountsAction());
        }
      } else {
        if (prevSelected) {
          if (prevSelected.type === MspType.Customer) {
            dispatch(navigateToAccountAction(prevSelected.partnerId));
          } else {
            dispatch(navigateToAccountAction(prevSelected.id));
          }
        } else {
          if (prevExpanded) {
            dispatch(navigateToAccountAction(prevExpanded.id));
          }
        }
      }

      function updateFiltersWithPartnerSkusAndFilter() {
        const currentOrders = getOrders(accountsOrders, mspAccountLoggedIn.id);
        if (currentOrders === undefined) {
          dispatch(setLoadingFilteringAccounts(true));
          getOrdersForFilterOptions(mspAccountLoggedIn.id).then(result => {
            if (result) {
              filterWithPartnerOrders(result);
            }
          });
        } else {
          filterWithPartnerOrders(currentOrders);
        }
      }

      function filterWithPartnerOrders(currentOrders: IOrderSlim[]) {
        let newFilters: IAccountFilters = { ...filters };
        if (filters?.csProdSkus !== undefined && filters.csProdSkus.length > 0) {
          const csSkus: string | undefined = computeProdSkuFilterOptions(currentOrders, ProductFamily.CONTENT_SHIELD);
          newFilters = { ...newFilters, csProdSkus: csSkus };
        }
        if (filters?.bbsProdSkus !== undefined && filters.bbsProdSkus.length > 0) {
          const bbsSkus: string | undefined = computeProdSkuFilterOptions(currentOrders, ProductFamily.BACKUP_APPLIANCES);
          newFilters = { ...newFilters, bbsProdSkus: bbsSkus };
        }
        dispatch(setAccountFiltersAction(newFilters));
        dispatch(filterAccountsAction());
      }
    });
  };

  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);

  const [addButtonId, setAddButtonId] = useState(0);
  const [parentOfNewAccount, setParentOfNewAccount] = useState(mspAccountLoggedIn);
  const [typeOfNewAccount, setTypeOfNewAccount] = useState(MspType.Subpartner);
  const [viewAddAccountButton, setViewAddAccountButton] = useState(false);
  const [addTitle, setAddTitle] = useState("");

  const onSelectedMspType = (newValue: MspType) => {
    setTypeOfNewAccount(newValue);
    setAddTitle("ADD " + getAccountTypeAsTitle(newValue));
  };

  useEffect(() => {
    if (!viewSearchResults && (selectedAccount || expandedPartner || !hasSubpartners)) {
      const { addAccountButtonType, parentOfNewAcc, mspTypeOfNewAccount, canAddAccounts, title } = getSettingsForNewAcount(mspAccounts, mspAccountLoggedIn, selectedAccount, expandedPartner);
      setAddButtonId(addAccountButtonType);
      setParentOfNewAccount(parentOfNewAcc);
      setTypeOfNewAccount(mspTypeOfNewAccount);
      setViewAddAccountButton(canAddAccounts);
      setAddTitle(title);
    } else {
      setViewAddAccountButton(false);
    }
  }, [hasSubpartners, viewMspAccounts, mspAccounts, accountsNames, selectedAccount, mspAccountLoggedIn, expandedPartner, viewSearchResults]);

  useEffect(() => {
    setViewBackLink(!viewMspAccounts);
  }, [viewMspAccounts]);

  useEffect(() => {
    if (filters !== undefined) {
      dispatch(filterAccountsAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    const ownerAccountInfo = getOwnerAccountInfoForFiltering(mspAccountLoggedIn, expandedPartner);
    dispatch(setOwnerAccountFiltersAction(ownerAccountInfo.accountId, ownerAccountInfo.directOnly));
    setTitle(ownerAccountInfo.info);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mspAccountLoggedIn, expandedPartner]);

  useEffect(() => {
    setFilterDisabled(shouldDisableFilter(loadingAccountId, loadingPartnerAccounts, loadingOrdersForAccountId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAccountId, loadingPartnerAccounts, loadingOrdersForAccountId]);

  const handleOpenFilterAccountDialog = () => {
    setShowFilterAccount(true);
  };

  const handleCancelFilterAccountDialog = () => {
    setShowFilterAccount(false);
  };

  return (
    <Card className={"AccountPanel"}>
      <CardContent>
        <Grid item container>
          <Grid item xs={isBaLoggedIn ? 12 : 11}>
            <FilterAccountsByName data-testid="search" disabled={filterDisabled} placeHolder={title} />
          </Grid>
          {!isBaLoggedIn && (
            <Grid item xs={1} style={{ alignSelf: "center" }}>
              <TooltipElement title="More Filter Options">
                <IconButton data-testid={"filterAccountsBtn"} onClick={handleOpenFilterAccountDialog} disabled={loadingAccountId !== undefined && loadingAccountId !== 0}>
                  <CoreIcons.MenuVertical></CoreIcons.MenuVertical>
                </IconButton>
              </TooltipElement>
            </Grid>
          )}
        </Grid>
        <Grid item container spacing={1} direction="column" style={{ paddingBottom: "10px" }}>
          <Grid item container>
            <Grid container item xs={12}>
              <AccountFilterChips disabled={filterDisabled} />
            </Grid>
            <Grid container item xs={6}>
              <Grid container>
                {viewBackLink && (
                  <Grid>
                    <BackTo text={viewSearchResults ? "View Results for all Accounts" : "all Accounts"} callback={handleBackToAccounts} testId="accountPanelLnk" enforceText={viewSearchResults} />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={6} style={{ justifyContent: "flex-end" }}>
              {viewAddAccountButton && !viewSearchResults && !loadingFilteredAccounts && (
                <Grid container item style={{ justifyContent: "flex-end" }}>
                  <AddAccountButton canCreateSubPartners={mspAccountLoggedIn.canCreateSubPartners} addButtonId={addButtonId} handleOpenDialog={handleOpenDialog} onSelectedMspType={onSelectedMspType} buttonColor={setButtonColor(selectedAccount)} />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container item xs={12} data-testid="accountsTable">
            <div style={{ position: "relative" }}>
              <Backdrop className={"parentOpacity"} open={loadingFilteredAccounts} style={{ position: "absolute", zIndex: 1300 }}>
                <CircularProgress data-testid="loadingFilteredAccounts" size="80px" style={{ zIndex: 1301 }} />
              </Backdrop>
              <AccountsTable showExpandIcon={viewMspAccounts} />
            </div>
          </Grid>
        </Grid>
        {showAddAccount && <AddEditAccountDialog dialogStep={0} showDialog={showAddAccount} onCancel={handleOnCancel} selectedItem={parentOfNewAccount} addressToDisplay={undefined} mspType={typeOfNewAccount} isActionInProgress={isActionInProgress} isEdit={false} onSubmit={(partnerId: number, entity: IAccount, linkToM365?: boolean, notes?: string) => handleOnAddClicked(partnerId, entity, linkToM365, notes)} title={addTitle} loadingAddEditDialog={loadingAdd} />}
        {showFilterAccount && <FilterAccountListDialog showDialog={showFilterAccount} onCancel={handleCancelFilterAccountDialog} actionInProgress={false} title={title} />}
      </CardContent>
    </Card>
  );
};

export default AccountPanel;

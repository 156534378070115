import { Button, Grid, Link, Typography } from "@material-ui/core";
import React from "react";
import GearsIcon from "../assets/icon_gears_200.png";
import LayersIcon from "../assets/icon_layers_200.png";
import ClipboardIcon from "../assets/icon_clipboard_200.png";

const LandingPage = () => {
  const imgStyle = {
    transform: "scale(0.4)",
  };
  const paragraph = {
    fontSize: "20px",
    color: "#4d4d4d",
    fontFamily: "ProximaNovaLight",
    fontWeight: "300",
    lineHeight: "27px",
  };
  const boxParagraph = {
    fontSize: "20px",
    color: "#4d4d4d",
    fontFamily: "ProximaNovaLight",
    fontWeight: "300",
    padding: "0px 91px 0px 91px",
  };
  return (
    <Grid container spacing={3} xs={12}>
      <Grid item style={{ textAlign: "center" }} xs={12}>
        <Typography data-testid="LandingPageTitle" variant="h3" style={{ color: "#0088ce", fontSize: "42px", fontFamily: "MuseoSlab3", fontWeight: "300" }}>
          Accelerate Your Path to MSP Success
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "left" }}>
        <Typography variant="h5" data-testid="LandingPageInfo1" style={paragraph}>
          To thrive in today's evolving cyberthreat landscape, you need to run your managed services business as a security-centric organization, and Barracuda MSP helps you do just that.
        </Typography>
        <Typography variant="h5" data-testid="LandingPageInfo2" style={paragraph}>
          With our combination of <b style={{ fontFamily: "ProximaNovaBold" }}> industry-leading security and data protection solutions, award-winning support </b> and <b style={{ fontFamily: "ProximaNovaBold" }}> MSP-friendly pricing</b>, scaling your business has never been easier. Join nearly 5,000 IT Service Providers worldwide who have partnered with Barracuda MSP on their security journey.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="button" data-testid="LandingPageInfoSubtitle" style={{ fontSize: "22px", color: "#666666", fontFamily: "MuseoSlab1", fontWeight: 700 }}>
          Take the security-centric approach.
        </Typography>
      </Grid>
      <Grid container item direction={"row"}>
        <Grid item xs={4}>
          <img src={GearsIcon} alt="Icon Gears" style={imgStyle} />
          <Typography variant="h5" data-testid="LandingPageInfoLabel1" style={boxParagraph}>
            Apply a security lens to <b style={{ fontFamily: "ProximaNovaBold" }}> internal operations </b> and <b style={{ fontFamily: "ProximaNovaBold" }}>service delivery</b> to keep both you and your customers protected.
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <img src={LayersIcon} alt="Icon Layers" style={imgStyle} />
          <Typography variant="h5" data-testid="LandingPageInfoLabel2" style={boxParagraph}>
            Offer <b style={{ fontFamily: "ProximaNovaBold" }}> comprehensive multi-layered security services</b> that protect data, devices, and users against today's most sophisticated threats.
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <img src={ClipboardIcon} alt="Icon Clipboard" style={imgStyle} />
          <Typography variant="h5" data-testid="LandingPageInfoLabel3" style={boxParagraph}>
            Continuously <b style={{ fontFamily: "ProximaNovaBold" }}> assess customer security postures</b> and proactively fill in the gaps. Peace of mind for them, revenue growth for you.
          </Typography>
        </Grid>
      </Grid>
      <Grid container item style={{ justifyContent: "center" }}>
        <Button size={"large"} data-testid="LandingPageVisitButton" color="primary" variant="contained" style={{ borderRadius: 30, width: 350 }}>
          <Link rel="noopener noreferrer" target="_blank" href="https://barracudamsp.com/" underline="none">
            <Typography variant="h6" style={{ fontSize: "16px", color: "white", fontFamily: "ProximaNovaBold" }}>
              VISIT BARRACUDAMSP.COM
            </Typography>
          </Link>
        </Button>
      </Grid>
    </Grid>
  );
};

export default LandingPage;

import { CancelTokenSource } from "axios";
import { Reducer } from "redux";
import { GeneralActionTypes, GeneralActions } from "../actions/generalActions";
import { ApiError } from "../errors/ApiError";
import ActionMessageType from "../models/ActionMessageType";
import DetailsTabs from "../models/DetailsTabs";
import IAccount from "../models/IAccount";
import ILoggedUser from "../models/ILoggedUser";
import ISnackBarMessage from "../models/ISnackBarMessage";
import CustomerMessagesType from "../models/CustomerMessagesType";

export interface IGeneralState {
  apiUrl: string;
  isMspLoggedIn: boolean;
  isBaLoggedIn: boolean;
  mspAccountLoggedIn: IAccount;
  selectedTabName: string;
  loading: boolean;
  noOfLoadedSubpartners: number;
  noOfSubpartners: number;
  errorMessage: string;
  snackBarMessage: ISnackBarMessage;
  viewMspAccounts: boolean;
  viewSearchResults: boolean;
  hasSubpartners: boolean;
  loggedUser: ILoggedUser;
  isDefaultUser: boolean | undefined;
  hasUsers: boolean | undefined;
  isBillingExclusionsDisplayed: boolean;
  cancellationTokenSource?: CancelTokenSource;
  apiError?: ApiError;
  viewIntegrations?: boolean;
  viewFilterResults: boolean;
  showPartnerResources?: boolean;
  echoUrl: string;
  showCustomerMessage: boolean;
  customerMessageType?: CustomerMessagesType;
}

const initialAccountState: IGeneralState = {
  apiUrl: "",
  isMspLoggedIn: false,
  isBaLoggedIn: false,
  mspAccountLoggedIn: {
    partnerId: 0,
    name: "",
    type: "",
    id: 0,
    accounts: [],
    userAdminFlag: false,
    userBillFlag: false,
    canCreateSubPartners: false,
    addresses: {
      isContactBillingSame: true,
      contact: {
        city: "",
        country: "",
        email: "",
        name: "",
        phone: "",
        state: "",
        street: "",
        zip: "",
      },
      billing: {
        city: "",
        country: "",
        email: "",
        name: "",
        phone: "",
        state: "",
        street: "",
        zip: "",
      },
    },
  },
  selectedTabName: DetailsTabs.Products,
  loading: false,
  noOfLoadedSubpartners: 0,
  noOfSubpartners: 0,
  errorMessage: "",
  snackBarMessage: { message: "", type: ActionMessageType.Info },
  viewMspAccounts: true,
  viewSearchResults: false,
  hasSubpartners: false,
  loggedUser: {
    id: 0,
    name: "",
    email: "",
    role: "",
  },
  isDefaultUser: undefined,
  hasUsers: undefined,
  isBillingExclusionsDisplayed: false,
  cancellationTokenSource: undefined,
  viewIntegrations: false,
  viewFilterResults: false,
  echoUrl: "",
  showCustomerMessage: false,
};

export const generalReducer: Reducer<IGeneralState, GeneralActions> = (state = initialAccountState, action: GeneralActions) => {
  switch (action.type) {
    case GeneralActionTypes.GET_API_ECHO_URL: {
      return {
        ...state,
        apiUrl: action.apiUrl,
        echoUrl: action.echoUrl,
      };
    }
    case GeneralActionTypes.LOAD_LOGGEDIN_MSP: {
      return {
        ...state,
        isMspLoggedIn: action.isMspLoggedIn,
        mspAccountLoggedIn: action.mspAccountLoggedIn,
        hasSubpartners: action.hasSubpartners,
        isBaLoggedIn: action.isBaLoggedIn,
      };
    }
    case GeneralActionTypes.SET_SELECTED_TAB: {
      return {
        ...state,
        selectedTabName: action.selectedTabName,
      };
    }
    case GeneralActionTypes.LOAD_LOGGED_USER: {
      return {
        ...state,
        loggedUser: action.loggedUser,
        isDefaultUser: action.isDefaultUser,
      };
    }
    case GeneralActionTypes.LOAD_ACTION: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case GeneralActionTypes.SET_NO_OF_LOADED_SUBPARTNERS: {
      return {
        ...state,
        noOfLoadedSubpartners: action.noOfLoadedSubpartners,
      };
    }
    case GeneralActionTypes.SET_NO_OF_SUBPARTNERS: {
      return {
        ...state,
        noOfSubpartners: action.noOfSubpartners,
      };
    }
    case GeneralActionTypes.ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    }
    case GeneralActionTypes.ERROR_AFTER_LOGIN: {
      return {
        ...state,
        apiError: action.apiError,
      };
    }
    case GeneralActionTypes.SET_SNACKBAR_MESSAGE: {
      return {
        ...state,
        snackBarMessage: action.snackBarMessage,
      };
    }
    case GeneralActionTypes.SET_VIEW_MPS_ACCOUNTS: {
      return {
        ...state,
        viewMspAccounts: action.viewMspAccounts,
      };
    }
    case GeneralActionTypes.SET_CUSTOMER_SEARCH_RESULTS: {
      return {
        ...state,
        viewSearchResults: action.viewSearchResults,
      };
    }
    case GeneralActionTypes.SET_HAS_SUBPARTNERS: {
      return {
        ...state,
        hasSubpartners: action.hasSubpartners,
      };
    }
    case GeneralActionTypes.SET_VIEW_BILLING_EXCLUSIONS_LIST: {
      return {
        ...state,
        isBillingExclusionsDisplayed: action.isBillingExclusionsDisplayed,
      };
    }
    case GeneralActionTypes.SET_CANCEL_TOKEN: {
      return {
        ...state,
        cancellationTokenSource: action.cancellationTokenSource,
      };
    }
    case GeneralActionTypes.GET_HAS_USERS: {
      return {
        ...state,
        hasUsers: action.hasUsers,
      };
    }
    case GeneralActionTypes.SET_VIEW_INTEGRATIONS: {
      return {
        ...state,
        viewIntegrations: action.viewIntegrations,
      };
    }
    case GeneralActionTypes.SET_CUSTOMER_FILTER_RESULTS: {
      return {
        ...state,
        viewFilterResults: action.viewFilterResults,
      };
    }
    case GeneralActionTypes.SET_SHOW_PARTNER_RESOURCES: {
      return {
        ...state,
        showPartnerResources: action.showPartnerResources,
      };
    }
    case GeneralActionTypes.SET_SHOW_CUSTOM_MESSAGE_PAGE: {
      return {
        ...state,
        showCustomerMessage: action.showCustomerMessage,
        customerMessageType: action.customerMessageType,
      };
    }
    default:
      return state;
  }
};

import MspType from "../models/MspType";

export enum ActionTypes {
  AddAccount,
  EditAccount,
  DeleteAccount,
  AddSubpartner,
  EditSubpartner,
  DeleteAubpartner,
  EditPartner,
  EditBillingAggregator,
  LinkAccountToM365,
  UnlinkAccountFromM365,
  AddLogin,
  EditLogin,
  DeleteLogin,
  AssignProduct,
  ActivateProduct,
  UnassignProduct,
  DeactivateSerial,
  RemoveSerial,
  ChangeService,
  RetryActivation,
  ExportCsvReport,
  SetPaymentMethod,
  SendEmailInvoice,
  AddCreditCard,
  EditCreditCard,
  DeleteCreditCard,
  PayInvoice,
  DeleteIntegration,
  EditIntegration,
  AddIntegration,
  LinkIntegrationAccount,
  UnlinkIntegrationAccount,
  ResetPastPlans,
  SetBillingMaps,
  ExcludeBillingUser,
  IncludeBillingUser,
  AddApiCredentials,
  RenewApiCredentials,
  DeleteApiCredentials,
}

export interface IActionMessages {
  successMessage: string;
  failMessage: string;
  infoMessage?: string;
}

export const ActionMessages: Record<ActionTypes, IActionMessages> = {
  [ActionTypes.AddAccount]: { successMessage: "Account successfully added.", failMessage: "Failed to add Account!" },
  [ActionTypes.EditAccount]: { successMessage: "Account successfully updated.", failMessage: "Failed to update Account!" },
  [ActionTypes.DeleteAccount]: { successMessage: "Account successfully deleted.", failMessage: "Failed to delete Account!" },
  [ActionTypes.AddSubpartner]: { successMessage: "Subpartner successfully added.", failMessage: "Failed to add Subpartner!" },
  [ActionTypes.EditSubpartner]: { successMessage: "Subpartner successfully updated.", failMessage: "Failed to update Subpartner!" },
  [ActionTypes.DeleteAubpartner]: { successMessage: "Subpartner successfully deleted.", failMessage: "Failed to delete Subpartner!" },
  [ActionTypes.EditPartner]: { successMessage: "Partner successfully updated.", failMessage: "Failed to update Partner!" },
  [ActionTypes.EditBillingAggregator]: { successMessage: "BillingAggregator successfully updated.", failMessage: "Failed to update Billing Aggregator!" },
  [ActionTypes.LinkAccountToM365]: { successMessage: "Account successfully linked to M365.", failMessage: "Failed to link account to M365!" },
  [ActionTypes.UnlinkAccountFromM365]: { successMessage: "Account successfully unlinked from M365.", failMessage: "Failed to unlink account from M365!" },
  [ActionTypes.AddLogin]: { successMessage: "Login user successfully added.", failMessage: "Failed to add login user!" },
  [ActionTypes.EditLogin]: { successMessage: "Login user successfully updated.", failMessage: "Failed to update login user!" },
  [ActionTypes.DeleteLogin]: { successMessage: "Login user successfully deleted.", failMessage: "Failed to delete login user!" },
  [ActionTypes.AssignProduct]: { successMessage: "Product/Service successfully assigned.", failMessage: "Failed to assign Product/Service!" },
  [ActionTypes.ActivateProduct]: { successMessage: "", failMessage: "Failed to start Product/Service activation process!", infoMessage: "Product/Service Activation Process Started." },
  [ActionTypes.UnassignProduct]: { successMessage: "Product/Service successfully unassigned.", failMessage: "Failed to unassign Product/Service!" },
  [ActionTypes.DeactivateSerial]: { successMessage: "Product/Service successfully deactivated.", failMessage: "Failed to deactivate Product/Service!" },
  [ActionTypes.RemoveSerial]: { successMessage: "Serial successfully removed.", failMessage: "Failed to remove serial!" },
  [ActionTypes.ChangeService]: { successMessage: "", failMessage: "Failed to change Service!", infoMessage: "Change of Service Process started." },
  [ActionTypes.RetryActivation]: { successMessage: "", failMessage: "Failed to retry activation!", infoMessage: "Product/Service Activation Process Started." },
  [ActionTypes.ExportCsvReport]: { successMessage: "", failMessage: "Failed to request Email CSV Report!", infoMessage: "Email CSV Report has been requested." },
  [ActionTypes.SetPaymentMethod]: { successMessage: "Payment Method successfully set.", failMessage: "Failed to set Payment Method!" },
  [ActionTypes.SendEmailInvoice]: { successMessage: "", failMessage: "Failed to request Email Invoice!", infoMessage: "Email Invoice has been requested." },
  [ActionTypes.AddCreditCard]: { successMessage: "Credit Card successfully added.", failMessage: "Failed to add credit card!" },
  [ActionTypes.EditCreditCard]: { successMessage: "Credit Card successfully updated.", failMessage: "Failed to update credit card!" },
  [ActionTypes.DeleteCreditCard]: { successMessage: "Credit Card successfully deleted.", failMessage: "Failed to delete credit card!" },
  [ActionTypes.PayInvoice]: { successMessage: "", failMessage: "Failed to process payment request!", infoMessage: "Payment request received. Thank you!" },
  [ActionTypes.DeleteIntegration]: { successMessage: "ConnectWise Integration successfully deleted.", failMessage: "Failed to delete ConnectWise Integration!" },
  [ActionTypes.EditIntegration]: { successMessage: "ConnectWise Integration successfully updated.", failMessage: "Failed to update ConnectWise Integration!" },
  [ActionTypes.AddIntegration]: { successMessage: "ConnectWise Integration successfully added.", failMessage: "Failed to add ConnectWise Integration!" },
  [ActionTypes.LinkIntegrationAccount]: { successMessage: "Integration account successfully linked.", failMessage: "Failed to link integration account!" },
  [ActionTypes.UnlinkIntegrationAccount]: { successMessage: "Integration account successfully unlinked.", failMessage: "Failed to unlink integration account!" },
  [ActionTypes.ResetPastPlans]: { successMessage: "Past Plans successfully reset.", failMessage: "Failed to reset Past Plans!" },
  [ActionTypes.SetBillingMaps]: { successMessage: "Set up Billing successfully updated", failMessage: "Failed to update Set up Billing!" },
  [ActionTypes.ExcludeBillingUser]: { successMessage: "Billing user successfully excluded.", failMessage: "Failed to exclude billing user!" },
  [ActionTypes.IncludeBillingUser]: { successMessage: "Billing user successfully included.", failMessage: "Failed to include billing user!" },
  [ActionTypes.AddApiCredentials]: { successMessage: "API Credentials successfully added.", failMessage: "Failed to add API Credentials!" },
  [ActionTypes.RenewApiCredentials]: { successMessage: "API Credentials successfully renewed.", failMessage: "Failed to renew API Credentials!" },
  [ActionTypes.DeleteApiCredentials]: { successMessage: "API Credentials successfully deleted.", failMessage: "Failed to delete API Credentials!" },
};

export function getEditAccountItemActionType(type: string): ActionTypes | undefined {
  switch (type) {
    case MspType.BillingAggregator:
      return ActionTypes.EditBillingAggregator;
    case MspType.Customer:
      return ActionTypes.EditAccount;
    case MspType.Partner:
      return ActionTypes.EditPartner;
    case MspType.Subpartner:
      return ActionTypes.EditSubpartner;
  }
}
export function getAddAccountItemActionType(type: string): ActionTypes | undefined {
  switch (type) {
    case MspType.Customer:
      return ActionTypes.AddAccount;
    case MspType.Subpartner:
      return ActionTypes.AddSubpartner;
  }
}

export function getDeleteAccountItemActionType(type: string): ActionTypes | undefined {
  switch (type) {
    case MspType.Customer:
      return ActionTypes.DeleteAccount;
    case MspType.Subpartner:
      return ActionTypes.DeleteAubpartner;
  }
}

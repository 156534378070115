import React, { useEffect, useMemo, useState } from "react";
import { DataTable, LinearProgress, DataTableColumn as Column } from "@cuda-networks/bds-core";
import { process, State } from "@progress/kendo-data-query";
import IProduct from "../../models/Products/IProduct";
import ButtonCommandCell from "../ButtonCommandCell";
import * as CoreIcons from "@cuda-networks/bds-core/dist/Icons/Core";
import ProductOveragesCell from "./ProductOveragesCell";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store/store";
import TooltipElement from "../Users/TooltipElement";
import { setTablePropsForCS, setTablePropsForESS } from "../../actions/productActions";
import IProductFamily from "../../models/Products/IProductFamily";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ProductFamily from "../../models/Products/ProductFamily";
import IconUnassignedCell from "./IconUnassignedCell";
import ProductStatusIconCell from "./ProductStatusIconCell";
import ProductStatusIcon from "./ProductStatusIcon";
import Pager from "@cuda-networks/bds-core/dist/DataTable/Pager";
import { getButtonCount } from "../../utility";
import { filterTextInput, getColorForColumn } from "../TableHelpers";
import { GridNoRecords } from "@progress/kendo-react-grid";
import { getProductTableColumnsSettings, getUpdatedStateForProducts } from "../../businessLogic/components/Products/ProductsTable";
import HeaderCell from "../HeaderCell";
import produce from "immer";
import PriceCell from "../PriceCell";
import UsersHeaderCell from "./UsersHeaderCell";
import SerialsTable from "./Serials/CustomSerialsTable";

interface IProductsTableProps {
  productFamily: IProductFamily;
  openEditDialog: (product: IProduct, selectedAccountType: string) => void;
}

const ProductsTable: React.FC<IProductsTableProps> = ({ productFamily, openEditDialog }) => {
  const essTableState = useSelector((state: IAppState) => state.productState.essTableState);
  const csTableState = useSelector((state: IAppState) => state.productState.csTableState);
  const tableState = productFamily.productType === ProductFamily.ESSENTIALS_SERIVICES ? essTableState : csTableState;

  const dispatch = useDispatch();
  const currency = useSelector((state: IAppState) => state.productState.currency);
  const loadingSerials = useSelector((state: IAppState) => state.productState.loadingProducts);
  const isBaLoggedIn = useSelector((state: IAppState) => state.generalState.isBaLoggedIn);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const selectedAccountToFilterProductsForBA = useSelector((state: IAppState) => state.accountState.selectedAccountToFilterProductsForBA);
  const [currentlySelectedAccountId, setCurrentlySelectedAccountId] = useState(0);
  const [width, setWidth] = useState(100);
  const [priceWidth, setPriceWidth] = useState(100);
  const [statusWidth, setStatusWidth] = useState(90);
  const [usersColWidth, setUsersColWidth] = useState(90);
  const [overagesColWidth, setOveragesColWidth] = useState(90);
  const [contractColWidth, setContractColWidth] = useState(90);
  const [serialWidth, setSerialWidth] = useState(300);
  const [editActionWidth, setEditActionWidth] = useState(120);
  const responsiveViewPortTrigger1600 = useMediaQuery("(max-width: 1600px)");
  const responsiveViewPortTrigger1400 = useMediaQuery("(max-width: 1400px)");
  const [buttonCount, setButtonCount] = useState(10);
  const [productsToDisplay, setProductsToDisplay] = useState(productFamily.products);
  const [showSerialColumn, setShowSerialColumn] = useState(false);
  const [showPriceColumns, setShowPriceColumns] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const [showContractColumns, setShowContractColumns] = useState(false);
  const [colSpanNameGroup, setColSpanNameGrup] = useState(0);
  const [colSpanStatusGroup, setColSpanStatusGrup] = useState(0);
  const [colSpanOverageGroup, setColSpanOverageGroup] = useState(0);
  const [showUnassignedProductIcon, setShowUnassignedProductIcon] = useState(false);
  const [showDetailComponent, setShowDetailComponent] = useState(false);

  useEffect(() => {
    if (responsiveViewPortTrigger1600 && !responsiveViewPortTrigger1400) {
      setPriceWidth(108);
      setWidth(108);
      setUsersColWidth(105);
      setOveragesColWidth(105);
      setContractColWidth(105);
      setStatusWidth(95);
      setEditActionWidth(80);
      setSerialWidth(100);
    } else if (responsiveViewPortTrigger1400) {
      setPriceWidth(70);
      setWidth(108);
      setUsersColWidth(75);
      setOveragesColWidth(90);
      setContractColWidth(90);
      setStatusWidth(75);
      setSerialWidth(110);
      setEditActionWidth(108);
      setStatusWidth(90);
    } else {
      setWidth(120);
      setPriceWidth(120);
      setUsersColWidth(120);
      setOveragesColWidth(120);
      setContractColWidth(120);
      setStatusWidth(110);
      setEditActionWidth(90);
      setSerialWidth(140);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsiveViewPortTrigger1600, responsiveViewPortTrigger1400]);

  useEffect(() => {
    const { showEdit, showUnassignedProductIcon, showBaColumns, showSerialCol, showContractCol, colSpanOverageGroupSize, colSpanNameGroupSize, colSpanStatusGroupSize, showDetailComponent } = getProductTableColumnsSettings(isBaLoggedIn, selectedAccount, selectedAccountToFilterProductsForBA);
    setShowEditButton(showEdit);
    setShowUnassignedProductIcon(showUnassignedProductIcon);
    setShowPriceColumns(showBaColumns);
    setShowSerialColumn(showSerialCol);
    setShowContractColumns(showContractCol);
    setColSpanStatusGrup(colSpanStatusGroupSize);
    setColSpanOverageGroup(colSpanOverageGroupSize);
    setColSpanNameGrup(colSpanNameGroupSize);
    setShowDetailComponent(showDetailComponent);
  }, [isBaLoggedIn, selectedAccount, selectedAccountToFilterProductsForBA]);

  const dataState = {
    skip: tableState.skip,
    take: tableState.take,
    group: [
      {
        field: "name",
        aggregates: [
          { field: "overages", aggregate: "max" },
          { field: "status", aggregate: "min" },
          { field: "noOfErrors", aggregate: "sum" },
          { field: "hasPendingSerials", aggregate: "sum" },
        ],
      },
    ],
    sort: tableState.sort,
    filter: tableState.filter,
    collapsedGroups: [],
    selectedItem: "any",
    lastSelectedIndex: 0,
    columns: [
      {
        title: "PRODUCT/SERVICE",
        field: "name",
        show: false,
        filter: "text",
      },
      {
        title: "PRODUCT/SERVICE",
        field: "subname",
        show: true,
        filter: "text",
      },
      {
        title: "SERIAL",
        field: "serial",
        show: false,
        filter: "text",
      },
      {
        title: "USERS",
        field: "users",
        show: false,
        filter: "text",
      },
      {
        title: "CONTRACT",
        field: "contract",
        show: false,
        filter: "text",
        headerClassName: "headerEditActionColumn",
      },
      {
        title: "Price",
        field: "price",
        show: false,
        filter: "text",
      },
      {
        title: "Overage Price",
        field: "overageRate",
        show: false,
        filter: "text",
      },
      {
        title: "Start Date",
        field: "effectiveDate",
        show: false,
        filter: "date",
      },
      {
        title: "End Date",
        field: "endDate",
        show: false,
        filter: "date",
      },
    ],
  };

  const [gridState, setGridState] = useState({
    dataState,
    dataResult: process(productsToDisplay, dataState as any),
  });

  useEffect(() => {
    setProductsToDisplay(getUpdatedStateForProducts(isBaLoggedIn, productFamily.products));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBaLoggedIn]);

  useEffect(() => {
    setGridState({ dataState: gridState.dataState, dataResult: process(productsToDisplay, gridState.dataState as any) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsToDisplay]);

  useEffect(() => {
    if (productFamily.productType === ProductFamily.ESSENTIALS_SERIVICES) {
      dispatch(setTablePropsForESS({ sort: gridState.dataState.sort, take: gridState.dataState.take, skip: gridState.dataState.skip, filter: gridState.dataState.filter }));
      if (gridState.dataState.take !== essTableState.take || gridState.dataState.skip !== essTableState.skip) {
        setGridState({ dataState: gridState.dataState, dataResult: process(productsToDisplay, gridState.dataState as any) });
      }
    } else if (productFamily.productType === ProductFamily.CONTENT_SHIELD) {
      dispatch(setTablePropsForCS({ sort: gridState.dataState.sort, take: gridState.dataState.take, skip: gridState.dataState.skip, filter: gridState.dataState.filter }));
      if (gridState.dataState.take !== csTableState.take || gridState.dataState.skip !== csTableState.skip) {
        setGridState({ dataState: gridState.dataState, dataResult: process(productsToDisplay, gridState.dataState as any) });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridState.dataState]);

  useEffect(() => {
    if (selectedAccount !== undefined && selectedAccount != null) {
      if (currentlySelectedAccountId !== selectedAccount?.id) {
        setCurrentlySelectedAccountId(selectedAccount.id);
        if (selectedAccount.id !== 0 && currentlySelectedAccountId !== 0) {
          const ds = { ...gridState.dataState, skip: 0 };
          setGridState({
            dataState: ds,
            dataResult: process(productsToDisplay, ds as any),
          });
          if (productFamily.productType === ProductFamily.ESSENTIALS_SERIVICES) {
            dispatch(setTablePropsForESS({ skip: 0 }));
          } else if (productFamily.productType === ProductFamily.CONTENT_SHIELD) {
            dispatch(setTablePropsForCS({ skip: 0 }));
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlySelectedAccountId, gridState.dataState, selectedAccount, productsToDisplay]);

  useEffect(() => {
    setButtonCount(getButtonCount(gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTrigger1600));
  }, [gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTrigger1600]);

  useEffect(() => {
    if (gridState.dataState.take && gridState.dataResult.total === gridState.dataState.skip) {
      let newSkip = gridState.dataState.skip - gridState.dataState.take;
      const ds = { ...gridState.dataState, skip: newSkip };
      setGridState({
        dataState: ds,
        dataResult: process(productsToDisplay, ds as any),
      });
      if (productFamily.productType === ProductFamily.ESSENTIALS_SERIVICES) {
        dispatch(setTablePropsForESS({ skip: newSkip }));
      } else if (productFamily.productType === ProductFamily.CONTENT_SHIELD) {
        dispatch(setTablePropsForCS({ skip: newSkip }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridState.dataResult, gridState.dataState, productsToDisplay]);

  const dataStateChange = (e: any): void => {
    setGridState({
      dataState: { ...dataState, ...e.dataState },
      dataResult: process(productsToDisplay, e.dataState),
    });
  };

  const expandChange = (e: any): void => {
    if (e.dataItem.aggregates) {
      e.dataItem[e.target.props.expandField] = e.value;
      const dataR = Object.assign({}, gridState.dataResult);
      setGridState({
        dataState: { ...dataState, ...e.dataState },
        dataResult: dataR,
      });
    } else {
      const ds = { ...gridState.dataState };
      const index = productsToDisplay.findIndex((x: IProduct) => x.id === e.dataItem.id);
      if (index > -1) {
        const nextStateProducts = produce(productsToDisplay, draft => {
          for (let i = 0; i < draft.length; i++) {
            if (i === index) {
              draft[i].expanded = e.value;
            }
          }
        });
        setProductsToDisplay(nextStateProducts);
        setGridState({
          dataState: ds,
          dataResult: process(nextStateProducts, ds as any),
        });
      }
    }
  };

  const enterEdit = (e: any): void => {
    if (selectedAccount) {
      openEditDialog(e, selectedAccount.type);
    }
  };

  const cellRender = (td: React.DetailedReactHTMLElement<any, HTMLElement>, props: { rowType: string; dataItem: { aggregates: { overages: { max: React.ReactNode }; status: { min: React.ReactNode }; noOfErrors: { sum: React.ReactNode }; hasPendingSerials: { sum: React.ReactNode }; price: { sum: React.ReactNode }; overageRate: { sum: React.ReactNode } } } }) => {
    if (td && td.props.children && props.rowType === "groupHeader") {
      let noOfErrors: number = 0;
      const hasPendingSerials: boolean = props?.dataItem?.aggregates?.hasPendingSerials?.sum as boolean;
      if (props?.dataItem?.aggregates?.noOfErrors?.sum) {
        noOfErrors = props?.dataItem?.aggregates?.noOfErrors?.sum as number;
      }

      const nameChildren = <span>{td.props.children.props.children} </span>;
      const nameProps = { ...td.props, colSpan: colSpanNameGroup };
      let nameElement = React.cloneElement(td, nameProps, nameChildren);
      nameElement = { ...nameElement, key: td.key + "nameElement" };

      const isOverageChildren = (
        <span>
          {Number(props.dataItem.aggregates.overages.max) > 0 && (
            <div data-testid={"overageIcon"}>
              <TooltipElement title="Has overages">
                <CoreIcons.Announcement />
              </TooltipElement>
            </div>
          )}
        </span>
      );
      const isOverageProps = { ...td.props, colSpan: colSpanOverageGroup };
      let isOverageElement = React.cloneElement(td, isOverageProps, isOverageChildren);
      isOverageElement = { ...isOverageElement, key: td.key + "overageElement" };

      const statusChildren = <span>{<ProductStatusIcon hasPendingSerials={hasPendingSerials} hasSerials={true} noOfErrors={noOfErrors} showActivatedIcon={false} showAvailableIcon={false}></ProductStatusIcon>}</span>;
      const statusProps = { ...td.props, colSpan: colSpanStatusGroup };
      let statusElement = React.createElement("td", statusProps, statusChildren);
      statusElement = { ...statusElement, key: td.key + "statusElement" };

      const showOverages = showContractColumns && isOverageElement;
      let newTd = (
        <React.Fragment>
          {nameElement}
          {showOverages}
          {statusElement}
        </React.Fragment>
      );
      newTd = { ...newTd, key: td.key + "headerElement" };
      return newTd;
    }
    return td;
  };

  const Header = (props: any) => <HeaderCell {...props} />;
  const UsersHeader = (props: any) => <UsersHeaderCell {...props} showM365Icon={productFamily.productType === ProductFamily.ESSENTIALS_SERIVICES} />;
  const ProductOverages = (props: any) => <ProductOveragesCell {...props} />;
  const Price = (props: any) => <PriceCell {...props} amount={props.dataItem.price} currency={currency} />;
  const OverageRate = (props: any) => <PriceCell {...props} amount={props.dataItem.overageRate} currency={currency} />;
  const ProductStatus = (props: any) => <ProductStatusIconCell {...props} />;
  const EditProduct = (props: any) => <ButtonCommandCell {...props} dataTestId={"editProduct"} id={"productsEditAction"} action={enterEdit} label={"EDIT"} isOutlined={false} color={"secondary"} />;
  const UnassignedIcon = (props: any) => <IconUnassignedCell {...props} />;

  const CachedProductDetailsComponent = useMemo(() => {
    const ProductDetailsComponent = (props: any) => <SerialsTable onGoToAccount={() => {}} showIssueDate={true} serials={props.dataItem.serials} productType={props.dataItem.type} />;
    return ProductDetailsComponent;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="overflowTableColumn">
      <DataTable
        className={"noScrollbar noBorders ProductsTable"}
        data={gridState.dataResult}
        resizable
        // page
        pageConfig={{
          pageable: {
            pageSizes: [10, 25, 50],
            buttonCount: buttonCount,
          },
          skip: gridState.dataState.skip,
          take: gridState.dataState.take,
          total: gridState.dataResult.total,
        }}
        groupable
        // sort
        sortConfig={{
          sortable: true,
          sort: tableState.skip,
        }}
        pager={gridState.dataResult.data.length > 0 && Pager}
        onExpandChange={expandChange}
        expandField="expanded"
        onDataStateChange={dataStateChange}
        selectedField="selected"
        cellRender={cellRender}
        detail={showDetailComponent ? CachedProductDetailsComponent : null}
        {...(gridState.dataState as any)}
        style={{ maxWidth: "100%" }}
      >
        <GridNoRecords>
          <div data-testid="loadingProducts">{loadingSerials ? <LinearProgress /> : "No records available"}</div>
        </GridNoRecords>
        {gridState.dataState.columns.map((column, idx) => column.show && <Column key={"products" + idx} field={column.field} minResizableWidth={30} title={column.title} filterable filter={"text"} columnMenu={filterTextInput} headerClassName={getColorForColumn(column.field, gridState.dataState as State)} headerCell={Header} />)}
        {showSerialColumn && <Column key={"entitlementSerial" + gridState.dataState.columns.length} title={"SERIAL"} minResizableWidth={30} width={serialWidth} field={"serial"} filterable filter={"text"} columnMenu={filterTextInput} headerClassName={getColorForColumn("serial", gridState.dataState as State)} headerCell={Header} />}
        <Column key={"entitlementUsers" + gridState.dataState.columns.length} title={"USERS"} minResizableWidth={30} width={usersColWidth} field={"users"} headerCell={UsersHeader} />
        {showContractColumns && <Column key={"entitlementContracts" + gridState.dataState.columns.length} title={"CONTRACT"} minResizableWidth={30} width={contractColWidth} field={"contract"} headerCell={Header} />}
        {showContractColumns && <Column key={"entitlementOverages" + gridState.dataState.columns.length} title={"OVERAGES"} minResizableWidth={30} width={overagesColWidth} field={"overages"} cell={ProductOverages} groupable={true} headerCell={Header} />}
        {showPriceColumns && <Column key={"entitlementPrice" + gridState.dataState.columns.length} title={"PRICE"} minResizableWidth={30} width={priceWidth} field={"price"} cell={Price} headerCell={Header} />}
        {showPriceColumns && <Column key={"entitlementOveragePrice" + gridState.dataState.columns.length} title={"OVERAGE PRICE"} minResizableWidth={30} width={priceWidth} field={"overageRate"} cell={OverageRate} headerCell={Header} />}
        <Column key={"entitlementStatus" + gridState.dataState.columns.length} title={"STATUS"} cell={ProductStatus} width={statusWidth} minResizableWidth={30} groupable={true} field={"noOfErrors"} headerCell={Header} />
        {showEditButton && <Column key={"entitlementActions" + gridState.dataState.columns.length} title={"ACTIONS"} headerClassName={"headerEditActionColumn"} minResizableWidth={30} cell={EditProduct} width={showUnassignedProductIcon ? editActionWidth : width} groupable={true} sortable={false} headerCell={Header} />}
        {showUnassignedProductIcon && <Column key={"entitlementUnassigned" + gridState.dataState.columns.length} title={""} cell={UnassignedIcon} minResizableWidth={30} resizable={false} width={30} groupable={true} sortable={false} headerCell={Header} />}
      </DataTable>
    </div>
  );
};

export default ProductsTable;

import React, { useEffect, useState } from "react";
import { DataTable, DataTableColumn as Column, Pager } from "@cuda-networks/bds-core";
import { CompositeFilterDescriptor, process, State } from "@progress/kendo-data-query";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { IAppState } from "../../../store/store";
import { getButtonCount, isStringNullOrEmpty } from "../../../utility";
import { filterDateInput, filterTextInput, getColorForColumn } from "../../TableHelpers";
import { getLogs24Hours, processLogs } from "../../../Utilities/integrationsUtilities";
import { setLogsTableProps } from "../../../actions/integrations/integrationsLogsActions";
import TooltipElement from "../../Users/TooltipElement";
import IntegrationDateAndTime from "./IntegrationDateAndTime";
import { FilterDropdownInput } from "../../FilterDropdownInput";
import IIntegrationLog from "../../../models/Integrations/IIntegrationLog";

interface IIntegrationsLogsTableProps {
  filterValue: string;
}

const IntegrationsLogsTable: React.FC<IIntegrationsLogsTableProps> = ({ filterValue }) => {
  const dispatch = useDispatch();
  const integrationLogs = useSelector((state: IAppState) => state.integrationsLogsState.integrationLogs);
  const selectedIntegration = useSelector((state: IAppState) => state.integrationsState.selectedIntegration);
  const logsTableState = useSelector((state: IAppState) => state.integrationsLogsState.logsTableState);
  const responsiveViewPortTriggerMin = useMediaQuery("(min-width: 1600px)");
  const logsIn24Hours = getLogs24Hours(integrationLogs);

  const [buttonCount, setButtonCount] = useState(10);
  const [currentlySelectedIntegrationId, setCurrentlySelectedIntegrationId] = useState(0);
  const dataState = {
    skip: logsTableState.skip,
    take: logsTableState.take,
    sort: logsTableState.sort,
    group: [],
    filter: logsTableState.filter,
    collapsedGroups: [],
    selectedItem: "any",
    lastSelectedIndex: 0,
    columns: [
      {
        title: "FEATURE",
        field: "feature",
        show: true,
        filter: "text",
        filtrable: true,
      },
      {
        title: "TYPE",
        field: "type",
        show: true,
        filter: "text",
        filtrable: true,
      },
      {
        title: "DESCRIPTION",
        field: "description",
        show: true,
        filter: "text",
        filtrable: true,
      },
      {
        title: "DATE AND TIME",
        field: "date",
        show: true,
        filter: "date",
        filtrable: true,
      },
    ],
  };

  const [gridState, setGridState] = useState({
    dataState,
    dataResult: process(integrationLogs, dataState as any),
  });

  const getIntegrationToDisplay = (logs: IIntegrationLog[], isUtcDate: boolean) => {
    const integrationLogsToDisplay = logs.map(log => ({
      ...log,
      description: processLogs(log),
      date: isUtcDate ? new Date(Date.UTC(log.date.getFullYear(), log.date.getMonth(), log.date.getDate())) : log.date,
      dateToDisplay: log.date,
    }));
    return integrationLogsToDisplay;
  };

  useEffect(() => {
    if (selectedIntegration) {
      const integrationLogsToDisplay = getIntegrationToDisplay(integrationLogs, true);
      dispatch(setLogsTableProps({ sort: gridState.dataState.sort, take: gridState.dataState.take, skip: gridState.dataState.skip, filter: gridState.dataState.filter }));
      setGridState({ dataState: gridState.dataState, dataResult: process(integrationLogsToDisplay, gridState.dataState as any) });
    }
    // eslint-disable-next-line
  }, [integrationLogs, gridState.dataState]);

  useEffect(() => {
    if (selectedIntegration !== undefined && selectedIntegration != null && selectedIntegration.id !== undefined) {
      if (currentlySelectedIntegrationId !== selectedIntegration.id) {
        setCurrentlySelectedIntegrationId(selectedIntegration.id);
        if (selectedIntegration.id !== 0) {
          const ds = { ...gridState.dataState, skip: 0 };
          setGridState({
            dataState: ds,
            dataResult: process(integrationLogs, ds as any),
          });
        }
      }
    }
  }, [currentlySelectedIntegrationId, gridState.dataState, selectedIntegration, integrationLogs]);

  const dataStateChange = (e: any): void => {
    if (selectedIntegration) {
      const displayLogs = process(integrationLogs, e.dataState);
      setGridState({
        dataState: { ...dataState, ...e.dataState },
        dataResult: displayLogs,
      });
    }
  };

  useEffect(() => {
    if (logsTableState.take && gridState.dataResult.total >= logsTableState.take) {
      if (logsTableState.skip === gridState.dataResult.total) {
        const ds = { ...gridState.dataState, skip: logsTableState.skip - logsTableState.take, take: logsTableState.take, filter: logsTableState.filter };
        setGridState({
          dataState: ds,
          dataResult: process(integrationLogs, ds as any),
        });
      }
    }
    // eslint-disable-next-line
  }, [gridState.dataResult.total]);

  useEffect(() => {
    setButtonCount(getButtonCount(gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin));
  }, [gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin]);

  const setGridFilter = () => {
    const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
    const utcYesterday = new Date(Date.UTC(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate()));
    let featureFilter: CompositeFilterDescriptor = {
      logic: "or",
      filters: [
        {
          field: "feature",
          operator: "eq",
          value: filterValue,
        },
      ],
    };
    let newLogsFilter: CompositeFilterDescriptor = {
      logic: "and",
      filters: [
        {
          field: "date",
          operator: "gte",
          value: utcYesterday,
        },
      ],
    };
    gridState.dataState.filter = {
      logic: "and",
      filters: [featureFilter, newLogsFilter],
    };
    gridState.dataState.skip = 0;
  };

  useEffect(() => {
    let integrationLogsToDisplay: IIntegrationLog[] = [];
    if (!isStringNullOrEmpty(filterValue)) {
      if (filterValue !== "clearAll") {
        if (gridState.dataState.filter === null) {
          setGridFilter();
        } else if (gridState.dataState.filter?.filters.filter((p: any) => p.logic === "or").length === 0) {
          setGridFilter();
        } else if (gridState.dataState.filter !== undefined && gridState.dataState.filter !== null) {
          if (gridState.dataState.filter.filters.length > 0) {
            setGridFilter();
          }
        }
        integrationLogsToDisplay = getIntegrationToDisplay(logsIn24Hours, false);
      } else {
        if (gridState.dataState.filter) {
          gridState.dataState.filter.filters = [];
          gridState.dataState.skip = 0;
          integrationLogsToDisplay = getIntegrationToDisplay(integrationLogs, false);
        }
      }
    }
    const ds = { ...gridState.dataState, filter: gridState.dataState.filter };
    dispatch(setLogsTableProps({ filter: gridState.dataState.filter }));
    setGridState({ dataState: ds, dataResult: process(integrationLogsToDisplay, ds as any) });
    // eslint-disable-next-line
  }, [filterValue]);

  useEffect(() => {
    if (filterValue === "LogsInLast24h") {
      let newLogsFilterIndex = gridState.dataState.filter?.filters.findIndex((t: any) => t.filters.find((x: any) => x.field === "date"));
      if (newLogsFilterIndex === undefined || newLogsFilterIndex < 0) {
        const integrationLogsToDisplay = getIntegrationToDisplay(integrationLogs, false);
        setGridState({ dataState: gridState.dataState, dataResult: process(integrationLogsToDisplay, gridState.dataState as any) });
      }
    }
    // eslint-disable-next-line
  }, [gridState.dataState.filter]);

  const TooltipTable = (props: any, tooltipType: any) => <TooltipElement {...props} tooltipType={"table"} />;
  const getLogsTableColumnWidth = (field: string) => {
    if (field === "feature" || field === "type") {
      if (responsiveViewPortTriggerMin) {
        return 160;
      } else {
        return 120;
      }
    } else if (field === "date") {
      if (responsiveViewPortTriggerMin) {
        return 220;
      } else {
        return 180;
      }
    } else {
      return undefined;
    }
  };
  const LogsDateAndTimeCell = (props: any) => <IntegrationDateAndTime {...props} />;
  const getLogsTableColumnCell = (field: string) => {
    if (field === "description") {
      return TooltipTable;
    } else if (field === "date") {
      return LogsDateAndTimeCell;
    }
  };

  const filterTypeDropdownInput = (props: any) => FilterDropdownInput(props, [{ type: "Information" }, { type: "Warning" }, { type: "Error" }]);
  const filterFeatureDropdownInput = (props: any) => FilterDropdownInput(props, [{ feature: "Accounts" }, { feature: "Tickets" }, { feature: "Billing" }]);
  const getLogsTableColumnMenu = (field: string) => {
    if (field === "date") {
      return filterDateInput;
    } else if (field === "feature") {
      return filterFeatureDropdownInput;
    } else if (field === "type") {
      return filterTypeDropdownInput;
    } else {
      return filterTextInput;
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <DataTable
        className={"IntegrationsLogsTable noScrollbar noBorders"}
        data={gridState.dataResult}
        resizable
        filter={gridState.dataState.filter}
        // page
        pageConfig={{
          pageable: {
            pageSizes: [10, 25, 50],
            buttonCount: buttonCount,
          },
          skip: logsTableState.skip,
          take: logsTableState.take,
          total: gridState.dataResult.total,
        }}
        // sort
        sortConfig={{
          sortable: true,
          sort: logsTableState.sort,
        }}
        pager={gridState.dataResult.data.length > 0 && Pager}
        onDataStateChange={dataStateChange}
        selectedField="selected"
        {...(gridState.dataState as any)}
      >
        {gridState.dataState.columns.map((column, idx) => column.show && <Column key={"logs" + idx} field={column.field} title={column.title} width={getLogsTableColumnWidth(column.field)} cell={getLogsTableColumnCell(column.field)} filter={column.field === "date" ? "date" : "text"} sortable={true} minResizableWidth={30} resizable={true} columnMenu={getLogsTableColumnMenu(column.field)} headerClassName={getColorForColumn(column.field, gridState.dataState as State)} />)}
      </DataTable>
    </div>
  );
};

export default IntegrationsLogsTable;

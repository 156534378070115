import React, { useEffect, useState } from "react";
import { Typography, Grid, Tabs, Tab, Box } from "@cuda-networks/bds-core";
import { UserPrivilegeAccount, UserPrivilegeApiCredentials, UserPrivilegeBAProductServices, UserPrivilegeFinance, UserPrivilegeMSP, UserPrivilegeProductServices, UserPrivilegeRolesAccess, UserSubpartnerPrivilegeAccount, UserSubpartnerPrivilegeProductServices } from "../../../../../models/UserPrivileges";
import { IPrivilege } from "../../../../../models/IUserPrivleges";
import UserRole from "../../../../../models/UserRole";
import { computeAdminPrivileges, computeBAAdminPrivileges, computeBAFinancePrivileges, computeBATechPrivileges, computeFinancePrivileges, computeNoRoleSelected, computeReadOnlyPrivileges, computeSubpartnerAdminPrivileges, computeSubpartnerFinancePrivileges, computeSubpartnerReadOnlyPrivileges, computeSubpartnerTechAndTechNoDelPrivileges, computeTechAndTechNoDelPrivileges, getAccountPrivileges, getProductServicesPrivileges } from "../../../../../businessLogic/components/Users/AddEditUser/DialogSteps/RolesStep";
import PrivilegesSection from "./PrivilegesSection";

interface IPrivilegesProps {
  role: UserRole | string | undefined;
  isBaLoggedIn: boolean;
  isSubpartner: boolean;
  isSubpartnerLoggedIn: boolean;
}

const UserPrivileges: React.FC<IPrivilegesProps> = ({ role, isBaLoggedIn, isSubpartner, isSubpartnerLoggedIn }) => {
  const initialAccountPrivileges: IPrivilege<UserPrivilegeAccount>[] = Object.values(UserPrivilegeAccount).map(privilege => ({ privilege, enabled: false }));
  const [selectedAccountPrivileges, setSelectedAccountPrivileges] = useState<IPrivilege<UserPrivilegeAccount>[]>(initialAccountPrivileges);
  const initialMSPPrivileges: IPrivilege<UserPrivilegeMSP>[] = Object.values(UserPrivilegeMSP).map(privilege => ({ privilege, enabled: false }));
  const [selectedMSPPrivileges, setSelectedMSPPrivileges] = useState<IPrivilege<UserPrivilegeMSP>[]>(initialMSPPrivileges);
  const initialRolesAccessPrivileges: IPrivilege<UserPrivilegeRolesAccess>[] = Object.values(UserPrivilegeRolesAccess).map(privilege => ({ privilege, enabled: false }));
  const [selectedRolesAccessPrivileges, setSelectedRolesAccessPrivileges] = useState<IPrivilege<UserPrivilegeRolesAccess>[]>(initialRolesAccessPrivileges);
  const initialFinancePrivileges: IPrivilege<UserPrivilegeFinance>[] = Object.values(UserPrivilegeFinance).map(privilege => ({ privilege, enabled: false }));
  const [selectedFinancePrivileges, setSelectedFinancePrivileges] = useState<IPrivilege<UserPrivilegeFinance>[]>(initialFinancePrivileges);
  const initialProductServicesPrivileges: IPrivilege<UserPrivilegeProductServices>[] = Object.values(UserPrivilegeProductServices).map(privilege => ({ privilege, enabled: false }));
  const [selectedProductServicesPrivileges, setSelectedProductServicesPrivileges] = useState<IPrivilege<UserPrivilegeProductServices>[]>(initialProductServicesPrivileges);
  const initialApiCredentialsPrivileges: IPrivilege<UserPrivilegeApiCredentials>[] = Object.values(UserPrivilegeApiCredentials).map(privilege => ({ privilege, enabled: false }));
  const [selectedApiCredentialsPrivileges, setSelectedApiCredentialsPrivileges] = useState<IPrivilege<UserPrivilegeApiCredentials>[]>(initialApiCredentialsPrivileges);
  const initialBAProductServicesPrivileges: IPrivilege<UserPrivilegeBAProductServices>[] = Object.values(UserPrivilegeBAProductServices).map(privilege => ({ privilege, enabled: false }));
  const [selectedBAProductServicesPrivileges, setSelectedBAProductServicesPrivileges] = useState<IPrivilege<UserPrivilegeBAProductServices>[]>(initialBAProductServicesPrivileges);
  const [accountPrivilegeTitle, setAccoutPrivilegeTitle] = useState("Account");

  const initialAccountPrivilegesForSubpartnerUser: IPrivilege<UserSubpartnerPrivilegeAccount>[] = Object.values(UserSubpartnerPrivilegeAccount).map(privilege => ({ privilege, enabled: false }));
  const [selectedAccountPrivilegesForSubpartnerUser, setSelectedAccountPrivilegesForSubpartnerUser] = useState<IPrivilege<UserSubpartnerPrivilegeAccount>[]>(initialAccountPrivilegesForSubpartnerUser);
  const initialProductServicesPrivilegesForSubpartnerUser: IPrivilege<UserSubpartnerPrivilegeProductServices>[] = Object.values(UserSubpartnerPrivilegeProductServices).map(privilege => ({ privilege, enabled: false }));
  const [selectedProductServicesPrivilegesForSubpartnerUser, setSelectedProductServicesPrivilegesForSubpartnerUser] = useState<IPrivilege<UserSubpartnerPrivilegeProductServices>[]>(initialProductServicesPrivilegesForSubpartnerUser);
  const [userAccountPrivileges, setUserAccountPrivileges] = useState<IPrivilege<any>[]>([]);
  const [userProductServicesPrivileges, setUserProductServicesPrivileges] = useState<IPrivilege<any>[]>([]);

  const [tab, setTab] = useState(0);

  const setAdminPrivileges = () => {
    if (isSubpartnerLoggedIn) {
      const { accountPrivileges, rolesAccessPrivileges, productServicesPrivileges } = computeSubpartnerAdminPrivileges(initialAccountPrivilegesForSubpartnerUser, initialRolesAccessPrivileges, initialProductServicesPrivilegesForSubpartnerUser);
      setSelectedAccountPrivilegesForSubpartnerUser(accountPrivileges);
      setSelectedRolesAccessPrivileges(rolesAccessPrivileges);
      setSelectedProductServicesPrivilegesForSubpartnerUser(productServicesPrivileges);
      setSelectedApiCredentialsPrivileges(initialApiCredentialsPrivileges);
    } else {
      const { accountPrivileges, rolesAccessPrivileges, financePrivileges, productServicesPrivileges, apiCrendetialsPrivileges } = computeAdminPrivileges(initialAccountPrivileges, initialRolesAccessPrivileges, initialFinancePrivileges, initialProductServicesPrivileges, initialApiCredentialsPrivileges, isSubpartner);
      setSelectedAccountPrivileges(accountPrivileges);
      setSelectedRolesAccessPrivileges(rolesAccessPrivileges);
      setSelectedFinancePrivileges(financePrivileges);
      setSelectedProductServicesPrivileges(productServicesPrivileges);
      setSelectedApiCredentialsPrivileges(apiCrendetialsPrivileges);
    }
  };

  const setFinancePrivileges = () => {
    if (isSubpartnerLoggedIn) {
      const { accountPrivileges, rolesAccessPrivileges, productServicesPrivileges } = computeSubpartnerFinancePrivileges(initialAccountPrivilegesForSubpartnerUser, initialRolesAccessPrivileges, initialProductServicesPrivilegesForSubpartnerUser);
      setSelectedAccountPrivilegesForSubpartnerUser(accountPrivileges);
      setSelectedRolesAccessPrivileges(rolesAccessPrivileges);
      setSelectedProductServicesPrivilegesForSubpartnerUser(productServicesPrivileges);
      setSelectedApiCredentialsPrivileges(initialApiCredentialsPrivileges);
    } else {
      const { accountPrivileges, rolesAccessPrivileges, financePrivileges, productServicesPrivileges } = computeFinancePrivileges(initialAccountPrivileges, initialRolesAccessPrivileges, initialFinancePrivileges, initialProductServicesPrivileges, isSubpartner);
      setSelectedAccountPrivileges(accountPrivileges);
      setSelectedRolesAccessPrivileges(rolesAccessPrivileges);
      setSelectedFinancePrivileges(financePrivileges);
      setSelectedProductServicesPrivileges(productServicesPrivileges);
      setSelectedApiCredentialsPrivileges(initialApiCredentialsPrivileges);
    }
  };

  const setTechAndTechoDelPrivileges = () => {
    if (isSubpartnerLoggedIn) {
      const { accountPrivileges, rolesAccessPrivileges, productServicesPrivileges } = computeSubpartnerTechAndTechNoDelPrivileges(initialAccountPrivilegesForSubpartnerUser, initialRolesAccessPrivileges, initialProductServicesPrivilegesForSubpartnerUser);
      setSelectedAccountPrivilegesForSubpartnerUser(accountPrivileges);
      setSelectedRolesAccessPrivileges(rolesAccessPrivileges);
      setSelectedProductServicesPrivilegesForSubpartnerUser(productServicesPrivileges);
      setSelectedApiCredentialsPrivileges(initialApiCredentialsPrivileges);
    } else {
      const { accountPrivileges, rolesAccessPrivileges, financePrivileges, productServicesPrivileges } = computeTechAndTechNoDelPrivileges(initialAccountPrivileges, initialRolesAccessPrivileges, initialFinancePrivileges, initialProductServicesPrivileges, isSubpartner);
      setSelectedAccountPrivileges(accountPrivileges);
      setSelectedRolesAccessPrivileges(rolesAccessPrivileges);
      setSelectedFinancePrivileges(financePrivileges);
      setSelectedProductServicesPrivileges(productServicesPrivileges);
      setSelectedApiCredentialsPrivileges(initialApiCredentialsPrivileges);
    }
  };

  const setNoRoleSelected = () => {
    const { accountPrivileges, rolesAccessPrivileges, financePrivileges, productServicesPrivileges, mspPrivileges, productBAServicesPrivileges } = computeNoRoleSelected(initialAccountPrivileges, initialRolesAccessPrivileges, initialFinancePrivileges, initialProductServicesPrivileges, initialMSPPrivileges, initialBAProductServicesPrivileges);
    setSelectedAccountPrivileges(accountPrivileges);
    setSelectedRolesAccessPrivileges(rolesAccessPrivileges);
    setSelectedFinancePrivileges(financePrivileges);
    setSelectedProductServicesPrivileges(productServicesPrivileges);
    setSelectedMSPPrivileges(mspPrivileges);
    setSelectedBAProductServicesPrivileges(productBAServicesPrivileges);
    setSelectedApiCredentialsPrivileges(initialApiCredentialsPrivileges);
  };

  const setReadOnlyPrivileges = () => {
    if (isSubpartnerLoggedIn) {
      const { accountPrivileges, rolesAccessPrivileges, productServicesPrivileges } = computeSubpartnerReadOnlyPrivileges(initialAccountPrivilegesForSubpartnerUser, initialRolesAccessPrivileges, initialProductServicesPrivilegesForSubpartnerUser);
      setSelectedAccountPrivilegesForSubpartnerUser(accountPrivileges);
      setSelectedRolesAccessPrivileges(rolesAccessPrivileges);
      setSelectedApiCredentialsPrivileges(initialApiCredentialsPrivileges);
      setSelectedProductServicesPrivilegesForSubpartnerUser(productServicesPrivileges);
    } else {
      const { accountPrivileges, rolesAccessPrivileges, financePrivileges, productServicesPrivileges } = computeReadOnlyPrivileges(initialAccountPrivileges, initialRolesAccessPrivileges, initialFinancePrivileges, initialProductServicesPrivileges);
      setSelectedAccountPrivileges(accountPrivileges);
      setSelectedRolesAccessPrivileges(rolesAccessPrivileges);
      setSelectedFinancePrivileges(financePrivileges);
      setSelectedApiCredentialsPrivileges(initialApiCredentialsPrivileges);
      setSelectedProductServicesPrivileges(productServicesPrivileges);
    }
  };

  const setBAAdminPrivileges = () => {
    const { mspPrivileges, rolesAccessPrivileges, financePrivileges, productBAServicesPrivileges } = computeBAAdminPrivileges(initialMSPPrivileges, initialRolesAccessPrivileges, initialFinancePrivileges, initialBAProductServicesPrivileges);

    setSelectedMSPPrivileges(mspPrivileges);
    setSelectedRolesAccessPrivileges(rolesAccessPrivileges);
    setSelectedFinancePrivileges(financePrivileges);
    setSelectedBAProductServicesPrivileges(productBAServicesPrivileges);
  };

  const setBAFinancePrivileges = () => {
    const { mspPrivileges, rolesAccessPrivileges, financePrivileges, productBAServicesPrivileges } = computeBAFinancePrivileges(initialMSPPrivileges, initialRolesAccessPrivileges, initialFinancePrivileges, initialBAProductServicesPrivileges);

    setSelectedMSPPrivileges(mspPrivileges);
    setSelectedRolesAccessPrivileges(rolesAccessPrivileges);
    setSelectedFinancePrivileges(financePrivileges);
    setSelectedBAProductServicesPrivileges(productBAServicesPrivileges);
  };

  const setBATechPrivileges = () => {
    const { mspPrivileges, rolesAccessPrivileges, financePrivileges, productBAServicesPrivileges } = computeBATechPrivileges(initialMSPPrivileges, initialRolesAccessPrivileges, initialFinancePrivileges, initialBAProductServicesPrivileges);

    setSelectedMSPPrivileges(mspPrivileges);
    setSelectedRolesAccessPrivileges(rolesAccessPrivileges);
    setSelectedFinancePrivileges(financePrivileges);
    setSelectedBAProductServicesPrivileges(productBAServicesPrivileges);
  };

  useEffect(() => {
    switch (role) {
      case UserRole.Admin:
        setAdminPrivileges();
        break;
      case UserRole.Finance:
        setFinancePrivileges();
        break;
      case UserRole.Tech:
      case UserRole.TechDoNotDelete:
        setTechAndTechoDelPrivileges();
        break;
      case UserRole.ReadOnly:
        setReadOnlyPrivileges();
        break;
      case UserRole.BillingAggregatorAdmin:
        setBAAdminPrivileges();
        break;
      case UserRole.BillingAggregatorFinance:
        setBAFinancePrivileges();
        break;
      case UserRole.BillingAggregatorTech:
        setBATechPrivileges();
        break;
      default:
        setNoRoleSelected();
        break;
    }
    // eslint-disable-next-line
  }, [role]);

  useEffect(() => {
    if (isBaLoggedIn) {
      setAccoutPrivilegeTitle("MSP");
    } else {
      setAccoutPrivilegeTitle("Accounts");
    }
    // eslint-disable-next-line
  }, [isBaLoggedIn]);

  useEffect(() => {
    const privileges = getAccountPrivileges(isBaLoggedIn, isSubpartnerLoggedIn, selectedMSPPrivileges, selectedAccountPrivilegesForSubpartnerUser, selectedAccountPrivileges);

    setUserAccountPrivileges(privileges);
  }, [isBaLoggedIn, isSubpartnerLoggedIn, selectedMSPPrivileges, selectedAccountPrivilegesForSubpartnerUser, selectedAccountPrivileges]);

  useEffect(() => {
    const privileges = getProductServicesPrivileges(isBaLoggedIn, isSubpartnerLoggedIn, selectedBAProductServicesPrivileges, selectedProductServicesPrivilegesForSubpartnerUser, selectedProductServicesPrivileges);

    setUserProductServicesPrivileges(privileges);
  }, [isBaLoggedIn, isSubpartnerLoggedIn, selectedBAProductServicesPrivileges, selectedProductServicesPrivilegesForSubpartnerUser, selectedProductServicesPrivileges]);

  let userAccountPrivilegesCounter = userAccountPrivileges.length;
  let userAccountPrivilegesEnableCounter = userAccountPrivileges.filter(x => x.enabled === true).length;

  let rolesAccessPrivilegesCounter = selectedRolesAccessPrivileges.length;
  let rolesAccessPrivilegesEnableCounter = selectedRolesAccessPrivileges.filter(x => x.enabled === true).length;

  let selectedFinancePrivilegesCounter = selectedFinancePrivileges.length;
  let selectedFinancePrivilegesEnableCounter = selectedFinancePrivileges.filter(x => x.enabled === true).length;

  let userProductServicesPrivilegesCounter = userProductServicesPrivileges.length;
  let userProductServicesPrivilegesEnableCounter = userProductServicesPrivileges.filter(x => x.enabled === true).length;

  let selectedApiCredentialsPrivilegesCounter = selectedApiCredentialsPrivileges.length;
  let selectedApiCredentialsPrivilegesEnableCounter = selectedApiCredentialsPrivileges.filter(x => x.enabled === true).length;

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Typography variant="h6" style={{ paddingBottom: "10px", marginTop: "30px" }}>
          SECTIONS
        </Typography>
        <div className="tabsPrivileges">
          <Box sx={{ flexGrow: 1, display: "flex", height: 254 }}>
            <Tabs value={tab} indicatorColor="primary" textColor="primary" orientation="vertical" style={{ borderRight: "2px", borderColor: "black" }}>
              <Tab data-testid="tabAccounts" label={`${accountPrivilegeTitle} ${userAccountPrivilegesEnableCounter}/${userAccountPrivilegesCounter}`} onClick={() => setTab(0)} />
              <Tab data-testid="tabRolesAccess" label={`Roles & Access ${rolesAccessPrivilegesEnableCounter}/${rolesAccessPrivilegesCounter}`} onClick={() => setTab(1)} />
              <Tab data-testid="tabFinances" label={`Finances ${selectedFinancePrivilegesEnableCounter}/${selectedFinancePrivilegesCounter}`} onClick={() => setTab(2)} />
              {!isSubpartnerLoggedIn && <Tab data-testid="tabProductsServices" label={`Products & Services ${userProductServicesPrivilegesEnableCounter}/${userProductServicesPrivilegesCounter}`} onClick={() => setTab(3)} />}
              {!isSubpartnerLoggedIn && !isBaLoggedIn && <Tab data-testid="tabApiCredentials" label={`Api Credentials ${selectedApiCredentialsPrivilegesEnableCounter}/${selectedApiCredentialsPrivilegesCounter}`} onClick={() => setTab(4)} />}
            </Tabs>
          </Box>
        </div>
      </Grid>
      <Grid item xs={8}>
        <Box style={{ marginLeft: "30px", marginTop: "30px" }}>
          {tab === 0 && <PrivilegesSection title={accountPrivilegeTitle} privileges={userAccountPrivileges} />}
          {tab === 1 && <PrivilegesSection title="Roles & Access" privileges={selectedRolesAccessPrivileges} />}
          {tab === 2 && !isSubpartnerLoggedIn && <PrivilegesSection title="Finance" privileges={selectedFinancePrivileges} />}
          {tab === 3 && <PrivilegesSection title="Product & Services" privileges={userProductServicesPrivileges} />}
          {!isSubpartnerLoggedIn && !isBaLoggedIn && tab === 4 && <PrivilegesSection title="Api Credentials" privileges={selectedApiCredentialsPrivileges} />}
        </Box>
      </Grid>
    </Grid>
  );
};

export default UserPrivileges;

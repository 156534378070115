import React from "react";
import { Card, Typography, CardContent } from "@cuda-networks/bds-core";

interface IEmptyInfoPanelProps {
  message: string;
}

const EmptyInfoPanel: React.FC<IEmptyInfoPanelProps> = ({ message }) => {
  return (
    <Card className={"EmptyInfoPanel"}>
      <CardContent style={{ height: 550 }}>
        <Typography data-testid="emptyPanel" variant="body1" style={{ position: "relative", top: "235px", color: "darkgray" }}>
          {message}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default EmptyInfoPanel;

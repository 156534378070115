import { AddAccountButtonType } from "../../../models/AddAccountButtonType";
import IAccount from "../../../models/IAccount";
import MspType from "../../../models/MspType";
import { getAccountTypeAsTitle, searchByAccountId } from "../../../Utilities/accountsHelper";

export function getSettingsForNewAcount(mspAccounts: IAccount[], mspAccountLoggedIn: IAccount, selectedAccount: IAccount | undefined, expandedPartner: IAccount | undefined): { addAccountButtonType: AddAccountButtonType; parentOfNewAcc: IAccount; mspTypeOfNewAccount: MspType; canAddAccounts: boolean; title: string } {
  let addAccountButtonType: AddAccountButtonType = AddAccountButtonType.AddSubpartnerOrAccount;
  let parentOfNewAccount: IAccount | undefined = mspAccountLoggedIn;
  let typeOfNewAccount: MspType = MspType.Subpartner;
  let viewAddAccountButton = false;
  let dialogTitle: string = "";
  if (mspAccountLoggedIn.userAdminFlag) {
    viewAddAccountButton = true;
    if (selectedAccount) {
      if (selectedAccount.type === MspType.Partner) {
        parentOfNewAccount = mspAccountLoggedIn;
        typeOfNewAccount = MspType.Customer;
        addAccountButtonType = getSettingsForSelectedOrExpandedPartner(mspAccountLoggedIn);
        dialogTitle = "ADD " + getAccountTypeAsTitle(typeOfNewAccount);
      } else if (selectedAccount.type === MspType.Subpartner) {
        parentOfNewAccount = selectedAccount;
        addAccountButtonType = AddAccountButtonType.AddAccount;
        typeOfNewAccount = MspType.Customer;
        dialogTitle = "ADD " + getAccountTypeAsTitle(typeOfNewAccount);
      } else if (selectedAccount.type === MspType.Customer) {
        typeOfNewAccount = MspType.Customer;
        addAccountButtonType = AddAccountButtonType.AddAccount;
        parentOfNewAccount = searchByAccountId(mspAccounts, selectedAccount.partnerId);
        if (parentOfNewAccount) {
          if (parentOfNewAccount.type === MspType.Partner) {
            typeOfNewAccount = MspType.Customer;
            addAccountButtonType = getSettingsForSelectedOrExpandedPartner(mspAccountLoggedIn);
            dialogTitle = "ADD " + getAccountTypeAsTitle(typeOfNewAccount);
          } else if (parentOfNewAccount.type === MspType.Subpartner) {
            addAccountButtonType = AddAccountButtonType.AddAccount;
            typeOfNewAccount = MspType.Customer;
            dialogTitle = "ADD " + getAccountTypeAsTitle(typeOfNewAccount);
          } else {
            throw new Error(`Customer cannot be parent of for account id ${selectedAccount.id}`);
          }
        }
      } else {
        throw new Error(`Unknown type for account id ${selectedAccount.id}`);
      }
    } else if (expandedPartner) {
      parentOfNewAccount = expandedPartner;
      if (mspAccountLoggedIn.type === MspType.Partner) {
        if (expandedPartner.type === MspType.Partner) {
          typeOfNewAccount = MspType.Customer;
          addAccountButtonType = getSettingsForSelectedOrExpandedPartner(mspAccountLoggedIn);
          dialogTitle = "ADD " + getAccountTypeAsTitle(typeOfNewAccount);
        } else {
          addAccountButtonType = AddAccountButtonType.AddAccount;
          typeOfNewAccount = MspType.Customer;
          dialogTitle = "ADD " + getAccountTypeAsTitle(typeOfNewAccount);
        }
      } else {
        addAccountButtonType = AddAccountButtonType.AddAccount;
        typeOfNewAccount = MspType.Customer;
        dialogTitle = "ADD " + getAccountTypeAsTitle(typeOfNewAccount);
      }
    } else {
      parentOfNewAccount = mspAccountLoggedIn;
      if (mspAccountLoggedIn.type === MspType.Partner) {
        typeOfNewAccount = MspType.Customer;
        addAccountButtonType = getSettingsForSelectedOrExpandedPartner(mspAccountLoggedIn);
        dialogTitle = "ADD " + getAccountTypeAsTitle(typeOfNewAccount);
      } else {
        addAccountButtonType = AddAccountButtonType.AddAccount;
        typeOfNewAccount = MspType.Customer;
        dialogTitle = "ADD " + getAccountTypeAsTitle(typeOfNewAccount);
      }
    }
  }
  return { addAccountButtonType, parentOfNewAcc: parentOfNewAccount, mspTypeOfNewAccount: typeOfNewAccount, canAddAccounts: viewAddAccountButton, title: dialogTitle };
}

function getSettingsForSelectedOrExpandedPartner(mspAccountLoggedIn: IAccount): AddAccountButtonType {
  if (mspAccountLoggedIn.canCreateSubPartners) {
    return AddAccountButtonType.AddSubpartnerOrAccount;
  } else {
    return AddAccountButtonType.AddAccount;
  }
}

import React, { useEffect } from "react";
import StartApp from "./components/StartApp";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NewUser from "./components/NewUserPage/NewUser";
import { BDSProvider } from "@cuda-networks/bds-core";
import { OptionsObject, SnackbarProvider, closeSnackbar, enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { removeSnackBarMessage } from "./actions/generalActions";
import { IAppState } from "./store/store";
import ActionLink from "./components/SnackBar/ActionLink";
import CloseButton from "./components/SnackBar/CloseButton";

const App = (): React.JSX.Element => {
  const dispatch = useDispatch();
  const snackBarMessage = useSelector((state: IAppState) => state.generalState.snackBarMessage);

  useEffect(() => {
    if (snackBarMessage.message !== "") {
      const snackBarOptions: OptionsObject = {
        variant: snackBarMessage.type,
        autoHideDuration: 5000,
        onClose: () => {
          if (snackBarMessage.closeAction !== undefined) {
            snackBarMessage.closeAction();
          }
          dispatch(removeSnackBarMessage());
        },
        action: [
          snackBarMessage.action !== undefined ? (
            <ActionLink
              onActionLinkClick={() => {
                if (snackBarMessage.action !== undefined) {
                  snackBarMessage.action();
                  closeSnackbar(key);
                  dispatch(removeSnackBarMessage());
                }
              }}
            />
          ) : null,
          <CloseButton
            onCloseSnackbar={() => {
              if (snackBarMessage.closeAction !== undefined) {
                snackBarMessage.closeAction();
              }
              closeSnackbar(key);
              dispatch(removeSnackBarMessage());
            }}
          />,
        ],
      };
      const key = enqueueSnackbar(snackBarMessage.message, snackBarOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snackBarMessage]);

  return (
    <BDSProvider>
      <SnackbarProvider maxSnack={10}>
        <Router>
          <Routes>
            <Route path="/" element={<StartApp />} />
            <Route path="/newUser" element={<NewUser />} />
            <Route path="*" element={<StartApp />} />
          </Routes>
        </Router>
      </SnackbarProvider>
    </BDSProvider>
  );
};

export default App;

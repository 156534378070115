import { CancelToken } from "axios";
import { ActionCreator, AnyAction, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import IAccount from "../models/IAccount";
import IUser from "../models/IUser";
import mspService from "../service/mspService";
import { IAppState } from "../store/store";
import { UserRoleValues } from "../models/UserRole";
import IEntitlement from "../models/IEntitlement";
import { getAccountAccessForUserExplicitId, getDisplayUserRoleByAuthDbValue, getRoleFromUserAccount, updateUsersList, updateUsersListWithExtraInfo, computeUsersList } from "../Utilities/usersHelper";
import { IUserToUpdate } from "../components/Users/UsersTab";
import { State, process } from "@progress/kendo-data-query";
import { GeneralActionTypes } from "./generalActions";
import { handleError } from "./actionsErrorHandler";
import { deleteUserFromState, getUserParentAccount } from "../businessLogic/users";
import { isTokenExpiredError } from "../utility";
import { OperationCanceledError } from "../errors/OperationCanceledError";
import { TokenStorage } from "../TokenStorage";
import { cancelCurrent, cancelGeneralActionTokenAndCreateNew } from "./cancelAction";
import { ActionTypes } from "./ActionTypes";
import { LocalStoragePreferences, localStorageService } from "../service/localStorageService";

export enum UserActionTypes {
  GET_ACCOUNT_USERS = "GET_ACCOUNT_USERS",
  GET_ACCOUNT_USERS_EXTRA_INFO = "GET_ACCOUNT_USERS_EXTRA_INFO",
  GET_USER = "GET_USER",
  SET_SELECTED_USER = "SET_SELECTED_USER",
  GET_USER_ACCOUNTS = "GET_USER_ACCOUNTS",
  ADD_USER = "ADD_USER",
  ADD_NEW_PARTNER_USER = "ADD_NEW_PARTNER_USER",
  SET_ADD_EDIT_USER_ERROR = "SET_ADD_EDIT_USER_ERROR",
  SET_TABLE_PROPS = "SET_TABLE_PROPS",
  EDIT_USER = "EDIT_USER",
  DELETE_USER = "DELETE_USER",
  SET_DELETE_USER_ERROR = "SET_DELETE_USER_ERROR",
  CANCEL_LOAD_USERS = "CANCEL_LOAD_USERS",
  GET_ENTITLEMENTS = "GET_ENTITLEMENTS",
  GET_ENTITLEMENTS_FOR_ACCOUNT = "GET_ENTITLEMENTS_FOR_ACCOUNT",
  SET_LOADING_USERS_CANCELED = "SET_LOADING_USERS_CANCELED",
  SET_LOADING_USERS_EXTRA_INFO = "SET_LOADING_USERS_EXTRA_INFO",
  SET_ENTITLEMENTS_TABLE_PROPS = "SET_ENTITLEMENTS_TABLE_PROPS",
  SET_DUPLICATE_EMAIL_USER = "SET_DUPLICATE_EMAIL_USER",
}

export interface IGetAccountUsersAction {
  type: UserActionTypes.GET_ACCOUNT_USERS;
  usersToDisplay: IUser[];
  loadingUsers: boolean;
}

export interface IGetAccountUsersExtraInfoAction {
  type: UserActionTypes.GET_ACCOUNT_USERS_EXTRA_INFO;
  usersToDisplay: IUser[];
  loadingUsers: boolean;
}

export interface ISetSelectedUserAction {
  type: UserActionTypes.SET_SELECTED_USER;
  selectedUser: IUser;
}

export interface ISetTableProps {
  type: UserActionTypes.SET_TABLE_PROPS;
  tableState: State;
}

export interface IAddUserAction {
  type: UserActionTypes.ADD_USER;
  usersToDisplay: IUser[];
}

export interface IAddNewPartnerUserAction {
  type: UserActionTypes.ADD_NEW_PARTNER_USER;
}

export interface IEditUserAction {
  type: UserActionTypes.EDIT_USER;
  usersToDisplay: IUser[];
}
export interface IDeleteUserAction {
  type: UserActionTypes.DELETE_USER;
  usersToDisplay: IUser[];
}

export interface IGetUserEntitlements {
  type: UserActionTypes.GET_ENTITLEMENTS;
  userEntitlements: IEntitlement[];
  loadingEntitlements: boolean;
}

export interface IGetUserEntitlementsForAccount {
  type: UserActionTypes.GET_ENTITLEMENTS_FOR_ACCOUNT;
  entitlements: IEntitlement[];
  loadingEntitlements: boolean;
}

export interface ISetLoadingUsersCanceled {
  type: UserActionTypes.SET_LOADING_USERS_CANCELED;
  loadingUsersCanceledForAccountId: number;
}

export interface ISetLoadingUsersExtraInfo {
  type: UserActionTypes.SET_LOADING_USERS_EXTRA_INFO;
  loadingUsersExtraInfo: boolean;
}

export interface ISetEntitlementTableProps {
  type: UserActionTypes.SET_ENTITLEMENTS_TABLE_PROPS;
  entitlementsTableState: State;
}

export interface ISetDuplicateEmailUser {
  type: UserActionTypes.SET_DUPLICATE_EMAIL_USER;
  duplicateEmailError: boolean;
}

export type UserActions = IGetAccountUsersAction | IGetAccountUsersExtraInfoAction | ISetSelectedUserAction | IAddUserAction | IAddNewPartnerUserAction | IEditUserAction | ISetTableProps | IDeleteUserAction | IGetUserEntitlements | IGetUserEntitlementsForAccount | ISetLoadingUsersCanceled | ISetLoadingUsersExtraInfo | ISetEntitlementTableProps | ISetDuplicateEmailUser;

export const getAccountUsersAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetAccountUsersAction>> = (account: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    dispatch({
      usersToDisplay: [],
      type: UserActionTypes.GET_ACCOUNT_USERS,
      loadingUsers: true,
    });
    cancelCurrent(getState().productState.loadParentProductsCancellationTokenSource);
    const newCancelTokenSource = cancelGeneralActionTokenAndCreateNew(getState, dispatch);
    try {
      const { mspAccounts } = getState().accountState;
      const accountUsers = await mspService.loadAccountUsers(apiUrl, account, newCancelTokenSource.token);
      let updatedUsersList: IUser[] = [];
      if (accountUsers.length > 0) {
        const { tableState } = getState().userState;
        const usersToBeDisplayed = process(accountUsers, tableState).data;
        const usersWithExtraInfo = await loadAccountUsersExtraInfo(apiUrl, usersToBeDisplayed, account, mspAccounts, newCancelTokenSource.token);

        updatedUsersList = updateUsersListWithExtraInfo(accountUsers, usersWithExtraInfo);
      }

      dispatch({
        usersToDisplay: updatedUsersList,
        type: UserActionTypes.GET_ACCOUNT_USERS,
        loadingUsers: false,
      });
      dispatch({
        loadingUsersCanceledForAccountId: 0,
        type: UserActionTypes.SET_LOADING_USERS_CANCELED,
      });
      dispatch({
        loadingUsersExtraInfo: false,
        type: UserActionTypes.SET_LOADING_USERS_EXTRA_INFO,
      });
      return accountUsers;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            usersToDisplay: getState().userState.usersToDisplay,
            type: UserActionTypes.GET_ACCOUNT_USERS,
            loadingUsers: false,
          });
        },
        () => {
          dispatch({
            loadingUsersCanceledForAccountId: account.id,
            type: UserActionTypes.SET_LOADING_USERS_CANCELED,
          });
        },
      );
      return [];
    }
  };
};

export const getAccountUsersExtraInfoAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetAccountUsersExtraInfoAction>> = (account: IAccount, users: IUser[]) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    const { usersToDisplay } = getState().userState;
    cancelCurrent(getState().productState.loadParentProductsCancellationTokenSource);
    const newCancelTokenSource = cancelGeneralActionTokenAndCreateNew(getState, dispatch);
    try {
      let usersWithoutExtraInfo: IUser[] = [];
      users.forEach((u: IUser) => {
        if (u.role === undefined) {
          usersWithoutExtraInfo.push(u);
        }
      });

      if (usersWithoutExtraInfo.length > 0) {
        dispatch({
          type: UserActionTypes.SET_LOADING_USERS_EXTRA_INFO,
          loadingUsersExtraInfo: true,
        });
        const { mspAccounts } = getState().accountState;
        const usersWithExtraInfo = await loadAccountUsersExtraInfo(apiUrl, usersWithoutExtraInfo, account, mspAccounts, newCancelTokenSource.token);

        const updatedUsersList = updateUsersListWithExtraInfo(usersToDisplay, usersWithExtraInfo);

        dispatch({
          usersToDisplay: updatedUsersList,
          type: UserActionTypes.GET_ACCOUNT_USERS,
          loadingUsers: false,
        });
        dispatch({
          loadingUsersCanceledForAccountId: 0,
          type: UserActionTypes.SET_LOADING_USERS_CANCELED,
        });
        dispatch({
          type: UserActionTypes.SET_LOADING_USERS_EXTRA_INFO,
          loadingUsersExtraInfo: false,
        });
        return updatedUsersList;
      } else {
        dispatch({
          usersToDisplay: usersToDisplay,
          type: UserActionTypes.GET_ACCOUNT_USERS,
          loadingUsers: false,
        });
        dispatch({
          loadingUsersCanceledForAccountId: 0,
          type: UserActionTypes.SET_LOADING_USERS_CANCELED,
        });
        dispatch({
          type: UserActionTypes.SET_LOADING_USERS_EXTRA_INFO,
          loadingUsersExtraInfo: false,
        });
        return usersToDisplay;
      }
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: UserActionTypes.SET_LOADING_USERS_EXTRA_INFO,
            loadingUsersExtraInfo: false,
          });
        },
        () => {
          dispatch({
            loadingUsersCanceledForAccountId: account.id,
            type: UserActionTypes.SET_LOADING_USERS_CANCELED,
          });
        },
      );

      return [];
    }
  };
};

export const setSelectedUserAction: ActionCreator<ThunkAction<any, IAppState, null, ISetSelectedUserAction>> = (user: IUser) => (dispatch: Dispatch) => dispatch({ type: UserActionTypes.SET_SELECTED_USER, selectedUser: user });

export const setTableProps: ActionCreator<ThunkAction<any, IAppState, null, ISetTableProps>> = (tableState: State) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch({ type: UserActionTypes.SET_TABLE_PROPS, tableState });
    localStorageService.setItem(getState().generalState.loggedUser.id.toString(), LocalStoragePreferences.ROLESANDACCESS_UI, JSON.stringify({ ...tableState, skip: 0 }));
  };
};

export const addUserAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IAddUserAction>> = (account: IAccount, newUser: IUser) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    try {
      const result = await mspService.addUser(apiUrl, account.id, newUser);
      dispatch({
        type: UserActionTypes.ADD_USER,
        usersToDisplay: computeUsersList(getState().userState.usersToDisplay, result),
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: UserActionTypes.ADD_USER,
            usersToDisplay: getState().userState.usersToDisplay,
          });
        },
        () => {},
        true,
        ActionTypes.AddLogin,
      );
      return false;
    }
  };
};

export const addNewPartnerUserAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IAddNewPartnerUserAction>> = (account: IAccount, newUser: IUser) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    try {
      await mspService.addNewPartnerUser(apiUrl, account.id, newUser);
      dispatch({
        type: UserActionTypes.ADD_NEW_PARTNER_USER,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          /* do nothing */
        },
        () => {},
        true,
        ActionTypes.AddLogin,
      );
      return false;
    }
  };
};

export const editUserAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IEditUserAction>> = (user: IUser, updatedUserInfo: IUserToUpdate) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    try {
      if (updatedUserInfo.email !== undefined || updatedUserInfo.name !== undefined) {
        await mspService.editUser(apiUrl, user.id, updatedUserInfo);
      }

      const role = UserRoleValues.find(value => value.value === user.role)?.authDbValue;
      if (role === undefined) {
        dispatch({ type: GeneralActionTypes.ERROR, errorMessage: "Unknown user role" });
        dispatch({
          type: UserActionTypes.EDIT_USER,
          usersToDisplay: getState().userState.usersToDisplay,
        });
        return false;
      }

      let newRole = { role: role };
      if (updatedUserInfo.updateRoleOrEntitlements) {
        const ent = user.entitlements?.map((en: IEntitlement) => en.service_id);
        newRole = await mspService.editUserRole(apiUrl, user.id, user.explicitAccountId, role, ent ? ent : []);
      }

      const { usersToDisplay } = getState().userState;
      const updatedUserList = updateUsersList(usersToDisplay, user, newRole);
      dispatch({
        type: UserActionTypes.EDIT_USER,
        usersToDisplay: updatedUserList,
      });
      return true;
    } catch (err: any) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: UserActionTypes.EDIT_USER,
            usersToDisplay: getState().userState.usersToDisplay,
          });
        },
        () => {},
        true,
        ActionTypes.EditLogin,
      );
      return false;
    }
  };
};

export const deleteUserAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IDeleteUserAction>> = (user: IUser) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    try {
      await mspService.deleteUser(apiUrl, user.id);
      const { usersToDisplay } = getState().userState;
      const nexStateUsers = deleteUserFromState(usersToDisplay, user);

      dispatch({
        type: UserActionTypes.DELETE_USER,
        usersToDisplay: nexStateUsers,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: UserActionTypes.DELETE_USER,
            usersToDisplay: getState().userState.usersToDisplay,
          });
        },
        () => {},
        true,
        ActionTypes.DeleteLogin,
      );
      return false;
    }
  };
};

export const loadUserEntitlements: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetUserEntitlements>> = (user: IUser) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    dispatch({
      userEntitlements: [],
      type: UserActionTypes.GET_ENTITLEMENTS,
      loadingEntitlements: true,
    });
    try {
      const entitlements = await mspService.loadUserEntitlements(apiUrl, user.id);
      dispatch({
        userEntitlements: entitlements.services,
        type: UserActionTypes.GET_ENTITLEMENTS,
        loadingEntitlements: false,
      });
      return true;
    } catch (err) {
      handleError(err, dispatch, () => {
        dispatch({
          type: UserActionTypes.GET_ENTITLEMENTS,
          userEntitlements: [],
          loadingEntitlements: false,
        });
      });
      return false;
    }
  };
};

export const loadUserEntitlementsForAccount: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetUserEntitlementsForAccount>> = (userId: number, accountId: number) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    dispatch({
      entitlements: [],
      type: UserActionTypes.GET_ENTITLEMENTS_FOR_ACCOUNT,
      loadingEntitlements: true,
    });
    try {
      const entitlements = await mspService.loadUserEntitlementsForAccount(apiUrl, userId, accountId);
      dispatch({
        entitlements: entitlements.services,
        type: UserActionTypes.GET_ENTITLEMENTS_FOR_ACCOUNT,
        loadingEntitlements: false,
      });
      return true;
    } catch (err) {
      handleError(err, dispatch, () => {
        dispatch({
          type: UserActionTypes.GET_ENTITLEMENTS_FOR_ACCOUNT,
          entitlements: [],
          loadingEntitlements: false,
        });
      });
      return false;
    }
  };
};

export const getUserParentAccountAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, AnyAction>> = (user: IUser) => {
  return async (_dispatch: Dispatch, getState: () => IAppState) => {
    const { mspAccounts, accountsNames, selectedAccount } = getState().accountState;
    return getUserParentAccount(selectedAccount, mspAccounts, accountsNames, user);
  };
};

async function loadAccountUsersExtraInfo(apiUrl: string, users: IUser[], account: IAccount, mspAccounts: IAccount[], cancelToken: CancelToken) {
  return loadAccountUsersExtraInfoRecursive(apiUrl, users, account, mspAccounts, cancelToken);
}

async function loadAccountUsersExtraInfoRecursive(apiUrl: string, users: IUser[], account: IAccount, mspAccounts: IAccount[], cancelToken: CancelToken): Promise<any> {
  let tokenExpired = false;

  const usersPromises = users.map(async (user: { id: number; email: string; name: string; explicitAccountId: number }) => {
    let role = undefined;
    let roleDisplay = "";
    let billingAdministration = false;
    let userManagement = false;
    let entitlements = [];
    let errorWhileLoading = undefined;
    let userId = user.id;
    const accountAccess = getAccountAccessForUserExplicitId(mspAccounts, user);

    try {
      const userAccount = await mspService.loadUserAccount(apiUrl, userId, account.id, true, cancelToken);
      const userEntitlements = await mspService.loadUserEntitlementsForAccount(apiUrl, userId, user.explicitAccountId, cancelToken);
      role = getRoleFromUserAccount(userAccount);
      roleDisplay = getDisplayUserRoleByAuthDbValue(userAccount);
      billingAdministration = userAccount?.userBillFlag === 1;
      userManagement = userAccount?.userAdminFlag === 1;
      entitlements = userEntitlements?.services ? userEntitlements?.services : [];
    } catch (error: any) {
      if (!(error instanceof OperationCanceledError)) {
        console.warn(`Could not retrive data for user ${userId} regarding account ${account.id}.`);
        if (error && error.errorMessage) {
          console.warn(`Api error message: ${error.errorMessage}`);
        }
        errorWhileLoading = `This user does not manage account s${account.name}`;
      } else {
        throw error;
      }
    }

    return {
      ...user,
      role: role,
      roleDisplay: roleDisplay,
      billingAdministration: billingAdministration,
      userManagement: userManagement,
      entitlements: entitlements,
      accountAccess: accountAccess,
      errorWhileLoading: errorWhileLoading,
    };
  });

  const results = await Promise.all(
    usersPromises.map(async userPromise => {
      try {
        return await userPromise;
      } catch (error: any) {
        if (isTokenExpiredError(error)) {
          tokenExpired = true;
        } else {
          throw error;
        }
      }
    }),
  );

  if (tokenExpired) {
    return TokenStorage.refreshEchoV3AccessToken().then(async () => {
      return loadAccountUsersExtraInfoRecursive(apiUrl, users, account, mspAccounts, cancelToken);
    });
  } else {
    return results as IUser[];
  }
}

export const setEntitlementsTableProps: ActionCreator<ThunkAction<any, IAppState, null, ISetEntitlementTableProps>> = (entitlementsTableState: State) => (dispatch: Dispatch) => dispatch({ type: UserActionTypes.SET_ENTITLEMENTS_TABLE_PROPS, entitlementsTableState });

export const setDuplicateEmailErrorAction: ActionCreator<ThunkAction<any, IAppState, null, ISetDuplicateEmailUser>> = (duplicateEmailError: boolean) => (dispatch: Dispatch) => dispatch({ type: UserActionTypes.SET_DUPLICATE_EMAIL_USER, duplicateEmailError });

import React, { useEffect, useState } from "react";
import { DataTable, DataTableColumn as Column, Pager } from "@cuda-networks/bds-core";
import { process, State } from "@progress/kendo-data-query";
import { getButtonCount } from "../../../utility";
import { filterTextInput, getColorForColumn } from "../../TableHelpers";
import { IAppState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { IIntegrationBillingMapFamily } from "../../../models/Integrations/IntegrationsBillingMap";
import { setBillingMapsTablePropsForIBU } from "../../../actions/integrations/integrationsBillingActions";
import IntegrationPlanNameCell from "./IntegrationPlanNameCell";

interface IIntegrationsBillingTableProps {
  integrationBillingMapFamily: IIntegrationBillingMapFamily;
}

const IntegrationsIbuBillingTable: React.FC<IIntegrationsBillingTableProps> = ({ integrationBillingMapFamily }) => {
  const dispatch = useDispatch();
  const ibuBillingMapTableState = useSelector((state: IAppState) => state.integrationsBillingMapState.ibuBillingMapTableState);
  const responsiveViewPortTriggerMin = useMediaQuery("(max-width: 1600px)");
  const [buttonCount, setButtonCount] = useState(10);

  const dataState = {
    skip: ibuBillingMapTableState.skip,
    take: ibuBillingMapTableState.take,
    sort: ibuBillingMapTableState.sort,
    filter: ibuBillingMapTableState.filter,
    collapsedGroups: [],
    selectedItem: "any",
    lastSelectedIndex: 0,
    columns: [
      {
        title: "PLAN",
        field: "name",
        show: true,
        filter: "text",
      },
      {
        title: "LEVEL",
        field: "level",
        show: true,
        filter: "text",
      },
    ],
  };

  const [gridState, setGridState] = useState({
    dataState,
    dataResult: process(integrationBillingMapFamily.maps, dataState as any),
  });

  useEffect(() => {
    setGridState({ dataState: gridState.dataState, dataResult: process(integrationBillingMapFamily.maps, gridState.dataState as any) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationBillingMapFamily.maps]);

  useEffect(() => {
    dispatch(setBillingMapsTablePropsForIBU({ sort: gridState.dataState.sort, take: gridState.dataState.take, skip: gridState.dataState.skip, filter: gridState.dataState.filter }));
    if (gridState.dataState.take !== ibuBillingMapTableState.take || gridState.dataState.skip !== ibuBillingMapTableState.skip) {
      setGridState({ dataState: gridState.dataState, dataResult: process(integrationBillingMapFamily.maps, gridState.dataState as any) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridState.dataState]);

  useEffect(() => {
    setButtonCount(getButtonCount(gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin));
  }, [gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin]);

  const dataStateChange = (e: any): void => {
    setGridState({
      dataState: { ...dataState, ...e.dataState },
      dataResult: process(integrationBillingMapFamily.maps, e.dataState),
    });
  };

  const IntegrationPlanName = (props: any) => <IntegrationPlanNameCell {...props} />;

  return (
    <DataTable
      className={"noScrollbar noBorders ProductsTable"}
      data={gridState.dataResult}
      resizable
      // page
      pageConfig={{
        pageable: {
          pageSizes: [10, 25, 50],
          buttonCount: buttonCount,
        },
        skip: gridState.dataState.skip,
        take: gridState.dataState.take,
        total: gridState.dataResult.total,
      }}
      groupable
      // sort
      sortConfig={{
        sortable: true,
        sort: ibuBillingMapTableState.skip,
      }}
      pager={gridState.dataResult.data.length > 0 && Pager}
      onDataStateChange={dataStateChange}
      selectedField="selected"
      {...(gridState.dataState as any)}
      style={{ maxWidth: "100%" }}
    >
      <Column key={"integrationBillingTablePlan"} cell={IntegrationPlanName} field={"name"} minResizableWidth={30} title={"PLAN"} filterable filter={"text"} columnMenu={filterTextInput} headerClassName={getColorForColumn("subname", gridState.dataState as State)} />
      <Column key={"integrationBillingTableLevel"} field={"level"} minResizableWidth={30} title={"LEVEL"} filterable filter={"text"} columnMenu={filterTextInput} headerClassName={getColorForColumn("level", gridState.dataState as State)} />
    </DataTable>
  );
};

export default IntegrationsIbuBillingTable;

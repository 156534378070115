import React, { useEffect, useState } from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import { Card, Link, CardContent, Typography, CardHeader, Button, LinearProgress } from "@cuda-networks/bds-core";
import * as CoreIcons from "@cuda-networks/bds-core/dist/Icons/Core";
import AddEditIntegrationDialog from "./AddEditIntegrationDialog";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/store";
import { deleteIntegrationAction, setSelectedIntegrationAction, loadIntegrationsAction, addIntegrationAction, setSelectedIntegrationsTabNameAction, loadConnectWiseIntegrationInfoAction, editIntegrationAction } from "../../../actions/integrations/integrationsActions";
import DeleteDialog from "../../DeleteDialog";
import IAccount from "../../../models/IAccount";
import IIntegrationSetup from "../../../models/Integrations/IIntegrationSetup";
import IntegrationType from "../../../models/IntegrationType";
import IntegrationStatus from "../../../models/Integrations/IntegrationStatus";
import { getIntegrationStatus } from "../../../Utilities/integrationsUtilities";
import { addHttps } from "../../../utility";
import IIntegration from "../../../models/Integrations/IIntegration";
import { setSnackBarMessage } from "../../../actions/generalActions";
import ActionMessageType from "../../../models/ActionMessageType";
import { ActionMessages, ActionTypes } from "../../../actions/ActionTypes";

const IntegrationsDetailsTab = () => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [addEditIntegrationTitle, setAddEditIntegrationTitle] = useState("");
  const [showEditIntegration, setShowEditIntegration] = useState(false);
  const [showDeleteIntegration, setShowDeleteIntegration] = useState(false);
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const selectedIntegration = useSelector((state: IAppState) => state.integrationsState.selectedIntegration);
  const integrations = useSelector((state: IAppState) => state.integrationsState.integrations);
  const cwIntegrationInfo = useSelector((state: IAppState) => state.integrationsState.cwIntegrationInfo);
  const loadingConnectWiseIntegrationInfo = useSelector((state: IAppState) => state.integrationsState.loadingConnectWiseIntegrationInfo);
  const [statusElement, setStatusElement] = useState<React.JSX.Element | undefined>();

  const handleGoToLogsTab = (event: React.SyntheticEvent) => {
    event.preventDefault();
    dispatch(setSelectedIntegrationsTabNameAction("LOGS"));
  };

  useEffect(() => {
    if (cwIntegrationInfo !== undefined) {
      if (cwIntegrationInfo.status === IntegrationStatus.NewLogs) {
        setStatusElement(
          <Link
            data-testid="integrationInfoStatusLink"
            href="#"
            onClick={(event: React.SyntheticEvent) => {
              handleGoToLogsTab(event);
            }}
          >
            New logs in the last 24 hours
          </Link>,
        );
      } else {
        setStatusElement(
          <Typography data-testid="integrationInfoStatusText" variant="h6">
            {getIntegrationStatus(cwIntegrationInfo.status, IntegrationType.ConnectWise)}
          </Typography>,
        );
      }
    }
    // eslint-disable-next-line
  }, [cwIntegrationInfo]);

  const handleOpenDialog = () => {
    setShowEditIntegration(!showEditIntegration);
    setIsEdit(true);
    setAddEditIntegrationTitle("Edit ConnectWise Manage® Integration");
  };

  const handleCloseDialog = () => {
    setShowEditIntegration(false);
    if (selectedIntegration?.id === undefined) {
      dispatch(setSelectedIntegrationAction(undefined));
    }
  };

  const handleOnCancelDeleteDialog = () => {
    setShowEditIntegration(true);
    setShowDeleteIntegration(false);
  };

  const handleOnDeleteDialog = () => {
    setShowEditIntegration(false);
    setShowDeleteIntegration(true);
  };

  const deleteIntegration = (account: IAccount, integration: IIntegration) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(deleteIntegrationAction(account, integration));
      resolve(success);
    });

  const handleOnSubmitDeleteDialog = () => {
    if (selectedIntegration && selectedIntegration.id) {
      setIsActionInProgress(true);
      setLoadingDelete(true);
      deleteIntegration(mspAccountLoggedIn, selectedIntegration).then(success => {
        setIsActionInProgress(false);
        setLoadingDelete(false);
        setShowDeleteIntegration(!success);
        if (success) {
          dispatch(loadIntegrationsAction(mspAccountLoggedIn.id));
          dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.DeleteIntegration].successMessage, type: ActionMessageType.Success }));
        }
      });
    }
  };

  const [deleteMessage, setDeleteMessage] = useState<React.JSX.Element>();
  const [deleteTitle, setDeleteTitle] = useState<React.JSX.Element>();

  useEffect(() => {
    if (selectedIntegration) {
      setDeleteMessage(
        <div>
          <Typography variant="h6">
            Deleting your ConnectWise integration will remove all account associations and billing/ticketing settings.
            <br />
            <br />
            Are you sure you would like to delete this integration?
          </Typography>
        </div>,
      );
      setDeleteTitle(<Typography variant="h6">DELETE INTEGRATION</Typography>);
      setIsEdit(selectedIntegration.id !== undefined);
    }
  }, [selectedIntegration]);

  useEffect(() => {
    if (selectedIntegration !== undefined && selectedIntegration.id === undefined) {
      setShowEditIntegration(true);
      setAddEditIntegrationTitle("Set up ConnectWise Manage® Integration");
    }
    // eslint-disable-next-line
  }, [selectedIntegration]);

  useEffect(() => {
    if (integrations) {
      let selectedIntegration = integrations.find(e => e.type === IntegrationType.ConnectWise);
      if (selectedIntegration) {
        dispatch(setSelectedIntegrationAction(selectedIntegration));
      }
    }
    // eslint-disable-next-line
  }, [integrations]);

  const addIntegration = (account: IAccount, setupIntegration: IIntegrationSetup) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(addIntegrationAction(account, setupIntegration));
      resolve(success);
    });

  const editIntegration = (account: IAccount, setupIntegration: IIntegrationSetup) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(editIntegrationAction(account, setupIntegration));
      resolve(success);
    });

  const handelOnSubmitAddIntegration = (integrationSetup: IIntegrationSetup) => {
    setIsActionInProgress(true);
    if (isEdit) {
      editIntegration(mspAccountLoggedIn, integrationSetup).then(success => {
        setIsActionInProgress(false);
        setShowEditIntegration(!success);
        if (success) {
          dispatch(loadIntegrationsAction(mspAccountLoggedIn.id));
          if (integrationSetup.integrationType === IntegrationType.ConnectWise) {
            dispatch(loadConnectWiseIntegrationInfoAction());
          }
          dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.EditIntegration].successMessage, type: ActionMessageType.Success }));
        }
      });
    } else {
      addIntegration(mspAccountLoggedIn, integrationSetup).then(success => {
        setIsActionInProgress(false);
        setShowEditIntegration(!success);
        if (success) {
          dispatch(loadIntegrationsAction(mspAccountLoggedIn.id));
          if (integrationSetup.integrationType === IntegrationType.ConnectWise) {
            dispatch(loadConnectWiseIntegrationInfoAction());
          }
          dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.AddIntegration].successMessage, type: ActionMessageType.Success }));
        }
      });
    }
  };

  return loadingConnectWiseIntegrationInfo ? (
    <div data-testid={"loadingConnectWiseIntegrationInfo"}>
      <LinearProgress />
    </div>
  ) : (
    <Grid data-testid="integrationInfo" item container spacing={3} xs={12} className={"IntegrationsDetailsTab"}>
      <Grid container item xs={12}>
        <Card variant="outlined" elevation={0} style={{ textAlign: "left", border: 0, width: "100%" }}>
          <CardHeader data-testid="integrationInfoPreferencesTitle" title={"Integration Status"} />
          <CardContent style={{ backgroundColor: "whitesmoke" }}>{statusElement}</CardContent>
        </Card>
      </Grid>
      <Grid container item xs={12}>
        <Card variant="outlined" elevation={0} style={{ textAlign: "left", border: 0, width: "100%" }}>
          <Grid container item xs={12}>
            <Grid container item xs={6}>
              <CardHeader data-testid="integrationInfoPreferencesTitle" title={"Preferences"} />
            </Grid>
            <Grid container item xs={6} style={{ justifyContent: "flex-end", alignItems: "center" }}>
              <Button data-testid="integrationEdit" color="primary" size="large" className="smallButton" onClick={handleOpenDialog}>
                Edit
              </Button>
            </Grid>
          </Grid>
          <CardContent style={{ backgroundColor: "whitesmoke" }}>
            <Typography data-testid="integrationInfoCompanyLbl" variant="subtitle2" style={{ color: "#8a8a8a" }}>
              Company Name
            </Typography>
            <Typography data-testid="integrationInfoCompanyTxt" variant="h6" gutterBottom noWrap>
              {cwIntegrationInfo?.companyName}
            </Typography>
            <Typography data-testid="integrationInfoUrlLbl" variant="subtitle2" style={{ color: "#8a8a8a" }}>
              URL
            </Typography>
            <div style={{ marginBottom: "6px", marginTop: "3px" }}>
              <Link data-testid="integrationInfoUrlLink" target="_blank" href={addHttps(cwIntegrationInfo?.url)}>
                <CoreIcons.Launch fontSize="inherit" id="linkCw" style={{ marginBottom: "-1px", marginRight: "5px" }} />
                {cwIntegrationInfo?.url}
              </Link>
            </div>
            <Typography data-testid="integrationInfoMemberPublicKeyLbl" variant="subtitle2" style={{ color: "#8a8a8a" }}>
              Member Public Key
            </Typography>
            <Typography data-testid="integrationInfoMemberPublicKeyTxt" variant="h6" gutterBottom noWrap>
              {cwIntegrationInfo?.publicKey}
            </Typography>
            <Typography data-testid="integrationInfoManagementSolutionLbl" variant="subtitle2" style={{ color: "#8a8a8a" }}>
              Management Solution Name
            </Typography>
            <Typography data-testid="integrationInfoManagementSolutionTxt" variant="h6" gutterBottom noWrap>
              {cwIntegrationInfo?.managementSolution}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      {showEditIntegration && <AddEditIntegrationDialog onSubmit={handelOnSubmitAddIntegration} onCancel={handleCloseDialog} showDialog={showEditIntegration} isLoadingDelete={loadingDelete} isActionInProgress={isActionInProgress} isEdit={isEdit} title={addEditIntegrationTitle} onDelete={handleOnDeleteDialog} />}
      {showDeleteIntegration && selectedIntegration && <DeleteDialog message={deleteMessage} title={deleteTitle} showDeleteDialog={showDeleteIntegration} onCancelDelete={handleOnCancelDeleteDialog} isActionInProgress={isActionInProgress} onSubmitDelete={handleOnSubmitDeleteDialog} hasActiveProducts={false} loadingDeleteDialog={false} />}
    </Grid>
  );
};
export default IntegrationsDetailsTab;

import React, { useEffect, useState } from "react";
import { Typography, Button, Divider, LinearProgress, CircularProgress } from "@cuda-networks/bds-core";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import MspType from "../../models/MspType";
import IAccount from "../../models/IAccount";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store/store";
import { deleteAccountAction, editAccountAction, getAccountAddressAction, adjustAccountsCurrentPageAfterDeleteAction, setSelectedAccountAction, navigateToAccountAction, getCountriesAction, getStatesAction, setExpandedPartnerAction, setM365AuthAction, fetchM365AuthAction, revokeM365AuthAction, fetchM365DomainListAction, filterAccountsAction, isAccountInDisplayedItemsAction, getAccountInfoAction } from "../../actions/accountActions";
import AddEditAccountDialog from "./AddEditAccount/AddEditAccountDialog";
import DeleteDialog from "../DeleteDialog";
import { cancelCurrentAction, setSelectedTabName, setSnackBarMessage, setViewMspAccountsAction } from "../../actions/generalActions";
import DetailsTabs from "../../models/DetailsTabs";
import ICountry from "../../models/ICountry";
import { getProductsAction } from "../../actions/productActions";
import IProductFamily from "../../models/Products/IProductFamily";
import { getAccountDisplayType, getCustomerSubpartnerText, getNumberOfAccountsForMsp, getShowResourcesButton, setEditDialogTitle } from "../../Utilities/accountsHelper";
import AccountIcon from "./AccountIcon";
import { truncate, isNotFinance, getGoToEchoPlatformLink, isStringNullOrEmpty, areAllPropertiesEmpty } from "../../utility";
import { Tooltip } from "@material-ui/core";
import SvgInfo from "@cuda-networks/bds-core/dist/Icons/Feedback/Info";
import { getAccountUsageInfoIconTooltip } from "../../businessLogic/components/Accounts/AccountUsageInfoIcon";
import AddressesCardPanel from "./AddressesCardPanel";
import IAddress from "../../models/IAddress";
import IAccountAddress from "../../models/IAccountAddress";
import M365AuthLinkDialog from "./M365AuthLinkDialog";
import IAccountM365DomainList from "../../models/IAccountM365DomainList";
import PartnerResourcesButton from "./PartnerResourcesButton";
import M365LinkIcon from "./M356LinkIcon";
import ActionMessageType from "../../models/ActionMessageType";
import TabTitle from "../TabTitle";
import { ActionMessages, ActionTypes } from "../../actions/ActionTypes";

const AccountDetailsTab = () => {
  const dispatch = useDispatch();
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const loadingAddress = useSelector((state: IAppState) => state.accountState.loadingAddress);
  const mspAccounts = useSelector((state: IAppState) => state.accountState.mspAccounts);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const addressToDisplay = useSelector((state: IAppState) => state.accountState.addressToDisplay);
  const filters = useSelector((state: IAppState) => state.accountState.filters);
  const productsToDisplay = useSelector((state: IAppState) => state.productState.productsToDisplay);
  const loadingProducts = useSelector((state: IAppState) => state.productState.loadingProducts);
  const hasIBU = useSelector((state: IAppState) => state.accountState.hasIBU);
  const loadingAccountInfo = useSelector((state: IAppState) => state.accountState.loadingAccountInfo);
  const loadingEchoUserName = useSelector((state: IAppState) => state.accountState.loadingEchoUserName);
  const echoUserName = useSelector((state: IAppState) => state.accountState.echoUserName);
  const echoUrl = useSelector((state: IAppState) => state.generalState.echoUrl);
  const notes = useSelector((state: IAppState) => state.accountState.notes);
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const [showEditAccount, setShowEditAccount] = useState(false);
  const [showM365AuthLinkDialog, setShowM365AuthLinkDialog] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState<React.JSX.Element>();
  const [deleteTitle, setDeleteTitle] = useState<React.JSX.Element>();
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadedCountries, setLoadedCountries] = useState<ICountry[]>([]);
  const countries = useSelector((state: IAppState) => state.accountState.countries);
  const [activeProducts, setActiveProducts] = useState(false);
  const [dialogStep, setDialogStep] = useState(0);
  const [addressCardHeight, setAddressCardHeight] = useState(0);
  const [m365Unlinking, setM365Unlinking] = useState(false);
  const [m365AuthErrMsg, setM365AuthErrMsg] = useState("");
  const [m365AuthInProgress, setM365AuthInProgress] = useState(false);
  const [m365AuthFail, setM365AuthFail] = useState(false);
  const [m365DomainList, setM365DomainList] = useState<IAccountM365DomainList>();
  const [m365FetchDomainListErrMsg, setM365FetchDomainListErrMsg] = useState("");
  const [m365FetchDomainListFail, setM365FetchDomainListFail] = useState(false);
  const [m365FetchDomainListInProgress, setM365FetchDomainListInProgress] = useState(true);
  const [m365FetchDomainListSucceed, setM365FetchDomainListSucceed] = useState(false);
  const [showResourcesButton, setShowResourcesButton] = useState(false);
  const [loadingDeleteDialog, setLoadingDeleteDialog] = useState(false);
  const [redirectEchoPlatformLink, setRedirectEchoPlatformLink] = useState("");
  const [showEchoUserName, setShowEchoUserName] = useState(false);
  const [showCustomField, setShowCustomField] = useState(false);
  const [notesValue, setNotesValue] = useState("--");

  useEffect(() => {
    setShowResourcesButton(getShowResourcesButton(mspAccountLoggedIn, selectedAccount));
  }, [mspAccountLoggedIn, selectedAccount]);

  useEffect(() => {
    setLoadedCountries(countries);
  }, [countries]);

  useEffect(() => {
    setInitialM365AuthLinkDialogStatus();
    setM365FetchDomainListInProgress(true);
    new Promise<any>((resolve, reject) => {
      const result = dispatch(fetchM365DomainListAction(selectedAccount!, 0, 5));
      resolve(result);
    })
      .then((success: any) => {
        setM365DomainList(success);
        setM365FetchDomainListSucceed(true);
      })
      .catch(async (error: Error) => {
        setM365FetchDomainListFail(true);
        setM365FetchDomainListErrMsg(error.message);
      })
      .finally(() => {
        setM365FetchDomainListInProgress(false);
      });
  }, [selectedAccount, dispatch]);

  useEffect(() => {
    if (loadingProducts) {
      setLoadingDeleteDialog(true);
    } else {
      setLoadingDeleteDialog(false);
    }
  }, [loadingProducts]);

  useEffect(() => {
    if (notes && !isStringNullOrEmpty(notes)) {
      setNotesValue(notes);
    } else {
      setNotesValue("--");
    }
  }, [notes]);

  const loadCountries = () =>
    new Promise<any>((resolve, reject) => {
      const newAccountId = dispatch(getCountriesAction());
      resolve(newAccountId);
    });

  const loadStates = (country: string) =>
    new Promise<any>((resolve, reject) => {
      const states = dispatch(getStatesAction(country));
      resolve(states);
    });

  function followUp(isBillingFlag: boolean, isLoadingEdit: boolean) {
    setLoadingEdit(isLoadingEdit);
  }

  function loadStatesForCountry(address: IAddress, countriesList: ICountry[], callback: any) {
    const index = countriesList.findIndex((x: ICountry) => x.countryName === address.country);
    if (index > -1) {
      loadStates(countriesList[index].country).then(() => {
        callback();
      });
    }
  }

  function loadCountriesAndSetAddress(isBillingFlag: boolean, countriesList: ICountry[], accountAddress: IAccountAddress) {
    if (accountAddress.contact) {
      const contactCountryIndex = countriesList.findIndex((x: ICountry) => x.countryName === accountAddress.contact?.country);
      if (contactCountryIndex > -1) {
        loadStates(countriesList[contactCountryIndex].country).then(() => {
          if (!accountAddress.isContactBillingSame && accountAddress.billing && !areAllPropertiesEmpty(accountAddress.billing)) {
            loadStatesForCountry(accountAddress.billing, countriesList, () => followUp(isBillingFlag, false));
          } else {
            followUp(isBillingFlag, false);
          }
        });
      }
    } else {
      if (accountAddress.billing && !areAllPropertiesEmpty(accountAddress.billing)) {
        loadStatesForCountry(accountAddress.billing, countriesList, () => followUp(isBillingFlag, false));
      } else {
        followUp(isBillingFlag, false);
      }
    }
  }

  const handleOpenEditDialog = (isBilling: boolean) => {
    setDialogStep(isBilling ? 1 : 0);
    setShowEditAccount(!showEditAccount);
    if (selectedAccount && addressToDisplay) {
      setLoadingEdit(true);
      if (loadedCountries.length <= 0) {
        loadCountries().then(result => {
          if (result) {
            loadCountriesAndSetAddress(isBilling, result, addressToDisplay);
          }
        });
      } else {
        loadCountriesAndSetAddress(isBilling, loadedCountries, addressToDisplay);
      }
    }
  };

  const handleOnEditClicked = (entity: IAccount, notes?: string) => {
    setIsActionInProgress(true);
    editAccount(entity, notes);
  };

  const handleOnCancel = () => {
    setShowEditAccount(!showEditAccount);
  };

  const loadProducts = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getProductsAction(account));
      resolve(result);
    });

  const handleOnDelete = () => {
    setShowDeleteAccount(true);
    if (selectedAccount) {
      loadProducts(selectedAccount).then(products => {
        if (products) {
          if (!hasActiveSerials(products)) {
            if (hasIBU) {
              if (selectedAccount.type === MspType.Customer) {
                setRedirectEchoPlatformLink(getGoToEchoPlatformLink(echoUrl, mspAccountLoggedIn, selectedAccount, echoUserName));
              } else {
                setRedirectEchoPlatformLink(getGoToEchoPlatformLink(echoUrl, mspAccountLoggedIn, selectedAccount));
              }
            }
          }
        }
      });
    }
  };

  const handleOnCancelDeleteDialog = () => {
    setShowDeleteAccount(false);
    if (loadingProducts) {
      dispatch(cancelCurrentAction());
    }
  };

  const deleteAccount = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(deleteAccountAction(selectedAccount));
      resolve(success);
    });

  const handleOnSubmitDeleteDialog = () => {
    if (selectedAccount) {
      setIsActionInProgress(true);
      deleteAccount(selectedAccount).then(success => {
        setIsActionInProgress(false);
        setShowDeleteAccount(!success);
        if (success) {
          if (selectedAccount.type === MspType.Subpartner) {
            dispatch(setViewMspAccountsAction(true));
            dispatch(setExpandedPartnerAction(undefined));
          }
          dispatch(setSnackBarMessage({ message: `${getAccountDisplayType(selectedAccount.type)} successfully deleted.`, type: ActionMessageType.Success }));
          dispatch(adjustAccountsCurrentPageAfterDeleteAction());
          dispatch(setSelectedAccountAction(undefined));
        }
      });
    }
  };

  const updateAccount = (accountToUpdate: IAccount, notes?: string) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(editAccountAction(accountToUpdate, notes));
      resolve(success);
    });

  const isAccountInDisplayedItems = (accountToUpdate: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(isAccountInDisplayedItemsAction(accountToUpdate));
      resolve(success);
    });

  const editAccount = (entity: IAccount, notes?: string) => {
    updateAccount(entity, notes).then(success => {
      setIsActionInProgress(false);
      setShowEditAccount(!success);
      if (success) {
        isAccountInDisplayedItems(entity).then(found => {
          if (found === true) {
            dispatch(navigateToAccountAction(entity.id));
            dispatch(getAccountAddressAction(entity));
            dispatch(getAccountInfoAction(entity));
            dispatch(setSelectedTabName(DetailsTabs.AccountsDetails));
            dispatch(setSelectedAccountAction(entity));
            dispatch(fetchM365AuthAction(entity));
            dispatch(setSnackBarMessage({ message: `${getAccountDisplayType(entity.type)} successfully updated.`, type: ActionMessageType.Success }));
          } else {
            dispatch(adjustAccountsCurrentPageAfterDeleteAction());
            dispatch(setSelectedAccountAction(undefined));
          }
        });
      }
    });
  };

  const hasActiveSerials = (products: IProductFamily[]) => {
    return products.length > 0 ? products.filter(x => x.products.find(a => a.serials.length > 0)).length > 0 : false;
  };

  const addTooltipToDeleteMessage = (account: IAccount) => {
    if (account.name.length > 20) {
      return (
        <Tooltip title={account.name}>
          <b> {truncate(account.name, 20)}</b>
        </Tooltip>
      );
    } else {
      return <b> {account.name}</b>;
    }
  };

  const setInitialM365AuthLinkDialogStatus = () => {
    setM365FetchDomainListSucceed(false);
    setM365FetchDomainListFail(false);
    setM365FetchDomainListErrMsg("");
    setM365AuthErrMsg("");
    setM365AuthInProgress(false);
    setM365AuthFail(false);
  };

  const handleOnCancelM365AuthLinkDialog = () => {
    setShowM365AuthLinkDialog(false);
  };

  const m365InfoIcon = () => {
    return (
      <Tooltip title={getAccountUsageInfoIconTooltip}>
        <span data-testid="m365InfoIcon">
          <SvgInfo color={"secondary"}></SvgInfo>
        </span>
      </Tooltip>
    );
  };

  const fetchM365Auth = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(fetchM365AuthAction(account));
      resolve(result);
    });

  const connectM365Auth = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(setM365AuthAction(account));
      resolve(result);
    });

  const revokeM365Auth = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(revokeM365AuthAction(account));
      resolve(result);
    });

  const fetchM365DomainList = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(fetchM365DomainListAction(account, 0, 5));
      resolve(result);
    });

  async function retry(fn: any, retriesLeft = 3, interval = 10000) {
    return await fn
      .then((success: any) => {
        setM365FetchDomainListInProgress(false);
        setM365DomainList(success);
        setM365FetchDomainListSucceed(true);
      })
      .catch(async (error: Error) => {
        if (retriesLeft) {
          await new Promise(r => setTimeout(r, interval));
          return retry(fetchM365DomainList(selectedAccount!), retriesLeft - 1, interval);
        } else {
          setM365FetchDomainListInProgress(false);
          setM365FetchDomainListFail(true);
          setM365FetchDomainListErrMsg(error.message);
        }
      });
  }

  const handleOnBtnM365DomainList = (account?: IAccount) => {
    setShowM365AuthLinkDialog(true);
  };

  const fetchDomainListsAfterAuth = (account?: IAccount) => {
    setInitialM365AuthLinkDialogStatus();
    setM365FetchDomainListInProgress(true);
    if (account) {
      retry(fetchM365DomainList(account), 3);
    }
  };

  const handleOnBtnM365Link = (account?: IAccount) => {
    if (account == null) {
      return;
    }

    setInitialM365AuthLinkDialogStatus();
    if (account?.m365AuthLinked && account?.m365AuthLinked === 1) {
      setM365Unlinking(true);
      revokeM365Auth(account).then(success => {
        if (success.status === "success") {
          dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.UnlinkAccountFromM365].successMessage, type: ActionMessageType.Success }));
          fetchM365Auth(account).finally(() => {
            setM365Unlinking(false);
          });
        } else {
          setM365Unlinking(false);
        }
        if (filters?.onlyM365Linked || filters?.onlyM365Unlinked) {
          dispatch(filterAccountsAction());
        }
      });
    } else {
      setShowM365AuthLinkDialog(true);
      setM365AuthInProgress(true);
      connectM365Auth(account).then(success => {
        if (success && success.isSuccessful && success.authorizeUrl) {
          let popupWindow = window.open(success.authorizeUrl, "", "");
          if (popupWindow !== null) {
            const intervalId = setInterval(function () {
              if (popupWindow?.closed) {
                clearInterval(intervalId);
                handleOnCancelM365AuthLinkDialog();
                return;
              }
              let href = "";
              try {
                if (popupWindow?.location.href) {
                  href = popupWindow?.location.href;
                }
              } catch (e) {}

              if (href === "" || href === "about:blank") {
                return;
              }
              const url = new URL(href);
              const curUrl = new URL(window.location.href);
              if (url.hostname === curUrl.hostname) {
                popupWindow?.close();
                if (url.searchParams.get("error") != null && url.searchParams.get("error_description") != null) {
                  let desc = url.searchParams.get("error_description");
                  if (desc !== null) {
                    setM365AuthInProgress(false);
                    setM365AuthErrMsg(desc);
                    setM365AuthFail(true);
                  }
                } else {
                  setM365AuthInProgress(false);
                  fetchDomainListsAfterAuth(account);
                  dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.LinkAccountToM365].successMessage, type: ActionMessageType.Success }));
                }
                setM365Unlinking(true);
                fetchM365Auth(account).finally(() => {
                  setM365Unlinking(false);
                });
              }
              clearInterval(intervalId);
            }, 50);
          }
          if (filters?.onlyM365Linked || filters?.onlyM365Unlinked) {
            dispatch(filterAccountsAction());
          }
        }
      });
    }
  };

  const m365LinkButtonText = (account?: IAccount) => {
    if (account?.m365AuthLinked && account?.m365AuthLinked === 1) {
      return "UNLINK M365 ACCOUNT";
    }
    return "LINK M365 ACCOUNT";
  };

  const m365DomainListButton = (account?: IAccount) => {
    if (account?.m365AuthLinked && account?.m365AuthLinked === 1) {
      return (
        <div style={{ display: "inline", marginLeft: "15px" }}>
          <Button data-testid="m365DomainBtn" variant={"contained"} color={"secondary"} size={"medium"} onClick={() => handleOnBtnM365DomainList(account)}>
            DOMAIN LIST
          </Button>
        </div>
      );
    }
  };

  const m365LinkButton = (account?: IAccount) => {
    if (mspAccountLoggedIn.type !== MspType.Customer && isNotFinance(mspAccountLoggedIn) && account) {
      if (account.m365AuthLinked === undefined || m365FetchDomainListInProgress) {
        return <CircularProgress />;
      }

      return (
        <Grid>
          <Button data-testid="m365Btn" isLoading={m365Unlinking} variant={"contained"} color={"secondary"} size={"medium"} onClick={() => handleOnBtnM365Link(account)}>
            {m365LinkButtonText(account)}
          </Button>
          {m365DomainListButton(account)}
        </Grid>
      );
    }
  };

  const m365LinkHeader = (account?: IAccount) => {
    return (
      <div style={{ display: "flex" }}>
        <Typography data-testid="m365Lbl" variant="subtitle2" style={{ color: "#8a8a8a" }}>
          M365 Account
        </Typography>
        <div className={"headerM365LinkUserIcon"}>{m365InfoIcon()}</div>
        {mspAccountLoggedIn.type !== MspType.Customer && account && (
          <div className={"headerM365LinkUserIcon"}>
            <M365LinkIcon account={account} />
          </div>
        )}
      </div>
    );
  };

  interface IM365ButtonProps {
    loggedIn: IAccount;
    selected?: IAccount;
  }

  const M365LinkButton: React.FC<IM365ButtonProps> = ({ loggedIn, selected }) => {
    return (
      <div>
        {m365LinkHeader(selected)}
        <Grid container item direction="row">
          {m365LinkButton(selected)}
        </Grid>
      </div>
    );
  };

  const addTooltipToDeleteTitle = (account: IAccount) => {
    let textValue;
    if (account.name.length > 20) {
      textValue = getCustomerSubpartnerText(account, "DELETE ACCOUNT ", "DELETE SUBPARTNER ") + truncate(account.name, 20);
      return (
        <Tooltip title={account.name}>
          <Typography variant="h6">{textValue}</Typography>
        </Tooltip>
      );
    } else {
      textValue = getCustomerSubpartnerText(selectedAccount, "DELETE ACCOUNT ", "DELETE SUBPARTNER ") + account.name;
      return <Typography variant="h6">{textValue}</Typography>;
    }
  };

  useEffect(() => {
    if (selectedAccount?.type.includes(MspType.Customer)) {
      if (loadingAccountInfo || loadingAddress) {
        setShowCustomField(false);
      } else {
        setShowCustomField(true);
      }
    } else {
      setShowCustomField(false);
    }
  }, [selectedAccount, loadingAccountInfo, loadingAddress]);

  useEffect(() => {
    if (loadingEchoUserName || loadingAddress) {
      setShowEchoUserName(false);
    } else {
      setShowEchoUserName(true);
    }
  }, [selectedAccount, loadingEchoUserName, loadingAddress]);

  useEffect(() => {
    if (selectedAccount) {
      if (!hasActiveSerials(productsToDisplay)) {
        if (hasIBU) {
          setActiveProducts(false);
          setDeleteMessage(
            <div>
              <Typography variant="h6">
                {getCustomerSubpartnerText(selectedAccount, "The account", "The Subpartner")} you are attempting to delete has IBU data associated with it.
                <br />
                <a href={redirectEchoPlatformLink} rel="noopener noreferrer" target="_blank">
                  GO TO ECHOplatform
                </a>{" "}
                <br />
                Are you sure you want to delete {getCustomerSubpartnerText(selectedAccount, "account", "Subpartner")} {addTooltipToDeleteMessage(selectedAccount)}?
              </Typography>
            </div>,
          );
          setDeleteTitle(<Typography variant="h6">{getCustomerSubpartnerText(selectedAccount, "DELETE ACCOUNT ", "DELETE SUBPARTNER ")}</Typography>);
        } else {
          let noOfAccounts = 0;
          if (selectedAccount.type === MspType.Subpartner) {
            noOfAccounts = getNumberOfAccountsForMsp(mspAccounts, selectedAccount);
          }
          setActiveProducts(false);
          setDeleteMessage(
            <div>
              <Typography variant="h6">
                Are you sure you want to delete {getCustomerSubpartnerText(selectedAccount, "account", "Subpartner")}
                {addTooltipToDeleteMessage(selectedAccount)}?
              </Typography>
              <br />
              {noOfAccounts !== 0 ? <Typography variant="h6">This Subpartner has {noOfAccounts} account(s). By deleting it, you will automatically delete all its accounts.</Typography> : null}
            </div>,
          );
          setDeleteTitle(<Typography variant="h6">{getCustomerSubpartnerText(selectedAccount, "DELETE ACCOUNT", "DELETE SUBPARTNER")}</Typography>);
        }
      } else {
        setActiveProducts(true);
        setDeleteMessage(
          <div>
            <Typography variant="h6">
              {getCustomerSubpartnerText(selectedAccount, "The account", "The Subpartner")} you are attempting to delete has active serial numbers associated with it.
              <br />
              <br />
              To delete,{" "}
              <a href="https://barracudamsp.com/essentials-change/" rel="noopener noreferrer" target="_blank">
                click here
              </a>{" "}
              or call: <b>1-800-569-0155</b>, Option 1. <br />
              Or you can manually remove/deactivate the serials from within this app, by clicking on <b>Edit</b> serial button and then try to delete the account again.
            </Typography>
            <br />
          </div>,
        );
        setDeleteTitle(addTooltipToDeleteTitle(selectedAccount));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount, productsToDisplay, mspAccounts, hasIBU, redirectEchoPlatformLink]);

  const handleSetHeight = (value: number) => {
    setAddressCardHeight(value);
  };

  const showDeleteButton = () => {
    let button = null;
    if (mspAccountLoggedIn.userAdminFlag) {
      if (mspAccountLoggedIn.id !== selectedAccount?.id) {
        button = (
          <Button data-testid="deleteAccountBtn" isLoading={loadingAccountInfo} variant="contained" color="secondary" type={"submit"} disabled={isActionInProgress || loadingAccountInfo} onClick={handleOnDelete}>
            DELETE
          </Button>
        );
      }
    }
    return button;
  };

  return (
    <Grid container item xs={12} className={"AccountDetailsTab"}>
      <Grid item xs={12} style={{ alignContent: "center", textAlign: "left" }}>
        <Grid item container spacing={3} direction="column">
          <Grid item container>
            <Grid container item xs={10}>
              <TabTitle tabType={DetailsTabs.AccountsDetails} item={selectedAccount} />
            </Grid>
            <Grid item xs={2} className="centerVertically">
              <Grid container item direction="row" style={{ justifyContent: "end" }}>
                {showDeleteButton()}
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} direction="row">
            <Grid item xs={3}>
              <Typography data-testid="idLbl" variant="subtitle2" style={{ color: "#8a8a8a" }}>
                ID
              </Typography>
              <Typography data-testid="idTxt" variant="h6" gutterBottom>
                {selectedAccount?.id}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography data-testid="typeLbl" variant="subtitle2" style={{ color: "#8a8a8a" }}>
                Type
              </Typography>
              <Grid container item direction="row">
                <div style={{ position: "relative", top: "4px", right: "3px" }}>
                  <AccountIcon entityType={selectedAccount?.type ? selectedAccount?.type : ""}></AccountIcon>
                </div>
                <Typography data-testid="typeTxt" variant="h6" gutterBottom>
                  {getAccountDisplayType(selectedAccount?.type)}
                </Typography>
              </Grid>
            </Grid>
            {selectedAccount?.type === MspType.Customer && (
              <Grid container item xs={6} direction="column">
                <M365LinkButton loggedIn={mspAccountLoggedIn} selected={selectedAccount} />
              </Grid>
            )}
            {showResourcesButton && (
              <Grid item xs style={{ marginRight: "15px", flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
                <PartnerResourcesButton />
              </Grid>
            )}
          </Grid>

          <Grid container item xs={12} direction="row">
            {showEchoUserName && (
              <Grid item xs={3}>
                <Typography data-testid="EchoUserNameLbl" variant="subtitle2" style={{ color: "#8a8a8a" }}>
                  Echo Username
                </Typography>
                <Typography data-testid="EchoUserNameTxt" variant="h6" gutterBottom>
                  {echoUserName}
                </Typography>
              </Grid>
            )}
            {showCustomField && (
              <Grid item xs={6}>
                <Typography data-testid="NotesLbl" variant="subtitle2" style={{ color: "#8a8a8a" }}>
                  Notes
                </Typography>
                <Typography data-testid="NotesTxt" variant="h6" gutterBottom>
                  {notesValue}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Divider />
        {loadingAddress && <LinearProgress data-testid="loadingAddress" />}
      </Grid>

      {!loadingAddress && addressToDisplay && <AddressesCardPanel mspAccountLoggedIn={mspAccountLoggedIn} selectedAccount={selectedAccount} addressToDisplay={addressToDisplay} handleOpenEditDialog={(isBilling: boolean) => handleOpenEditDialog(isBilling)} handleSetHeight={(value: number) => handleSetHeight(value)} loadingEdit={loadingEdit} addressCardHeight={addressCardHeight} countries={loadedCountries} />}

      {showEditAccount && selectedAccount && <AddEditAccountDialog dialogStep={dialogStep} showDialog={showEditAccount} onCancel={handleOnCancel} selectedItem={selectedAccount} addressToDisplay={addressToDisplay} mspType={selectedAccount.type as MspType} /*TODO*/ isActionInProgress={isActionInProgress} isEdit={true} account={selectedAccount} onSubmit={(partnerId: number, entity: IAccount, linkToM365?: boolean, notes?: string) => handleOnEditClicked(entity, notes)} title={setEditDialogTitle(selectedAccount)} loadingAddEditDialog={loadingEdit} />}
      {showDeleteAccount && selectedAccount && <DeleteDialog message={deleteMessage} title={deleteTitle} showDeleteDialog={showDeleteAccount} onCancelDelete={handleOnCancelDeleteDialog} isActionInProgress={isActionInProgress} onSubmitDelete={handleOnSubmitDeleteDialog} hasActiveProducts={activeProducts} loadingDeleteDialog={loadingDeleteDialog} />}
      {showM365AuthLinkDialog && <M365AuthLinkDialog authErrMsg={m365AuthErrMsg} showDialog={showM365AuthLinkDialog} onCancel={handleOnCancelM365AuthLinkDialog} authInProgress={m365AuthInProgress} authFail={m365AuthFail} authDomainList={m365DomainList!} fetchDomainListSucceed={m365FetchDomainListSucceed} fetchDomainListFail={m365FetchDomainListFail} fetchDomainListErrMsg={m365FetchDomainListErrMsg} fetchDomainListInProgress={m365FetchDomainListInProgress} account={selectedAccount} />}
    </Grid>
  );
};
export default AccountDetailsTab;

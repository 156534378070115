import React from "react";
import "../../table.css";
import { useSelector, useDispatch, batch } from "react-redux";
import { IAppState } from "../../store/store";
import { setDisplayCustomersByAccountId, setSelectedAccountAction, setExpandedPartnerAction, getCustomers, cancelLoadSubpartnerCustomersAction, loadParentAccountWithChildrenAction, setSelectedAccountToFilterProductsForBA, setshowEmptyPanelAction, setAccountsPageSizeAction, setAccountsPageNumberAction } from "../../actions/accountActions";
import MspType from "../../models/MspType";
import { setError, setViewMspAccountsAction } from "../../actions/generalActions";
import { clearAuditStateBeforeSearch, setFilterByOption, setSearchedAuditUserEmail } from "../../actions/auditUsersActions";
import { setIntegrationsAction, setLoadedIntegrationAction, setSelectedIntegrationAction, setSelectedIntegrationsTabNameAction } from "../../actions/integrations/integrationsActions";
import DefaultIntegrations from "../../models/Integrations/DefaultIntegrations";
import { setShowUnlinkedAccountsAction } from "../../actions/integrations/integrationsAccountsActions";
import { Dispatch } from "redux";
import IAccount from "../../models/IAccount";
import { shouldGetParent } from "../../businessLogic/components/Accounts/AccountsTable";
import { NoMspTypeError } from "../../models/constants";
import AccountsTableWithoutState from "./AccountsTableBase";
import { AuditUserFilterByOption } from "../../models/Products/IAuditUserDisplayState";
import { setIntegrationLogsTablePropsActionToFirstPage } from "../../actions/integrations/integrationsLogsActions";
import { setIntegrationBillingTablePropsActionToFirstPage } from "../../actions/integrations/integrationsBillingActions";

interface IAccountsTableProps {
  showExpandIcon: boolean;
}

const AccountsTable: React.FC<IAccountsTableProps> = ({ showExpandIcon }) => {
  const dispatch = useDispatch();
  const pageSize = useSelector((state: IAppState) => state.accountState.accountsPageSize);
  const pageNumber = useSelector((state: IAppState) => state.accountState.accountsPageNumber);
  const items = useSelector((state: IAppState) => state.accountState.itemsToDisplay);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const hasSubpartners = useSelector((state: IAppState) => state.generalState.hasSubpartners);
  const viewSearchResults = useSelector((state: IAppState) => state.generalState.viewSearchResults);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const loadingAccountId = useSelector((state: IAppState) => state.accountState.loadingAccountId);
  const isBaLoggedIn = useSelector((state: IAppState) => state.generalState.isBaLoggedIn);

  const getSubpartnerCustomers = (account: IAccount, updateItemsToDisplay: boolean) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(getCustomers(account.id, updateItemsToDisplay));
      resolve(success);
    });

  const getParent = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(loadParentAccountWithChildrenAction(account.id));
      resolve(success);
    });

  const rowClick = (item: any) => {
    if (!viewSearchResults) {
      if (mspAccountLoggedIn.type === MspType.Partner && item.type === MspType.Subpartner && !isBaLoggedIn) {
        dispatch(setAccountsPageNumberAction(1));
      }
    }
  };

  const onItemSelected = (e: any): void => {
    const item = e;

    let enableLoading = true;
    if (selectedAccount !== undefined && selectedAccount !== null) {
      if (item.id === selectedAccount.id) {
        enableLoading = false;
        dispatch(setshowEmptyPanelAction(false));
      }
    } else if (item.id === loadingAccountId) {
      enableLoading = false;
    }

    if (enableLoading) {
      batch(() => {
        dispatch(setSearchedAuditUserEmail(""));
        dispatch(clearAuditStateBeforeSearch());
        dispatch(setFilterByOption(AuditUserFilterByOption.BILLABLE_USER));
        if (!viewSearchResults) {
          if (isBaLoggedIn) {
            dispatch(setSelectedAccountAction(item));
            rowClick(item);
            if (item.type === MspType.Partner) {
              dispatch(setExpandedPartnerAction(item));
              dispatch(setSelectedAccountToFilterProductsForBA(undefined));
            }
          } else {
            if (item.type === MspType.Subpartner) {
              if (selectedAccount?.partnerId === item.id || mspAccountLoggedIn.type === MspType.Subpartner) {
                setSubpartnerAsSelectedAccount(mspAccountLoggedIn.type === MspType.Subpartner);
              } else {
                getSubpartnerCustomers(item, true).then(response => {
                  if (response) {
                    setSubpartnerAsSelectedAccount(false);
                  }
                });
              }
            } else if (item.type === MspType.Partner) {
              dispatch(cancelLoadSubpartnerCustomersAction());
              rowClick(item);
              if (hasSubpartners) {
                dispatch(setViewMspAccountsAction(false));
              }
              dispatch(setDisplayCustomersByAccountId(item));
              dispatch(setExpandedPartnerAction(item));
              dispatch(setSelectedAccountAction(item));
            } else {
              if (hasSubpartners) {
                dispatch(setViewMspAccountsAction(false));
              }
              dispatch(setSelectedAccountAction(item));
            }
          }
        } else {
          if (shouldGetParent(item, mspAccountLoggedIn)) {
            getParent(item).then(response => {
              if (response) {
                const partnerId = response.partnerId;
                if (partnerId) {
                  rowClick(item);
                  dispatch(setSelectedAccountAction({ ...item, partnerId: partnerId }));
                } else {
                  if (response.reason === NoMspTypeError) {
                    dispatch(setError("Parent of account ID " + item.id + " has no MSP type"));
                  }
                }
              }
            });
          } else {
            rowClick(item);
            dispatch(setSelectedAccountAction(item));
          }
        }

        if (isBaLoggedIn) {
          dispatch(setSelectedAccountToFilterProductsForBA(undefined));
        } else if (item.type !== MspType.Partner) {
          resetCw(dispatch);
        }

        function setSubpartnerAsSelectedAccount(setViewMsp: boolean) {
          rowClick(item);
          dispatch(setViewMspAccountsAction(setViewMsp));
          dispatch(setExpandedPartnerAction(item));
          dispatch(setSelectedAccountAction(item));
        }
      });
    }
  };

  const onPageNumberChange = (value: number): void => {
    dispatch(setAccountsPageNumberAction(value));
  };

  const onPageSizeChange = (value: number): void => {
    dispatch(setAccountsPageSizeAction(value));
  };

  return <AccountsTableWithoutState showExpandIcon={showExpandIcon} pageSize={pageSize} pageNumber={pageNumber} items={items} selectedAccount={selectedAccount} hasSubpartners={hasSubpartners} viewSearchResults={viewSearchResults} loadingAccountId={loadingAccountId} onItemSelected={onItemSelected} onPageSizeChange={onPageSizeChange} onPageNumberChange={onPageNumberChange} showCheckboxes={false} checkboxesDisabled={false} isBaLoggedIn={isBaLoggedIn} onItemChecked={() => {}} showInfoIcon={false} defaultPagination={[10, 25, 50]} />;
};

export default AccountsTable;

export function resetCw(dispatch: Dispatch<any>) {
  dispatch(setIntegrationsAction(DefaultIntegrations));
  dispatch(setSelectedIntegrationAction(undefined));
  dispatch(setLoadedIntegrationAction(undefined));
  dispatch(setIntegrationLogsTablePropsActionToFirstPage());
  dispatch(setSelectedIntegrationsTabNameAction(undefined));
  dispatch(setShowUnlinkedAccountsAction(false));
  dispatch(setIntegrationBillingTablePropsActionToFirstPage());
}

import { IPrivilege } from "../../../../../models/IUserPrivleges";
import { UserPrivilegeAccount, UserPrivilegeApiCredentials, UserPrivilegeBAProductServices, UserPrivilegeFinance, UserPrivilegeMSP, UserPrivilegeProductServices, UserPrivilegeRolesAccess, UserSubpartnerPrivilegeAccount, UserSubpartnerPrivilegeProductServices } from "../../../../../models/UserPrivileges";

export function computeTechAndTechNoDelPrivileges(initialAccountPrivileges: IPrivilege<UserPrivilegeAccount>[], initialRolesAccessPrivileges: IPrivilege<UserPrivilegeRolesAccess>[], initialFinancePrivileges: IPrivilege<UserPrivilegeFinance>[], initialProductServicesPrivileges: IPrivilege<UserPrivilegeProductServices>[], isSubpartner: boolean): any {
  const accountPrivileges = initialAccountPrivileges.map(p => ({ ...p, enabled: p.privilege === UserPrivilegeAccount.View }));
  const rolesAccessPrivileges = initialRolesAccessPrivileges.map(p => ({ ...p, enabled: false }));
  const financePrivileges = initialFinancePrivileges.map(p => ({ ...p, enabled: false }));
  const productServicesPrivileges = initialProductServicesPrivileges.map(p => {
    let enabledPrivileges = isSubpartner ? [UserPrivilegeProductServices.View, UserPrivilegeProductServices.AccessUserBillingList, UserPrivilegeProductServices.ManageIntegrations] : [UserPrivilegeProductServices.View, UserPrivilegeProductServices.AccessSubpartnerCatalogs, UserPrivilegeProductServices.AccessUserBillingList, UserPrivilegeProductServices.ManageIntegrations];

    return {
      ...p,
      enabled: enabledPrivileges.includes(p.privilege),
    };
  });

  return {
    accountPrivileges,
    rolesAccessPrivileges,
    financePrivileges,
    productServicesPrivileges,
  };
}

export function computeFinancePrivileges(initialAccountPrivileges: IPrivilege<UserPrivilegeAccount>[], initialRolesAccessPrivileges: IPrivilege<UserPrivilegeRolesAccess>[], initialFinancePrivileges: IPrivilege<UserPrivilegeFinance>[], initialProductServicesPrivileges: IPrivilege<UserPrivilegeProductServices>[], isSubpartner: boolean): any {
  const accountPrivileges = initialAccountPrivileges.map(p => ({ ...p, enabled: p.privilege === UserPrivilegeAccount.View }));
  const financePrivileges = initialFinancePrivileges.map(p => ({ ...p, enabled: !isSubpartner }));
  const rolesAccessPrivileges = initialRolesAccessPrivileges.map(p => ({ ...p, enabled: false }));
  const productServicesPrivileges = initialProductServicesPrivileges.map(p => ({ ...p, enabled: [UserPrivilegeProductServices.View].includes(p.privilege) }));

  return {
    accountPrivileges,
    rolesAccessPrivileges,
    financePrivileges,
    productServicesPrivileges,
  };
}

export function computeAdminPrivileges(initialAccountPrivileges: IPrivilege<UserPrivilegeAccount>[], initialRolesAccessPrivileges: IPrivilege<UserPrivilegeRolesAccess>[], initialFinancePrivileges: IPrivilege<UserPrivilegeFinance>[], initialProductServicesPrivileges: IPrivilege<UserPrivilegeProductServices>[], initialApiCredentialsPrivileges: IPrivilege<UserPrivilegeApiCredentials>[], isSubpartner: boolean): any {
  const accountPrivileges = initialAccountPrivileges.map(p => {
    let isEnabled = true;
    if (isSubpartner) {
      isEnabled = [UserPrivilegeAccount.View, UserPrivilegeAccount.EditPartnerContactInfo, UserPrivilegeAccount.CreateAccounts, UserPrivilegeAccount.EditAccounts, UserPrivilegeAccount.DeleteAccounts].includes(p.privilege);
    }
    return { ...p, enabled: isEnabled };
  });
  const rolesAccessPrivileges = initialRolesAccessPrivileges.map(p => ({ ...p, enabled: true }));
  const financePrivileges = initialFinancePrivileges.map(p => ({ ...p, enabled: !isSubpartner }));
  const productServicesPrivileges = initialProductServicesPrivileges.map(p => {
    let isEnabled = true;
    if (isSubpartner) {
      isEnabled = [UserPrivilegeProductServices.View, UserPrivilegeProductServices.ActivateProductsToAccount, UserPrivilegeProductServices.DeactivateProducts, UserPrivilegeProductServices.AccessUserBillingList, UserPrivilegeProductServices.ExportUsageData, UserPrivilegeProductServices.ManageIntegrations].includes(p.privilege);
    }
    return { ...p, enabled: isEnabled };
  });
  const apiCrendetialsPrivileges = initialApiCredentialsPrivileges.map(p => ({ ...p, enabled: !isSubpartner }));

  return {
    accountPrivileges,
    rolesAccessPrivileges,
    financePrivileges,
    productServicesPrivileges,
    apiCrendetialsPrivileges,
  };
}

export function computeSubpartnerAdminPrivileges(initialAccountPrivileges: IPrivilege<UserSubpartnerPrivilegeAccount>[], initialRolesAccessPrivileges: IPrivilege<UserPrivilegeRolesAccess>[], initialProductServicesPrivileges: IPrivilege<UserSubpartnerPrivilegeProductServices>[]): any {
  const accountPrivileges = initialAccountPrivileges.map(p => ({ ...p, enabled: true }));
  const rolesAccessPrivileges = initialRolesAccessPrivileges.map(p => ({ ...p, enabled: true }));
  const productServicesPrivileges = initialProductServicesPrivileges.map(p => ({ ...p, enabled: true }));

  return {
    accountPrivileges,
    rolesAccessPrivileges,
    productServicesPrivileges,
  };
}

export function computeSubpartnerFinancePrivileges(initialAccountPrivileges: IPrivilege<UserSubpartnerPrivilegeAccount>[], initialRolesAccessPrivileges: IPrivilege<UserPrivilegeRolesAccess>[], initialProductServicesPrivileges: IPrivilege<UserSubpartnerPrivilegeProductServices>[]): any {
  const accountPrivileges = initialAccountPrivileges.map(p => ({ ...p, enabled: p.privilege === UserSubpartnerPrivilegeAccount.View }));
  const rolesAccessPrivileges = initialRolesAccessPrivileges.map(p => ({ ...p, enabled: false }));
  const productServicesPrivileges = initialProductServicesPrivileges.map(p => ({ ...p, enabled: [UserSubpartnerPrivilegeProductServices.View].includes(p.privilege) }));

  return {
    accountPrivileges,
    rolesAccessPrivileges,
    productServicesPrivileges,
  };
}

export function computeSubpartnerTechAndTechNoDelPrivileges(initialAccountPrivileges: IPrivilege<UserSubpartnerPrivilegeAccount>[], initialRolesAccessPrivileges: IPrivilege<UserPrivilegeRolesAccess>[], initialProductServicesPrivileges: IPrivilege<UserSubpartnerPrivilegeProductServices>[]): any {
  const accountPrivileges = initialAccountPrivileges.map(p => ({ ...p, enabled: p.privilege === UserSubpartnerPrivilegeAccount.View }));
  const rolesAccessPrivileges = initialRolesAccessPrivileges.map(p => ({ ...p, enabled: false }));
  const productServicesPrivileges = initialProductServicesPrivileges.map(p => ({ ...p, enabled: [UserSubpartnerPrivilegeProductServices.View, UserSubpartnerPrivilegeProductServices.AccessUserBillingList, UserSubpartnerPrivilegeProductServices.ManageIntegrations].includes(p.privilege) }));

  return {
    accountPrivileges,
    rolesAccessPrivileges,
    productServicesPrivileges,
  };
}

export function computeNoRoleSelected(initialAccountPrivileges: IPrivilege<UserPrivilegeAccount>[], initialRolesAccessPrivileges: IPrivilege<UserPrivilegeRolesAccess>[], initialFinancePrivileges: IPrivilege<UserPrivilegeFinance>[], initialProductServicesPrivileges: IPrivilege<UserPrivilegeProductServices>[], initialMSPPrivileges: IPrivilege<UserPrivilegeMSP>[], initialBAProductServicesPrivileges: IPrivilege<UserPrivilegeBAProductServices>[]): any {
  const accountPrivileges = initialAccountPrivileges.map(p => ({ ...p, enabled: false }));
  const rolesAccessPrivileges = initialRolesAccessPrivileges.map(p => ({ ...p, enabled: false }));
  const financePrivileges = initialFinancePrivileges.map(p => ({ ...p, enabled: false }));
  const productServicesPrivileges = initialProductServicesPrivileges.map(p => ({ ...p, enabled: false }));
  const mspPrivileges = initialMSPPrivileges.map(p => ({ ...p, enabled: false }));
  const productBAServicesPrivileges = initialBAProductServicesPrivileges.map(p => ({ ...p, enabled: false }));

  return {
    accountPrivileges,
    rolesAccessPrivileges,
    financePrivileges,
    productServicesPrivileges,
    mspPrivileges,
    productBAServicesPrivileges,
  };
}

export function computeBAAdminPrivileges(initialMSPPrivileges: IPrivilege<UserPrivilegeMSP>[], initialRolesAccessPrivileges: IPrivilege<UserPrivilegeRolesAccess>[], initialFinancePrivileges: IPrivilege<UserPrivilegeFinance>[], initialBAProductServicesPrivileges: IPrivilege<UserPrivilegeBAProductServices>[]): any {
  const mspPrivileges = initialMSPPrivileges.map(p => ({ ...p, enabled: [UserPrivilegeMSP.View, UserPrivilegeMSP.EditBABillingInfo].includes(p.privilege) }));
  const rolesAccessPrivileges = initialRolesAccessPrivileges.map(p => ({ ...p, enabled: true }));
  const financePrivileges = initialFinancePrivileges.map(p => ({ ...p, enabled: true }));
  const productBAServicesPrivileges = initialBAProductServicesPrivileges.map(p => ({ ...p, enabled: true }));

  return {
    mspPrivileges,
    rolesAccessPrivileges,
    financePrivileges,
    productBAServicesPrivileges,
  };
}

export function computeBAFinancePrivileges(initialMSPPrivileges: IPrivilege<UserPrivilegeMSP>[], initialRolesAccessPrivileges: IPrivilege<UserPrivilegeRolesAccess>[], initialFinancePrivileges: IPrivilege<UserPrivilegeFinance>[], initialBAProductServicesPrivileges: IPrivilege<UserPrivilegeBAProductServices>[]): any {
  const mspPrivileges = initialMSPPrivileges.map(p => ({ ...p, enabled: p.privilege === UserPrivilegeMSP.View }));
  const rolesAccessPrivileges = initialRolesAccessPrivileges.map(p => ({ ...p, enabled: false }));
  const financePrivileges = initialFinancePrivileges.map(p => ({ ...p, enabled: true }));
  const productBAServicesPrivileges = initialBAProductServicesPrivileges.map(p => ({ ...p, enabled: true }));

  return {
    mspPrivileges,
    rolesAccessPrivileges,
    financePrivileges,
    productBAServicesPrivileges,
  };
}

export function computeBATechPrivileges(initialMSPPrivileges: IPrivilege<UserPrivilegeMSP>[], initialRolesAccessPrivileges: IPrivilege<UserPrivilegeRolesAccess>[], initialFinancePrivileges: IPrivilege<UserPrivilegeFinance>[], initialBAProductServicesPrivileges: IPrivilege<UserPrivilegeBAProductServices>[]): any {
  const mspPrivileges = initialMSPPrivileges.map(p => ({ ...p, enabled: p.privilege === UserPrivilegeMSP.View }));
  const rolesAccessPrivileges = initialRolesAccessPrivileges.map(p => ({ ...p, enabled: false }));
  const financePrivileges = initialFinancePrivileges.map(p => ({ ...p, enabled: false }));
  const productBAServicesPrivileges = initialBAProductServicesPrivileges.map(p => ({ ...p, enabled: [UserPrivilegeBAProductServices.View, UserPrivilegeBAProductServices.FilterProductsOfMSPByAccount, UserPrivilegeBAProductServices.FilterProductsOfMSPBySubpartner].includes(p.privilege) }));

  return {
    mspPrivileges,
    rolesAccessPrivileges,
    financePrivileges,
    productBAServicesPrivileges,
  };
}

export function computeReadOnlyPrivileges(initialAccountPrivileges: IPrivilege<UserPrivilegeAccount>[], initialRolesAccessPrivileges: IPrivilege<UserPrivilegeRolesAccess>[], initialFinancePrivileges: IPrivilege<UserPrivilegeFinance>[], initialProductServicesPrivileges: IPrivilege<UserPrivilegeProductServices>[]): any {
  const accountPrivileges = initialAccountPrivileges.map(p => ({ ...p, enabled: false }));
  const rolesAccessPrivileges = initialRolesAccessPrivileges.map(p => ({ ...p, enabled: false }));
  const financePrivileges = initialFinancePrivileges.map(p => ({ ...p, enabled: false }));
  const productServicesPrivileges = initialProductServicesPrivileges.map(p => ({ ...p, enabled: p.privilege === UserPrivilegeProductServices.View }));

  return {
    accountPrivileges,
    rolesAccessPrivileges,
    financePrivileges,
    productServicesPrivileges,
  };
}

export function computeSubpartnerReadOnlyPrivileges(initialAccountPrivileges: IPrivilege<UserSubpartnerPrivilegeAccount>[], initialRolesAccessPrivileges: IPrivilege<UserPrivilegeRolesAccess>[], initialProductServicesPrivileges: IPrivilege<UserSubpartnerPrivilegeProductServices>[]): any {
  const accountPrivileges = initialAccountPrivileges.map(p => ({ ...p, enabled: false }));
  const rolesAccessPrivileges = initialRolesAccessPrivileges.map(p => ({ ...p, enabled: false }));
  const productServicesPrivileges = initialProductServicesPrivileges.map(p => ({ ...p, enabled: p.privilege === UserSubpartnerPrivilegeProductServices.View }));

  return {
    accountPrivileges,
    rolesAccessPrivileges,
    productServicesPrivileges,
  };
}

export const getAccountPrivileges = (isBaLoggedIn: boolean, isSubpartnerLoggedIn: boolean, selectedMSPPrivileges: IPrivilege<UserPrivilegeMSP>[], selectedAccountPrivilegesForSubpartnerUser: IPrivilege<UserSubpartnerPrivilegeAccount>[], selectedAccountPrivileges: IPrivilege<UserPrivilegeAccount>[]): IPrivilege<any>[] => {
  if (isBaLoggedIn) {
    return selectedMSPPrivileges;
  } else if (isSubpartnerLoggedIn) {
    return selectedAccountPrivilegesForSubpartnerUser;
  } else {
    return selectedAccountPrivileges;
  }
};

export const getProductServicesPrivileges = (isBaLoggedIn: boolean, isSubpartnerLoggedIn: boolean, selectedBAProductServicesPrivileges: IPrivilege<UserPrivilegeBAProductServices>[], selectedProductServicesPrivilegesForSubpartnerUser: IPrivilege<UserSubpartnerPrivilegeProductServices>[], selectedProductServicesPrivileges: IPrivilege<UserPrivilegeProductServices>[]): IPrivilege<any>[] => {
  if (isBaLoggedIn) {
    return selectedBAProductServicesPrivileges;
  } else if (isSubpartnerLoggedIn) {
    return selectedProductServicesPrivilegesForSubpartnerUser;
  } else {
    return selectedProductServicesPrivileges;
  }
};

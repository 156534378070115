import IAccount from "../../../models/IAccount";
import IAccountSlim from "../../../models/IAccountSlim";
import MspType from "../../../models/MspType";

export function shouldGetParent(selectedAcc: IAccount | IAccountSlim, loggedInAcc: IAccount): boolean {
  if (loggedInAcc.type === MspType.BillingAggregator) {
    return false;
  }
  if ((loggedInAcc.type === MspType.Partner && selectedAcc.type === MspType.Partner) || (loggedInAcc.type === MspType.Subpartner && selectedAcc.type === MspType.Subpartner) || (loggedInAcc.type === MspType.Partner && selectedAcc.type === MspType.Subpartner) || selectedAcc.partnerId !== undefined) {
    return false;
  }
  return true;
}

import { Typography } from "@cuda-networks/bds-core";
import React from "react";

const IntegrationsTicketsTab: React.FC = () => {
  return (
    <div style={{ height: 550 }}>
      <Typography data-testid="ticketingFeatureTxt" variant="body1" style={{ position: "relative", top: "235px", color: "darkgray" }}>
        The Ticketing feature is available only on the ECHOplatform and currently features only Intronis Backup ticketing. Please visit the ECHOplatform to access this feature.
      </Typography>
    </div>
  );
};
export default IntegrationsTicketsTab;

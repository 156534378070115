import { CardContent, Tooltip, Typography, Grid, Card } from "@cuda-networks/bds-core";
import React from "react";
import { useDispatch } from "react-redux";
import { cancelCurrentAction, setViewIntegrationsAction } from "../../actions/generalActions";
import { setCancelLoadIntegrationExtraInfoTokenAction } from "../../actions/integrations/integrationsActions";
import IntegrationsTable from "./IntegrationsTable";
import BackTo from "../BackTo";

const IntegrationsPanel = () => {
  const dispatch = useDispatch();
  const integrationsTitle = "Manage Integrations";

  const handleBackToProducts = (event: React.SyntheticEvent) => {
    event.preventDefault();
    dispatch(setCancelLoadIntegrationExtraInfoTokenAction());
    dispatch(setViewIntegrationsAction(false));
    dispatch(cancelCurrentAction());
  };

  return (
    <Card className={"IntegrationPanel"}>
      <CardContent>
        <Grid item container spacing={1} direction="column" style={{ paddingBottom: "10px" }}>
          <Grid item container>
            <Grid container item xs={9}>
              <Grid container style={{ paddingBottom: "10px", paddingRight: "40px" }}>
                <Tooltip title={""}>
                  <Typography data-testid="integrationsPanelTitle" variant="h4" noWrap>
                    {integrationsTitle}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid container>
                <BackTo text={"Products & Services"} callback={handleBackToProducts} testId="integrationsPanelLnk" />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} data-testid="integrationsTable">
            <IntegrationsTable />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default IntegrationsPanel;

import React, { useEffect, useState } from "react";
import { IconButton } from "@material-ui/core";
import TooltipElement from "../../Users/TooltipElement";
import { setNoPoinerEvents } from "../../../utility";
import { Refresh } from "@cuda-networks/bds-core/dist/Icons/Core";
import M365LinkIcon from "../../Accounts/M356LinkIcon";
import IAccountSlim from "../../../models/IAccountSlim";
import { CircularProgress } from "@cuda-networks/bds-core";
import IAccount from "../../../models/IAccount";
import { fetchM365AuthStatusAction, loadParentAccountWithChildrenAction } from "../../../actions/accountActions";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/store";

interface ICheckM365Status {
  accountId: number | string;
}

const CheckM365Status: React.FC<ICheckM365Status> = ({ accountId }) => {
  const dispatch = useDispatch();
  const accountsNames = useSelector((state: IAppState) => state.accountState.accountsNames);
  const loadingM356StatusForAccountId = useSelector((state: IAppState) => state.accountState.loadingM356StatusForAccountId);
  const loadingAccountId = useSelector((state: IAppState) => state.accountState.loadingAccountId);
  const [showStatus, setShowStatus] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [account, setAccount] = useState<IAccountSlim>();

  useEffect(() => {
    const accountIndex = accountsNames.findIndex((x: IAccountSlim) => x.id.toString() === accountId.toString());
    if (accountIndex > -1) {
      setAccount(accountsNames[accountIndex]);
      setShowStatus(accountsNames[accountIndex].m365AuthLinked !== undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsNames]);

  useEffect(() => {
    const isCurrentAccountLoading = (loadingM356StatusForAccountId !== undefined && loadingM356StatusForAccountId.toString() === accountId.toString()) || (loadingAccountId !== undefined && loadingAccountId.toString() === accountId.toString());
    const isAnotherAccountLoading = (loadingM356StatusForAccountId !== undefined && loadingM356StatusForAccountId.toString() !== accountId.toString()) || (loadingAccountId !== undefined && loadingAccountId.toString() !== accountId.toString());
    setShowLoading(isCurrentAccountLoading);
    setDisabled(isAnotherAccountLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingM356StatusForAccountId || loadingAccountId]);

  const getParent = (accountId: number) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(loadParentAccountWithChildrenAction(accountId, false));
      resolve(success);
    });

  const checkM365Status = (account: IAccount | IAccountSlim | undefined): void => {
    if (account) {
      if (account.partnerId) {
        dispatch(fetchM365AuthStatusAction(account));
      } else {
        getParent(account.id).then(response => {
          if (response) {
            const partnerId = response.partnerId;
            if (partnerId) {
              dispatch(fetchM365AuthStatusAction({ ...account, partnerId: partnerId }));
            }
          }
        });
      }
    }
  };

  const checkM365Comp = showStatus ? (
    <M365LinkIcon account={account} />
  ) : (
    <TooltipElement title={"Check M365 Connector Status"}>
      <IconButton aria-label="refresh" data-testid={"checkM365StatusBtn"} disabled={disabled} className={setNoPoinerEvents(disabled) + " checkM365StatusBtn"} onClick={() => checkM365Status(account)}>
        <Refresh />
      </IconButton>
    </TooltipElement>
  );
  return <div className={disabled ? "cursorNotAllowed CheckM365StatusComponent" : "CheckM365StatusComponent"}>{showLoading ? <CircularProgress data-testid={"loadingM365ConnectorStatus"} size="24px" /> : checkM365Comp}</div>;
};

export default CheckM365Status;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@cuda-networks/bds-core";
import { IAppState } from "../store/store";
import { getApiUrlAction, generateCustomAccessTokenAction, hasUsersAction, loadAction, loadLoggedInMsp, preFetch, setError, setLoggedUser } from "../actions/generalActions";
import MspUILoadingAppProgress from "./MspUILoadingAppProgress";
import ErrorMessage from "./ErrorMessage";
import LandingPage from "./LandingPage";
import env from "@beam-australia/react-env";
import MspApp from "./MspApp";
import { Navigate } from "react-router-dom";
import ErrorAfterLogin from "./ErrorAfterLogin";
import { TokenStorage } from "../TokenStorage";
import { isStringNullOrEmpty } from "../utility";
import CustomerMessagesType from "../models/CustomerMessagesType";
import ServiceUnavailable from "./ServiceUnavailable";

const StartApp = (): React.JSX.Element => {
  const dispatch = useDispatch();
  const isMspLoggedIn = useSelector((state: IAppState) => state.generalState.isMspLoggedIn);
  const loading = useSelector((state: IAppState) => state.generalState.loading);
  const errorMessage = useSelector((state: IAppState) => state.generalState.errorMessage);
  const apiError = useSelector((state: IAppState) => state.generalState.apiError);
  const noOfLoadedSubpartners = useSelector((state: IAppState) => state.generalState.noOfLoadedSubpartners);
  const noOfSubpartners = useSelector((state: IAppState) => state.generalState.noOfSubpartners);
  const isDefaultUser = useSelector((state: IAppState) => state.generalState.isDefaultUser);
  const hasUsers = useSelector((state: IAppState) => state.generalState.hasUsers);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const showCustomerMessage = useSelector((state: IAppState) => state.generalState.showCustomerMessage);
  const customerMessageType = useSelector((state: IAppState) => state.generalState.customerMessageType);

  useEffect(() => {
    if (mspAccountLoggedIn.id > 0 && isDefaultUser) {
      dispatch(hasUsersAction(mspAccountLoggedIn));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mspAccountLoggedIn, isDefaultUser]);

  const getEchoV3AccessToken = () =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(generateCustomAccessTokenAction());
      resolve(result);
    });

  const getUrl = () =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getApiUrlAction(env("URLS")));
      resolve(result);
    });

  const getLoggedUser = () =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(setLoggedUser());
      resolve(result);
    });

  useEffect(() => {
    dispatch(loadAction(true));
    const accessToken = TokenStorage.getBccAccessToken();
    const idToken = TokenStorage.getBccIdToken();
    if (accessToken && idToken) {
      getUrl().then(success => {
        if (success) {
          const echoV3AccessToken = TokenStorage.getEchoV3AccessToken();
          if (echoV3AccessToken && echoV3AccessToken !== null && !isStringNullOrEmpty(echoV3AccessToken)) {
            getLoggedUser().then(isDefaultUser => {
              preFetch();
              dispatch(loadLoggedInMsp(isDefaultUser));
            });
          } else {
            getEchoV3AccessToken().then(response => {
              if (response) {
                getLoggedUser().then(isDefaultUser => {
                  preFetch();
                  dispatch(loadLoggedInMsp(isDefaultUser));
                });
              } else {
                dispatch(setError("No access token, you need to login agan"));
              }
            });
          }
        }
      });
    } else if (window.location.hostname === "localhost") {
      //when working on localhost make things easier for dev and do not requier bcc_access_token and bcc_id_token, but directly with a v3 access_token provided in session storage
      const echoV3AccessToken = TokenStorage.getEchoV3AccessToken();
      if (echoV3AccessToken) {
        getUrl().then(success => {
          if (success) {
            getLoggedUser().then(isDefaultUser => {
              preFetch();
              dispatch(loadLoggedInMsp(isDefaultUser));
            });
          }
        });
      }
    } else {
      dispatch(setError("No bcc access token, you need to login again"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appIsLoading = () => {
    let isLoading = true;
    if (isMspLoggedIn && isDefaultUser !== undefined) {
      isLoading = false;
      if (isDefaultUser) {
        isLoading = true;
        if (hasUsers !== undefined) {
          isLoading = false;
        }
      }
    }
    return isLoading;
  };

  const renderLoginByToken = () => {
    if (!appIsLoading()) {
      if (apiError !== undefined) {
        return null;
      }
      if (isDefaultUser) {
        return <Navigate to="/newUser" />;
      } else {
        return <MspApp />;
      }
    } else {
      if (showCustomerMessage) {
        switch (customerMessageType) {
          case CustomerMessagesType.Marketing:
            return <LandingPage />;
          case CustomerMessagesType.TemporaryUnreachable:
            return <ServiceUnavailable />;
        }
      } else if (errorMessage) {
        return null;
      } else {
        if (loading) {
          return <MspUILoadingAppProgress noOfLoadedSubpartners={noOfLoadedSubpartners} noOfSubpartners={noOfSubpartners} />;
        } else {
          return <Typography>Waiting for server...</Typography>;
        }
      }
    }
  };

  const onCloseMessage = () => {
    dispatch(setError(""));
  };

  return (
    <div>
      {apiError !== undefined && <ErrorAfterLogin apiError={apiError} />}
      {errorMessage && errorMessage.length > 0 && <ErrorMessage message={errorMessage} onCloseMessage={onCloseMessage} />}
      <div className="App" style={{ paddingTop: 50, paddingBottom: 50, paddingRight: 50, paddingLeft: 50 }}>
        {renderLoginByToken()}
      </div>
    </div>
  );
};

export default StartApp;

import produce from "immer";
import IUser from "../models/IUser";
import IAccount from "../models/IAccount";
import IAccountSlim from "../models/IAccountSlim";
import MspType from "../models/MspType";
import { findAccountById } from "../Utilities/accountsHelper";

export function deleteUserFromState(usersToDisplay: IUser[], user: IUser) {
  const userToDeleteIndex = usersToDisplay.findIndex(value => value.id === user.id);
  if (userToDeleteIndex < 0) {
    throw new Error(`No user with id ${user.id}`);
  }
  const nextStateUsers = produce(usersToDisplay, draft => {
    draft.splice(userToDeleteIndex, 1);
  });
  return nextStateUsers;
}

export const getUserParentAccount = (selectedAcc: IAccount | undefined, accounts: IAccount[], slimAccounts: IAccountSlim[] | undefined, user: IUser): IAccount | IAccountSlim | undefined => {
  if (selectedAcc?.type === MspType.BillingAggregator) {
    return selectedAcc;
  } else {
    return findAccountById(accounts, slimAccounts, user.explicitAccountId);
  }
};

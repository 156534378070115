import { State } from "@progress/kendo-data-query";
import { GridColumnMenuFilter } from "@progress/kendo-react-grid";
import { GridFilterOperators } from "@progress/kendo-react-grid/dist/npm/interfaces/GridFilterOperators";
import React from "react";
import { isObjectEmpty } from "../utility";
import { DateFilter } from "./DateFilter";

export const filterTextInput = (props: any, filterOperators?: GridFilterOperators) => {
  if (props.column === "text" && props.filter !== null) {
    if (props.filter.filters.length !== 0 && props.filter.filters[0].filters[0].value) {
      props.filter.filters[0].filters[0].value = props.filter.filters[0].filters[0].value.trim();
    }
  }

  return (
    <div data-testid="filterTextInput">
      <GridColumnMenuFilter {...props} expanded={true} hideSecondFilter={true} filterOperators={!isObjectEmpty(filterOperators) ? filterOperators : props.filterOperators} />
    </div>
  );
};

export const isColumnActive = (field: string, dstate: State): boolean => {
  return GridColumnMenuFilter.active(field, dstate.filter);
};

export const getColorForColumn = (columnName: string, dstate: State): string => {
  return isColumnActive(columnName, dstate) ? "active" : "";
};

export const filterDateInput = (props: any) => {
  return (
    <div data-testid="filterTextInput">
      <GridColumnMenuFilter {...props} expanded={true} hideSecondFilter={true} filterUI={DateFilter} />
    </div>
  );
};

export const filterContainInput = (props: any) => {
  const onlyContainOperator: GridFilterOperators = {
    text: [
      {
        text: "grid.filterContainsOperator",
        operator: "contains",
      },
    ],
  };
  return filterTextInput(props, onlyContainOperator);
};

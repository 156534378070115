import React, { useEffect, useState } from "react";
import "../../../table.css";
import { DataTable, DataTableColumn as Column, LinearProgress } from "@cuda-networks/bds-core";
import { process, State } from "@progress/kendo-data-query";
import { useDispatch, useSelector } from "react-redux";
import Pager from "@cuda-networks/bds-core/dist/DataTable/Pager";
import { useMediaQuery } from "@material-ui/core";
import { IAppState } from "../../../store/store";
import { getButtonCount } from "../../../utility";
import { setAccountsWithExclusionsTableProps } from "../../../actions/financeActions";
import { GridNoRecords } from "@progress/kendo-react-grid";
import IAccountsWithExclusions from "../../../models/Invoices/IAccountsWithExclusions";
import AccountWithExclusionsIcon from "./AccountWithExclusionsIcon";
import ExpandAccountWithExclusions from "./ExpandAccountWithExclusions";
import { filterTextInput, getColorForColumn } from "../../TableHelpers";

interface IExclusionsTableProps {
  onAccountSelected: (account: IAccountsWithExclusions) => void;
}

const ExclusionsTable: React.FC<IExclusionsTableProps> = ({ onAccountSelected }) => {
  const dispatch = useDispatch();
  const loadingAccountsWithExclusions = useSelector((state: IAppState) => state.financeState.loadingAccountsWithExclusions);
  const tableState = useSelector((state: IAppState) => state.financeState.accountsWithExclusionsTableState);
  const accountsWithExclusions = useSelector((state: IAppState) => state.financeState.accountsWithExclusions);
  const responsiveViewPortTriggerMin = useMediaQuery("(min-width: 1600px)");
  const [buttonCount, setButtonCount] = useState(10);
  const [totalExclusions, setTotalExclusions] = useState(0);

  const dataState = {
    skip: tableState.skip,
    take: tableState.take,
    sort: tableState.sort,
    group: [],
    filter: tableState.filter,
    collapsedGroups: [],
    selectedItem: "any",
    lastSelectedIndex: 0,
    columns: [
      {
        title: "ACCOUNT",
        field: "accountName",
        show: true,
        filter: "text",
        filtrable: true,
      },
      {
        title: "EXCLUSIONS",
        field: "total",
        show: false,
        filter: "text",
        filtrable: false,
      },
    ],
  };

  const [gridState, setGridState] = useState({
    dataState,
    dataResult: process(accountsWithExclusions, dataState as any),
  });

  const dataStateChange = (e: any): void => {
    setGridState({
      dataState: { ...dataState, ...e.dataState },
      dataResult: process(accountsWithExclusions, e.dataState),
    });
  };

  useEffect(() => {
    let x = 0;
    accountsWithExclusions.forEach(element => {
      x += element.total;
    });
    setTotalExclusions(x);
    dispatch(setAccountsWithExclusionsTableProps({ sort: gridState.dataState.sort, take: gridState.dataState.take, skip: gridState.dataState.skip, filter: gridState.dataState.filter }));
    setGridState({ dataState: gridState.dataState, dataResult: process(accountsWithExclusions, gridState.dataState as any) });
    // eslint-disable-next-line
  }, [accountsWithExclusions, gridState.dataState]);

  useEffect(() => {
    setButtonCount(getButtonCount(gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin));
  }, [gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin]);

  const onClick = (account: IAccountsWithExclusions): void => {
    onAccountSelected(account);
  };

  const AccountIconCellCommand = (props: any) => <AccountWithExclusionsIcon {...props} onClick={onClick} />;
  const ExpandAccountWithExclusionsCommand = (props: any) => <ExpandAccountWithExclusions {...props} onClick={onClick} />;

  return (
    <div>
      <DataTable
        className={" noScrollbar noBorders"}
        data={gridState.dataResult}
        resizable
        filter={gridState.dataState.filter}
        // page
        pageConfig={{
          pageable: {
            pageSizes: [10, 25, 50],
            buttonCount: buttonCount,
          },
          skip: 0,
          take: 10,
          total: gridState.dataResult.total,
        }}
        sortConfig={{
          sortable: true,
          sort: tableState.sort,
        }}
        pager={gridState.dataResult.data.length > 0 && Pager}
        onDataStateChange={dataStateChange}
        selectedField="selected"
        onRowClick={(e: any) => onClick(e.dataItem)}
        {...(gridState.dataState as any)}
      >
        <GridNoRecords>
          {loadingAccountsWithExclusions ? (
            <div data-testid="loadingAccountsWithExclusions">
              <LinearProgress />
            </div>
          ) : (
            <div data-testid="noRecordsAccountsWithExclusions">No records available</div>
          )}
        </GridNoRecords>
        <Column key={"exclusionAccountIcon"} title={" "} cell={AccountIconCellCommand} width={60} sortable={false} resizable={false} onRowClick={onClick} />
        <Column key={"exclusionAccount"} field={"accountName"} title={"ACCOUNT"} minResizableWidth={30} resizable={true} columnMenu={filterTextInput} headerClassName={getColorForColumn("accountName", gridState.dataState as State)} />
        <Column key={"expandExclusionAccount"} field={"total"} title={totalExclusions > 0 ? `EXCLUSIONS (${totalExclusions} TOTAL)` : "EXCLUSIONS"} cell={ExpandAccountWithExclusionsCommand} minResizableWidth={30} resizable={false} onRowClick={onClick} />
      </DataTable>
    </div>
  );
};

export default ExclusionsTable;

enum ProductNames {
  //Essentials
  AdvancedEmailSecurity = "Advanced Email Security",
  EmailSecurityCompliance = "Email Security & Compliance",
  EmailCompleteProtection = "Email Complete Protection",
  BasicEmailSecurity = "Basic Email Security",
  EmailCloudToCloudBackup = "Email Cloud-to-Cloud Backup",
  EmailArchivingService = "Email Archiving Service",
  SentinelService = "Sentinel Service",
  ForensicsService = "Forensics Service",
  TotalEmailProtection = "Total Email Protection",
  //Content Shield
  BarracudaContentShield = "Barracuda Content Shield",
  BarracudaContentShieldPlus = "Barracuda Content Shield Plus",
}
export default ProductNames;

export enum ProductFamily {
  ESSENTIALS_SERIVICES = "Essentials Services",
  CONTENT_SHIELD = "Content Shield",
  BACKUP_APPLIANCES = "Barracuda Backup",
  INTRONIS_BACKUP = "Intronis Backup",
}

export interface IProductFamillyNames {
  productFamily: string;
  displayName: string;
}

export default ProductFamily;

export function getProductTypeName(familyName: string): ProductFamily {
  if (familyName.includes(ProductFamily.ESSENTIALS_SERIVICES)) {
    return ProductFamily.ESSENTIALS_SERIVICES;
  } else if (familyName.includes(ProductFamily.CONTENT_SHIELD)) {
    return ProductFamily.CONTENT_SHIELD;
  } else if (familyName.includes(ProductFamily.BACKUP_APPLIANCES)) {
    return ProductFamily.BACKUP_APPLIANCES;
  } else if (familyName.includes(ProductFamily.INTRONIS_BACKUP)) {
    return ProductFamily.INTRONIS_BACKUP;
  } else {
    throw new Error("Invalid argument");
  }
}

export function getProductTypeNameToDisplay(familyName: string): string {
  if (familyName.includes(ProductFamily.ESSENTIALS_SERIVICES)) {
    return "Barracuda Email Protection";
  } else if (familyName.includes(ProductFamily.CONTENT_SHIELD)) {
    return "Barracuda Content Shield";
  } else if (familyName.includes(ProductFamily.BACKUP_APPLIANCES)) {
    return "Barracuda Backup Appliances";
  } else if (familyName.includes(ProductFamily.INTRONIS_BACKUP)) {
    return "Intronis Backup";
  } else {
    throw new Error("Invalid argument");
  }
}

import { Dispatch } from "redux";
import { ApiError } from "../errors/ApiError";
import { CommunicationError } from "../errors/CommunicationError";
import { OperationCanceledError } from "../errors/OperationCanceledError";
import ActionMessageType from "../models/ActionMessageType";
import { GeneralActionTypes } from "./generalActions";
import { isResellerLoginError, isUserDuplicateEmailError } from "../businessLogic/components/Users/AddEditUser/AddEditLoginUserDialog";
import { UserActionTypes } from "./userActions";
import { ActionMessages, ActionTypes } from "./ActionTypes";

export const GENERAL_ERROR_MESSGE = "Something went wrong. Please refresh the page and try again.";
export const COMMUNICATION_ERROR_MESSAGE = "Could not communicate with server. Please refresh the page and try again.";
export const RESSELLER_ERROR_CODE = "error_reseller";
export const USER_DUPLICATE_EMAIL = "This email address is already linked to another user.";

export function handleError(
  error: any,
  dispatch: Dispatch,
  callback: () => void,
  canceledOperationCallback: () => void = () => {
    /* no action needed*/
  },
  displayInSnackBar: boolean = false,
  actionType: ActionTypes | undefined = undefined,
  isAsyncAction: boolean = false,
  customMessage: string | undefined = undefined,
  snackBarViewDetailsAction: any = undefined,
  snackBarCloseAction: any = undefined,
) {
  if (error instanceof CommunicationError) {
    displayMessage(COMMUNICATION_ERROR_MESSAGE, actionType, dispatch, displayInSnackBar, isAsyncAction, customMessage, snackBarViewDetailsAction, snackBarCloseAction);
  } else if (error instanceof ApiError) {
    handleApiError(error, actionType, dispatch, displayInSnackBar, isAsyncAction, customMessage, snackBarViewDetailsAction, snackBarCloseAction);
  }
  if (!(error instanceof OperationCanceledError)) {
    callback();
  } else {
    canceledOperationCallback();
  }
}

function handleApiError(error: ApiError, actionType: ActionTypes | undefined, dispatch: Dispatch, displayInSnackBar: boolean, isAsyncAction: boolean | undefined, customMessage: string | undefined, snackBarViewDetailsAction: any | undefined, snackBarCloseAction: any | undefined) {
  if (isResellerLoginError(error)) {
    dispatch({ type: GeneralActionTypes.ERROR_AFTER_LOGIN, apiError: error });
    return;
  }
  if (isUserDuplicateEmailError(error)) {
    dispatch({ type: UserActionTypes.SET_DUPLICATE_EMAIL_USER, duplicateEmailError: true });
    return;
  }
  displayMessage(error.errorMessage, actionType, dispatch, displayInSnackBar, isAsyncAction, customMessage, snackBarViewDetailsAction, snackBarCloseAction);
}

function displayMessage(message: string, actionType: ActionTypes | undefined, dispatch: Dispatch, displayInSnackBar: boolean, isAsyncAction: boolean | undefined, customMessage: string | undefined, snackBarViewDetailsAction: any | undefined, snackBarCloseAction: any | undefined) {
  const messageToDisplay = customMessage !== undefined ? customMessage : message;
  if (displayInSnackBar) {
    dispatch({ type: GeneralActionTypes.SET_SNACKBAR_MESSAGE, snackBarMessage: getSnackBarMessage(messageToDisplay, actionType, isAsyncAction, snackBarViewDetailsAction, snackBarCloseAction) });
  } else {
    dispatch({ type: GeneralActionTypes.ERROR, errorMessage: messageToDisplay });
  }
}

function getSnackBarMessage(message: string, actionType: ActionTypes | undefined, isAsyncAction: boolean | undefined, snackBarViewDetailsAction: any | undefined, snackBarCloseAction: any | undefined): any {
  let payload: any = { message: message, type: ActionMessageType.Error };
  if (actionType !== undefined) {
    payload = { ...payload, message: ActionMessages[actionType].failMessage + " " + message };
  }
  if (isAsyncAction) {
    payload = { ...payload, isAsync: isAsyncAction };
  }
  if (snackBarViewDetailsAction !== undefined) {
    payload = { ...payload, action: snackBarViewDetailsAction };
  }
  if (snackBarCloseAction !== undefined) {
    payload = { ...payload, closeAction: snackBarCloseAction };
  }
  return payload;
}

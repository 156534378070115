import React, { useEffect, useState } from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import { Button, LinearProgress } from "@cuda-networks/bds-core";
import { IAppState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import IntegrationsAccountsTable from "../Accounts/IntegrationsAccountsTable";
import { getIntegrationAccountsAction, getIntegrationAccountsCompaniesAction, getIntegrationFilteredAccountsAction, linkAccountAction, setIntegrationAccountsTablePropsAction, setShowUnlinkedAccountsAction, unlinkAccountAction } from "../../../actions/integrations/integrationsAccountsActions";
import { Refresh, Warning } from "@material-ui/icons";
import { filtersPresent, getIntegrationAccountsActionName, getIntegrationFiltering, getIntegrationSorting } from "../../../Utilities/integrationsUtilities";
import IIntegrationAccount from "../../../models/Integrations/IIntegrationAccount";
import IntegrationsAccountsActionDialog from "./IntegrationsAccountsActionDialog";
import IntegrationAccountStatus from "../../../models/Integrations/IntegrationAccountStatus";
import IAccount from "../../../models/IAccount";
import { cancelCurrentAction, setSnackBarMessage } from "../../../actions/generalActions";
import { getNumberOfUnlinkedAccounts, getShowUnlinkedButtonLabel, getShowViewAccountsFilterButton } from "../../../businessLogic/components/Integrations/Accounts/IntegrationsAccountsTab";
import IIntegrationCompany from "../../../models/Integrations/IIntegrationCompany";
import ActionMessageType from "../../../models/ActionMessageType";
import { ActionMessages, ActionTypes } from "../../../actions/ActionTypes";

const IntegrationsAccountsTab = () => {
  const dispatch = useDispatch();
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const loadingIntegrationAccounts = useSelector((state: IAppState) => state.integrationsAccountsState.loadingIntegrationAccounts);
  const showUnlinkedAccounts = useSelector((state: IAppState) => state.integrationsAccountsState.showUnlinkedAccounts);
  const cwIntegrationInfo = useSelector((state: IAppState) => state.integrationsState.cwIntegrationInfo);
  const tableState = useSelector((state: IAppState) => state.integrationsAccountsState.integrationAccountsTableState);
  const integrationUnlinkedAccountsCount = useSelector((state: IAppState) => state.integrationsAccountsState.integrationUnlinkedAccountsCount);
  const [showUnlinkedLabel, setShowUnlinkedLabel] = useState<string>("");
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const [showIntegrationAccountActionDialog, setShowIntegrationAccountActionDialog] = useState(false);
  const [selectedIntegrationAccount, setSelectedIntegrationAccount] = useState<IIntegrationAccount | undefined>(undefined);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [showViewAccountFilterButton, setShowViewAccountFilterButton] = useState(false);
  const [noOfUnlinkedAccounts, setNoOfUnlinkedAccounts] = useState(0);

  const handleRefreshAccounts: any = () => {
    getNewData(showUnlinkedAccounts, false);
  };

  const handleShowUnlinked: any = () => {
    dispatch(setShowUnlinkedAccountsAction(!showUnlinkedAccounts));
    getNewData(!showUnlinkedAccounts, true);
  };

  const getNewData = (showUnlinkedOnly: boolean, resetFilters: boolean): void => {
    const { sortBy, asc } = getIntegrationSorting(tableState.sort);
    const filterBy = getIntegrationFiltering(tableState.filter);
    if (resetFilters || !filtersPresent(filterBy)) {
      dispatch(setIntegrationAccountsTablePropsAction({ skip: 0, take: tableState.take, sort: tableState.sort, filter: { logic: "and", filters: [] } }));
      dispatch(getIntegrationAccountsAction(selectedAccount, 0, tableState.take, sortBy, asc, showUnlinkedOnly));
    } else {
      dispatch(setIntegrationAccountsTablePropsAction({ skip: 0, take: tableState.take, sort: tableState.sort, filter: tableState.filter }));
      dispatch(getIntegrationFilteredAccountsAction(selectedAccount, filterBy.forAccount, filterBy.forCompany, showUnlinkedOnly));
    }
  };

  useEffect(() => {
    setNoOfUnlinkedAccounts(getNumberOfUnlinkedAccounts(integrationUnlinkedAccountsCount, cwIntegrationInfo));
  }, [cwIntegrationInfo, integrationUnlinkedAccountsCount]);

  useEffect(() => {
    setShowViewAccountFilterButton(getShowViewAccountsFilterButton(showUnlinkedAccounts, noOfUnlinkedAccounts));
    setShowUnlinkedLabel(getShowUnlinkedButtonLabel(showUnlinkedAccounts, noOfUnlinkedAccounts));
  }, [showUnlinkedAccounts, noOfUnlinkedAccounts]);

  const loadCompanies = (acc: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(getIntegrationAccountsCompaniesAction(acc));
      resolve(success);
    });

  const handleIntegrationAccountDialog = (integrationAccount: IIntegrationAccount): void => {
    setSelectedIntegrationAccount(integrationAccount);
    if (integrationAccount.status === IntegrationAccountStatus.Unlinked) {
      setLoadingCompanies(true);
      setShowIntegrationAccountActionDialog(true);
      loadCompanies(mspAccountLoggedIn).then(success => {
        if (!success) {
          setShowIntegrationAccountActionDialog(false);
        }
        setLoadingCompanies(false);
      });
    } else {
      setShowIntegrationAccountActionDialog(true);
    }
  };

  const handleOnCancelAccountsActionDialog = () => {
    setShowIntegrationAccountActionDialog(false);
    if (loadingCompanies) {
      dispatch(cancelCurrentAction());
    }
  };

  const linkAccount = (accId: number, companyId: number) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(linkAccountAction(accId, companyId));
      resolve(success);
    });

  const unlinkAccount = (accId: number) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(unlinkAccountAction(accId));
      resolve(success);
    });

  const handleSubmitAction = (selectedCompany?: IIntegrationCompany) => {
    setActionInProgress(true);
    if (selectedIntegrationAccount) {
      if (getIntegrationAccountsActionName(selectedIntegrationAccount) === "Link" && selectedCompany) {
        linkAccount(selectedIntegrationAccount.id as number, selectedCompany.id).then(success => {
          if (success) {
            handleRefreshAccounts();
            setShowIntegrationAccountActionDialog(false);
            dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.LinkIntegrationAccount].successMessage, type: ActionMessageType.Success }));
          }
          setActionInProgress(false);
        });
      } else {
        unlinkAccount(selectedIntegrationAccount.id as number).then(success => {
          if (success) {
            handleRefreshAccounts();
            setShowIntegrationAccountActionDialog(false);
            dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.UnlinkIntegrationAccount].successMessage, type: ActionMessageType.Success }));
          }
          setActionInProgress(false);
        });
      }
    }
  };

  return (
    <Grid item container spacing={3} direction="column" className={"IntegrationsAccountsTab"}>
      <Grid item xs={12}>
        {loadingIntegrationAccounts ? (
          <div data-testid={"loadingIntegrationAccounts"}>
            <LinearProgress />
          </div>
        ) : (
          <Grid item container spacing={3}>
            <Grid container item justifyContent="flex-end" style={{ marginLeft: "-30px" }}>
              <div style={{ display: "flex" }}>
                {showViewAccountFilterButton && (
                  <Button data-testid={"showUnlinkedOrAllButton"} color="secondary" variant={"contained"} size={"large"} style={{ marginRight: "15px" }} onClick={handleShowUnlinked} startIcon={!showUnlinkedAccounts && <Warning />} disabled={loadingIntegrationAccounts}>
                    {showUnlinkedLabel}
                  </Button>
                )}
                <Button data-testid={"refreshIntegrationAccountsButton"} variant={"contained"} size={"large"} style={{ marginRight: "15px" }} onClick={handleRefreshAccounts} disabled={loadingIntegrationAccounts} startIcon={<Refresh />}>
                  REFRESH ACCOUNTS
                </Button>
              </div>
            </Grid>

            <Grid data-testid="integrationsAccountsTable" container item xs={12} className={"enableHorizontalScrollbar"}>
              <IntegrationsAccountsTable openIntegrationAccountsActionDialog={handleIntegrationAccountDialog} />
            </Grid>
          </Grid>
        )}
      </Grid>
      {showIntegrationAccountActionDialog && <IntegrationsAccountsActionDialog showIntegrationsAccountsActionDialog={showIntegrationAccountActionDialog} isActionInProgress={actionInProgress} selectedIntegraionAccount={selectedIntegrationAccount} onCancel={handleOnCancelAccountsActionDialog} action={getIntegrationAccountsActionName(selectedIntegrationAccount)} onSubmitAction={handleSubmitAction} loadingCompanies={loadingCompanies} />}
    </Grid>
  );
};
export default IntegrationsAccountsTab;

import env from "@beam-australia/react-env";

const LivePerson = (callback: Function) => {
  if (env("ENABLE_CHAT") === "false") {
    return;
  }

  const elementId = "liveChat";
  const existingScript = document.getElementById(elementId);
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = "liveperson.js";
    script.id = elementId;

    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

export default LivePerson;

import { CancelTokenSource } from "axios";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import IAccount from "../../models/IAccount";
import { IIntegrationBillingMap, IIntegrationBillingMapFamily, IIntegrationBillingMapOption, IntegrationBllingMapType } from "../../models/Integrations/IntegrationsBillingMap";
import { getAdjustedPageNumberAfterAdd, getAdjustedPageNumberAfterDelete, getDateTimeForApiCall } from "../../utility";
import mspService from "../../service/mspService";
import { IAppState } from "../../store/store";
import { handleError } from "../actionsErrorHandler";
import { IPastPlanPurgePayload } from "../../models/Integrations/IPastPlanPurge";
import { computeBillingMapsFamilies, generateApiIntegrationBillingPayload } from "../../Utilities/integrationsBillingHelper";
import { State } from "@progress/kendo-data-query";
import produce from "immer";
import IAccountProducts from "../../models/Products/IAccountProducts";
import ProductFamily from "../../models/Products/ProductFamily";
import ProductNames from "../../models/Products/ProductNames";
import IProductFamily from "../../models/Products/IProductFamily";
import IProduct from "../../models/Products/IProduct";
import { cancelGeneralActionTokenAndCreateNew } from "../cancelAction";
import { ActionTypes } from "../ActionTypes";
import { LocalStoragePreferences, localStorageService } from "../../service/localStorageService";

export enum IntegrationsBillingMapActionTypes {
  GET_INTEGRATION_BILLING_MAP = "GET_INTEGRATION_BILLING_MAP",
  SET_LOAD_INTEGRATION_BILLING_MAP_CANCELED = "SET_LOAD_INTEGRATION_BILLING_MAP_CANCELED",
  RESET_PAST_PLANS = "RESET_PAST_PLANS",
  GET_INTEGRATION_BILLING_MAP_OPTIONS = "GET_INTEGRATION_BILLING_MAP_OPTIONS",
  SET_LOAD_INTEGRATION_BILLING_MAP_OPTIONS_CANCELED = "SET_LOAD_INTEGRATION_BILLING_MAP_OPTIONS_CANCELED",
  SET_BILLING_MAPS_TABLE_PROPS_FOR_IBU = "SET_BILLING_MAPS_TABLE_PROPS_FOR_IBU",
  SET_BILLING_MAPS_TABLE_PROPS_FOR_ESS = "SET_BILLING_MAPS_TABLE_PROPS_FOR_ESS",
  SET_BILLING_MAPS_TABLE_PROPS_FOR_CS = "SET_BILLING_MAPS_TABLE_PROPS_FOR_CS",
  SET_BILLING_MAPS_EXPANDED_STATUS = "SET_BILLING_MAPS_EXPANDED_STATUS",
  SET_SETUP_BILLING_MAP_TABLE_PAGE_NUMBER = "SET_SETUP_BILLING_MAP_TABLE_PAGE_NUMBER",
  SET_SETUP_BILLING_MAP_TABLE_PAGE_SIZE = "SET_SETUP_BILLING_MAP_TABLE_PAGE_SIZE",
  SET_INTEGRATION_BILLING_MAPS = "SET_INTEGRATION_BILLING_MAPS",
  RESET_INTEGRATION_BILLING_MAPS_STATE = "RESET_INTEGRATION_BILLING_MAPS_STATE",
}

export interface IResetIntegrationBillingMapStateAction {
  type: IntegrationsBillingMapActionTypes.RESET_INTEGRATION_BILLING_MAPS_STATE;
  ibuBillingMapTableState: State;
  essBillingMapTableState: State;
  csBillingMapTableState: State;
  expandedBillingMapStatus: Record<IntegrationBllingMapType, boolean>;
}

export interface IGetIntegrationBillingMapAction {
  type: IntegrationsBillingMapActionTypes.GET_INTEGRATION_BILLING_MAP;
  integrationBillingMaps: IIntegrationBillingMapFamily[];
  integrationBillingMapsOptions: IIntegrationBillingMapOption[];
  integrationBillingMapOrderlineItems: IIntegrationBillingMap[];
  loadingIntegrationBillingMap: boolean;
  integrationBillingUsageType: number;
}

export interface ICancelGetIntegrationBillingMapAction {
  type: IntegrationsBillingMapActionTypes.SET_LOAD_INTEGRATION_BILLING_MAP_CANCELED;
  loadingIntegrationBillingMapCanceled: boolean;
}

export interface IResetPastPlans {
  type: IntegrationsBillingMapActionTypes.RESET_PAST_PLANS;
  loadingResetPastPlans: boolean;
}

export interface ISetBillingMapsTablePropsForIBU {
  type: IntegrationsBillingMapActionTypes.SET_BILLING_MAPS_TABLE_PROPS_FOR_IBU;
  ibuBillingMapTableState: State;
}

export interface ISetBillingMapsTablePropsForESS {
  type: IntegrationsBillingMapActionTypes.SET_BILLING_MAPS_TABLE_PROPS_FOR_ESS;
  essBillingMapTableState: State;
}
export interface ISetBillingMapsTablePropsForCS {
  type: IntegrationsBillingMapActionTypes.SET_BILLING_MAPS_TABLE_PROPS_FOR_CS;
  csBillingMapTableState: State;
}

export interface ISetBillingMapsExpandedStatus {
  type: IntegrationsBillingMapActionTypes.SET_BILLING_MAPS_EXPANDED_STATUS;
  expandedBillingMapStatus: Record<IntegrationBllingMapType, boolean>;
}

export interface ISetSetupBillingMapTablePageNumber {
  type: IntegrationsBillingMapActionTypes.SET_SETUP_BILLING_MAP_TABLE_PAGE_NUMBER;
  setupBillingMapTablePageNumber: number;
}

export interface ISetSetupBillingMapTablePageSize {
  type: IntegrationsBillingMapActionTypes.SET_SETUP_BILLING_MAP_TABLE_PAGE_SIZE;
  setupBillingMapTablePageSize: number;
}

export interface ISetBillingMaps {
  type: IntegrationsBillingMapActionTypes.SET_INTEGRATION_BILLING_MAPS;
  integrationBillingMaps: IIntegrationBillingMapFamily[];
  loadingIntegrationBillingMap: boolean;
  integrationBillingUsageType: number;
}

export type IntegrationsBillingMapActions = IResetIntegrationBillingMapStateAction | IGetIntegrationBillingMapAction | ICancelGetIntegrationBillingMapAction | IResetPastPlans | ISetBillingMapsTablePropsForIBU | ISetBillingMapsTablePropsForESS | ISetBillingMapsTablePropsForCS | ISetBillingMapsExpandedStatus | ISetSetupBillingMapTablePageNumber | ISetSetupBillingMapTablePageSize | ISetBillingMaps;

export const getIntegrationBillingMapsAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetIntegrationBillingMapAction>> = (account: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const currentIntegrationBillingMaps = getState().integrationsBillingMapState.integrationBillingMaps;
    const currentIntegrationBillingMapOrderlineItems = getState().integrationsBillingMapState.integrationBillingMapOrderlineItems;
    const { mspAccountLoggedIn } = getState().generalState;
    try {
      const { apiUrl } = getState().generalState;
      dispatch({
        type: IntegrationsBillingMapActionTypes.GET_INTEGRATION_BILLING_MAP,
        integrationBillingMaps: [],
        integrationBillingMapOrderlineItems: [],
        loadingIntegrationBillingMap: true,
        integrationBillingUsageType: 0,
      });
      dispatch({
        type: IntegrationsBillingMapActionTypes.SET_LOAD_INTEGRATION_BILLING_MAP_CANCELED,
        loadingIntegrationBillingMapCanceled: false,
      });
      const newCancelTokenSource = cancelGeneralActionTokenAndCreateNew(getState, dispatch);
      const billingMaps: any = await mspService.loadIntegrationBillingMaps(apiUrl, account.id, newCancelTokenSource.token);
      const options: any = await mspService.loadIntegrationBillingMapsOptions(apiUrl, account.id, newCancelTokenSource.token);
      let orderlineItems: any = await getIntegrationBillingMapOrderLineItem(getState, mspAccountLoggedIn, apiUrl, account, newCancelTokenSource);
      const result = computeBillingMapsFamilies(billingMaps, options.list, orderlineItems);
      dispatch({
        type: IntegrationsBillingMapActionTypes.GET_INTEGRATION_BILLING_MAP,
        integrationBillingMaps: result,
        integrationBillingMapsOptions: options.list,
        integrationBillingMapOrderlineItems: orderlineItems,
        loadingIntegrationBillingMap: false,
        integrationBillingUsageType: billingMaps.usageType,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: IntegrationsBillingMapActionTypes.GET_INTEGRATION_BILLING_MAP,
            integrationBillingMap: currentIntegrationBillingMaps,
            integrationBillingMapOrderlineItems: currentIntegrationBillingMapOrderlineItems,
            loadingIntegrationBillingMap: false,
            integrationBillingUsageType: 0,
          });
        },
        () => {
          dispatch({
            type: IntegrationsBillingMapActionTypes.SET_LOAD_INTEGRATION_BILLING_MAP_CANCELED,
            loadingIntegrationBillingMapCanceled: true,
          });
        },
      );
    }
  };
};

export const resetPastPlansAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IResetPastPlans>> = (account: IAccount, accountId: number) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      dispatch({
        type: IntegrationsBillingMapActionTypes.RESET_PAST_PLANS,
        loadingResetPastPlans: true,
      });
      const currentDate = new Date();
      const date: string = getDateTimeForApiCall(currentDate);
      const pastPlanPurge: IPastPlanPurgePayload = { pastPlanPurge: [{ accountId: accountId, date }] };
      await mspService.resetPastPlans(apiUrl, account.id, pastPlanPurge);
      dispatch({
        type: IntegrationsBillingMapActionTypes.RESET_PAST_PLANS,
        loadingResetPastPlans: false,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: IntegrationsBillingMapActionTypes.RESET_PAST_PLANS,
            loadingResetPastPlans: false,
          });
        },
        () => {},
        true,
        ActionTypes.ResetPastPlans,
      );
    }
  };
};

export const setBillingMapsTablePropsForIBU: ActionCreator<ThunkAction<any, IAppState, null, ISetBillingMapsTablePropsForIBU>> = (ibuBillingMapTableState: State) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { id } = getState().generalState.loggedUser;
    localStorageService.setItem(id.toString(), LocalStoragePreferences.INTEGRATIONS_BILLING_IBU_UI, JSON.stringify(ibuBillingMapTableState));
    dispatch({ type: IntegrationsBillingMapActionTypes.SET_BILLING_MAPS_TABLE_PROPS_FOR_IBU, ibuBillingMapTableState: { ...ibuBillingMapTableState, skip: 0 } });
  };
};

export const setBillingMapsTablePropsForESS: ActionCreator<ThunkAction<any, IAppState, null, ISetBillingMapsTablePropsForESS>> = (essBillingMapTableState: State) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { id } = getState().generalState.loggedUser;
    localStorageService.setItem(id.toString(), LocalStoragePreferences.INTEGRATIONS_BILLING_ESS_UI, JSON.stringify(essBillingMapTableState));
    dispatch({ type: IntegrationsBillingMapActionTypes.SET_BILLING_MAPS_TABLE_PROPS_FOR_ESS, essBillingMapTableState: { ...essBillingMapTableState, skip: 0 } });
  };
};

export const setBillingMapsTablePropsForCS: ActionCreator<ThunkAction<any, IAppState, null, ISetBillingMapsTablePropsForCS>> = (csBillingMapTableState: State) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { id } = getState().generalState.loggedUser;
    localStorageService.setItem(id.toString(), LocalStoragePreferences.INTEGRATIONS_BILLING_CS_UI, JSON.stringify(csBillingMapTableState));
    dispatch({ type: IntegrationsBillingMapActionTypes.SET_BILLING_MAPS_TABLE_PROPS_FOR_CS, csBillingMapTableState: { ...csBillingMapTableState, skip: 0 } });
  };
};

export const setBillingMapsExpandedStatusAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetBillingMapsExpandedStatus>> = (integrationBllingMapType: IntegrationBllingMapType, isExpanded: boolean) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { expandedBillingMapStatus } = getState().integrationsBillingMapState;
    const nextStateExpandedStatus = produce(expandedBillingMapStatus, draft => {
      draft[integrationBllingMapType] = isExpanded;
    });
    dispatch({
      type: IntegrationsBillingMapActionTypes.SET_BILLING_MAPS_EXPANDED_STATUS,
      expandedBillingMapStatus: nextStateExpandedStatus,
    });
    return true;
  };
};

async function getIntegrationBillingMapOrderLineItem(getState: () => IAppState, mspAccountLoggedIn: IAccount, apiUrl: string, account: IAccount, newCancelTokenSource: CancelTokenSource) {
  let orderlineItems: IIntegrationBillingMap[] = [];
  const { accountsProducts } = getState().productState;
  const accountIndex = accountsProducts.findIndex((x: IAccountProducts) => x.accountId === mspAccountLoggedIn.id);
  if (accountIndex > -1) {
    const cachedOrderLineItems = accountsProducts[accountIndex];
    cachedOrderLineItems.productFamilies.forEach(prodFamily => {
      orderlineItems.push(...setIntegrationsBillingMap(prodFamily));
    });
  } else {
    const ordersResult = await mspService.loadOrders(apiUrl, account.id, newCancelTokenSource.token, true);
    const newData: IProductFamily[] = await mspService.loadSerialsAndComputeProducts(apiUrl, ordersResult, account.id, account.type, [], newCancelTokenSource.token, false);
    newData.forEach(prodFamily => {
      orderlineItems.push(...setIntegrationsBillingMap(prodFamily));
    });
  }

  return orderlineItems;
}

function setIntegrationsBillingMap(prodFamily: IProductFamily): IIntegrationBillingMap[] {
  let orderlineItems: IIntegrationBillingMap[] = [];
  if (prodFamily.productType === ProductFamily.ESSENTIALS_SERIVICES) {
    prodFamily.products.forEach(product => {
      const orderLineItemFromProduct = getBillingMapOrderLineItem(product, IntegrationBllingMapType.ESSENTIALS_SERIVICES);
      if (orderLineItemFromProduct) {
        orderlineItems.push(orderLineItemFromProduct);
      }
    });
  } else if (prodFamily.productType === ProductFamily.CONTENT_SHIELD) {
    prodFamily.products.forEach(product => {
      if (product.name.includes(ProductNames.BarracudaContentShieldPlus)) {
        const orderLineItemFromProduct = getBillingMapOrderLineItem(product, IntegrationBllingMapType.CONTENT_SHIELD_PLUS);
        if (orderLineItemFromProduct) {
          orderlineItems.push(orderLineItemFromProduct);
        }
      }
    });
  }
  return orderlineItems;
}

function getBillingMapOrderLineItem(product: IProduct, type: IntegrationBllingMapType): IIntegrationBillingMap | undefined {
  if (product.partnerSkuId) {
    return { id: product.partnerSkuId, name: product.name, subname: product.subname, type };
  } else {
    console.warn("orderline item does not have partkerSkudId");
  }
}

export const setIntegrationSetupBillingTablePageNumberAction: ActionCreator<ThunkAction<any, IAppState, null, ISetSetupBillingMapTablePageNumber>> = (setupBillingMapTablePageNumber: number) => (dispatch: Dispatch) => dispatch({ type: IntegrationsBillingMapActionTypes.SET_SETUP_BILLING_MAP_TABLE_PAGE_NUMBER, setupBillingMapTablePageNumber });
export const setIntegrationSetupBillingTablePageSizeAction: ActionCreator<ThunkAction<any, IAppState, null, ISetSetupBillingMapTablePageSize>> = (setupBillingMapTablePageSize: number) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { id } = getState().generalState.loggedUser;
    localStorageService.setItem(id.toString(), LocalStoragePreferences.INTEGRATIONS_BILLING_SETUP, setupBillingMapTablePageSize.toString());
    dispatch({ type: IntegrationsBillingMapActionTypes.SET_SETUP_BILLING_MAP_TABLE_PAGE_SIZE, setupBillingMapTablePageSize });
  };
};

export const adjustSetupBillingTablePageAfterAddDeleteAction: ActionCreator<ThunkAction<any, IAppState, null, ISetSetupBillingMapTablePageNumber>> = (noOfItems: number, isDelete: boolean) => {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const { setupBillingMapTablePageNumber } = getState().integrationsBillingMapState;
    const { setupBillingMapTablePageSize } = getState().integrationsBillingMapState;
    let adjustedAccountsPageNumber: number = 0;
    if (isDelete) {
      adjustedAccountsPageNumber = getAdjustedPageNumberAfterDelete(noOfItems, setupBillingMapTablePageSize, setupBillingMapTablePageNumber);
    } else {
      adjustedAccountsPageNumber = getAdjustedPageNumberAfterAdd(noOfItems, setupBillingMapTablePageSize);
    }

    dispatch({ type: IntegrationsBillingMapActionTypes.SET_SETUP_BILLING_MAP_TABLE_PAGE_NUMBER, setupBillingMapTablePageNumber: adjustedAccountsPageNumber });
  };
};

export const setBillingMapsAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetBillingMaps>> = (account: IAccount, billingMapsList: IIntegrationBillingMap[]) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const currentIntegrationBillingMaps = getState().integrationsBillingMapState.integrationBillingMaps;
    try {
      const { apiUrl } = getState().generalState;
      const { integrationBillingMaps } = getState().integrationsBillingMapState;
      const { integrationBillingUsageType } = getState().integrationsBillingMapState;
      const { integrationBillingMapOrderlineItems } = getState().integrationsBillingMapState;
      const { integrationBillingMapsOptions } = getState().integrationsBillingMapState;
      dispatch({
        type: IntegrationsBillingMapActionTypes.SET_INTEGRATION_BILLING_MAPS,
        integrationBillingMaps: integrationBillingMaps,
        loadingIntegrationBillingMap: true,
        integrationBillingUsageType: 0,
      });
      const billingMapsPayload = generateApiIntegrationBillingPayload(billingMapsList, integrationBillingUsageType);
      const result = await mspService.setIntegrationBillingMaps(apiUrl, account.id, billingMapsPayload);
      const finalResult = computeBillingMapsFamilies(result, integrationBillingMapsOptions, integrationBillingMapOrderlineItems);
      dispatch({
        type: IntegrationsBillingMapActionTypes.SET_INTEGRATION_BILLING_MAPS,
        integrationBillingMaps: finalResult,
        loadingIntegrationBillingMap: false,
        integrationBillingUsageType: result.usageType,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: IntegrationsBillingMapActionTypes.SET_INTEGRATION_BILLING_MAPS,
            integrationBillingMaps: currentIntegrationBillingMaps,
            loadingIntegrationBillingMap: false,
            integrationBillingUsageType: 0,
          });
        },
        () => {
          /* no action needed*/
        },
        true,
        ActionTypes.SetBillingMaps,
      );
    }
  };
};

export const setIntegrationBillingTablePropsActionToFirstPage: ActionCreator<ThunkAction<any, IAppState, null, IResetPastPlans>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { id } = getState().generalState.loggedUser;
    const { ibuBillingMapTableState, essBillingMapTableState, csBillingMapTableState } = getState().integrationsBillingMapState;
    ibuBillingMapTableState.skip = 0;
    essBillingMapTableState.skip = 0;
    csBillingMapTableState.skip = 0;
    localStorageService.setItem(id.toString(), LocalStoragePreferences.INTEGRATIONS_BILLING_IBU_UI, JSON.stringify(ibuBillingMapTableState));
    localStorageService.setItem(id.toString(), LocalStoragePreferences.INTEGRATIONS_BILLING_ESS_UI, JSON.stringify(essBillingMapTableState));
    localStorageService.setItem(id.toString(), LocalStoragePreferences.INTEGRATIONS_BILLING_CS_UI, JSON.stringify(csBillingMapTableState));
    dispatch({
      type: IntegrationsBillingMapActionTypes.RESET_INTEGRATION_BILLING_MAPS_STATE,
      ibuBillingMapTableState,
      essBillingMapTableState,
      csBillingMapTableState,
      expandedBillingMapStatus: {
        [IntegrationBllingMapType.ESSENTIALS_SERIVICES]: true,
        [IntegrationBllingMapType.CONTENT_SHIELD_PLUS]: true,
        [IntegrationBllingMapType.IBU_PLANS]: true,
      },
    });
  };
};

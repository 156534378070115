import { DataTable, DataTableColumn as Column, CircularProgress } from "@cuda-networks/bds-core";
import { process } from "@progress/kendo-data-query";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IProductSerial from "../../../models/Products/IProductSerial";
import { IAppState } from "../../../store/store";
import { getButtonCount, isNotFinance } from "../../../utility";
import ButtonCommandCell from "../../ButtonCommandCell";
import TooltipElement from "../../Users/TooltipElement";
import AssignedProductsStatusCell from "./AssignedProductsStatusCell";
import Pager from "@cuda-networks/bds-core/dist/DataTable/Pager";
import { cancelGetSubpartnersWithProductsAssignedAction, getProductsForSubpartnersAction, setLoadingProductsForSubpartner } from "../../../actions/productActions";
import IAccount from "../../../models/IAccount";
import { Backdrop, useMediaQuery } from "@material-ui/core";
import SearchSubpartner from "./SearchSubpartner";
import { LocalStoragePreferences, localStorageService } from "../../../service/localStorageService";

interface ISerialsTableProps {
  onGoToAccount: (accountId: number) => void;
  onFocusedSearch: (isFocused: boolean) => void;
  showLoading: boolean;
}

const AssignedProductsTable: React.FC<ISerialsTableProps> = ({ onGoToAccount, onFocusedSearch, showLoading }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state: IAppState) => state.generalState.loggedUser.id);
  const subpartnersWithProducts = useSelector((state: IAppState) => state.productState.subpartnersWithProducts);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const loadingProductsForSubpartners = useSelector((state: IAppState) => state.productState.loadingProductsForSubpartners);
  const [buttonCount, setButtonCount] = useState(10);
  const responsiveViewPortTriggerMin = useMediaQuery("(min-width: 1600px)");
  const [prevSkip, setPrevSkip] = useState(0);
  const [searchedSubpartner, setSearchedSubpartner] = useState("");
  const [tableState, setTableState] = useState(() => {
    const defaultSettings = { sort: [{ field: "accountName", dir: "asc" }], take: 10, skip: 0, filter: { logic: "and", filters: [] } };
    return localStorageService.getItem(userId.toString(), LocalStoragePreferences.SUBPARTNER_CATALOG_UI, defaultSettings);
  });

  const dataState = {
    skip: tableState.skip,
    take: tableState.take,
    sort: tableState.sort,
    group: [],
    filter: tableState.filter,
    collapsedGroups: [],
    selectedItem: "any",
    lastSelectedIndex: 0,
    columns: [
      {
        title: "SUBPARTNER",
        field: "accountName",
        show: true,
        filter: "text",
      },
      {
        title: "PRODUCTS",
        field: "totalProducts",
        show: true,
        filter: "text",
      },
      {
        title: "STATUS",
        field: "totalErrors",
        show: false,
        filter: "text",
      },
      {
        title: "ACTIONS",
        field: "actions",
        show: false,
        filter: "text",
      },
    ],
  };

  const [gridState, setGridState] = useState({
    dataState,
    dataResult: process(subpartnersWithProducts, dataState as any),
  });

  const enterEdit = (serial: IProductSerial): void => {
    onGoToAccount(serial.accountId);
  };

  const getProductsForSubpartners = (subpartners: IAccount[]) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getProductsForSubpartnersAction(subpartners));
      resolve(result);
    });

  const dataStateChange = (e: any): void => {
    const displaySubpartners = process(subpartnersWithProducts, e.dataState);
    getProductsForSubpartners(displaySubpartners.data).then(success => {
      if (success) {
        const f = { logic: "and", filters: [{ field: "accountName", operator: "contains", value: searchedSubpartner }] };
        setTableState({ sort: tableState.sort, take: tableState.take, skip: tableState.skip, filter: f as any });
        setGridState({
          dataState: { ...dataState, ...e.dataState },
          dataResult: displaySubpartners,
        });
      }
    });
  };

  const onSubpartnerSearched = (filterString: string): void => {
    setSearchedSubpartner(filterString);
  };

  const onSearchCanceled = (): void => {
    dispatch(cancelGetSubpartnersWithProductsAssignedAction());
    dispatch(setLoadingProductsForSubpartner(false));
  };

  useEffect(() => {
    setTableState({ sort: gridState.dataState.sort, take: gridState.dataState.take, skip: gridState.dataState.skip, filter: gridState.dataState.filter });
    setGridState({ dataState: gridState.dataState, dataResult: process(subpartnersWithProducts, gridState.dataState as any) });
    localStorageService.setItem(userId.toString(), LocalStoragePreferences.SUBPARTNER_CATALOG_UI, JSON.stringify({ ...gridState.dataState, skip: 0 }));
  }, [subpartnersWithProducts, gridState.dataState, userId]);

  useEffect(() => {
    let ds: any;
    if (searchedSubpartner.length > 0) {
      setPrevSkip(gridState.dataState.skip);
      ds = { ...gridState.dataState, skip: 0, filter: { logic: "and", filters: [{ field: "accountName", operator: "contains", value: searchedSubpartner }] } };
    } else {
      ds = { ...gridState.dataState, skip: prevSkip, filter: { logic: "and", filters: [] } };
    }
    dataStateChange({ dataState: ds });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedSubpartner]);

  useEffect(() => {
    setButtonCount(getButtonCount(gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin));
  }, [gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin]);

  const GoToCommandCell = (props: any) => <ButtonCommandCell {...props} dataTestId={"goToAccount"} action={enterEdit} label={"GO TO"} isOutlined={false} color={"secondary"} />;
  const TooltipTable = (props: any, tooltipType: any) => <TooltipElement {...props} tooltipType={"table"} />;
  const ProductStatus = (props: any) => <AssignedProductsStatusCell {...props} />;
  const handleOnFocusSearch = (isFocused: boolean) => {
    onFocusedSearch(isFocused);
  };

  return (
    <div>
      <SearchSubpartner data-testid="searchSubpartner" onSubpartnerSearched={(filter: string) => onSubpartnerSearched(filter)} onCancelSearch={onSearchCanceled} onFocusSearch={handleOnFocusSearch} />
      <div style={{ position: "relative" }}>
        <Backdrop className={"parentOpacity"} open={showLoading || loadingProductsForSubpartners} style={{ position: "absolute", zIndex: 4000 }}>
          <CircularProgress data-testid="loadingProductsForSubpartners" size="80px" style={{ zIndex: 4001 }} />
        </Backdrop>
        <DataTable
          className={"AssignedProductsTable noScrollbar noBorders"}
          data={gridState.dataResult}
          resizable
          sortable
          // page
          pageConfig={{
            pageable: {
              pageSizes: [10, 25, 50],
              buttonCount: buttonCount,
            },
            skip: tableState.skip,
            take: tableState.take,
            total: gridState.dataResult.total,
          }}
          filter={gridState.dataState.filter}
          sortConfig={{
            sortable: true,
            sort: tableState.sort,
          }}
          pager={gridState.dataResult.data.length > 0 && Pager}
          onDataStateChange={dataStateChange}
          selectedField="selected"
          {...(gridState.dataState as any)}
        >
          <Column key={"subpartenerAssignedAccountName"} field={"accountName"} title={"SUBPARTNER"} cell={TooltipTable} minResizableWidth={30} width={180} resizable={true} sortable={true} filterable={false} />
          <Column key={"subpartenerAssignedAccountProducts"} field={"totalProducts"} title={"PRODUCTS"} cell={TooltipTable} minResizableWidth={30} width={180} resizable={true} sortable={false} filterable={false} />
          <Column key={"subpartenerAssignedStatus"} title={"STATUS"} cell={ProductStatus} minResizableWidth={30} width={180} groupable={true} filterable={false} sortable={false} />
          {isNotFinance(mspAccountLoggedIn) ? <Column key={"subpartenerAssignedActions" + gridState.dataState.columns.length} title={"ACTIONS"} cell={GoToCommandCell} width={120} sortable={false} resizable={false} filterable={false} /> : null}
        </DataTable>
      </div>
    </div>
  );
};

export default AssignedProductsTable;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import "../../App";
import { setError } from "../../actions/generalActions";
import { IAppState } from "../../store/store";
import { logout } from "../../utility";
import WelcomeMessage from "./WelcomeMessage";
import { Grid, Paper } from "@cuda-networks/bds-core";
import IUser from "../../models/IUser";
import { addNewPartnerUserAction, loadUserEntitlementsForAccount, setDuplicateEmailErrorAction } from "../../actions/userActions";
import IAccount from "../../models/IAccount";
import AddEditLoginUserDialog from "../Users/AddEditUser/AddEditLoginUserDialog";
import ErrorMessage from "../ErrorMessage";
import BarracudaMspLogo from "../../assets/barracudaMSPLogo.jpg";
import UserRole from "../../models/UserRole";

const NewUser = () => {
  const dispatch = useDispatch();
  const errorMessage = useSelector((state: IAppState) => state.generalState.errorMessage);
  const hasUsers = useSelector((state: IAppState) => state.generalState.hasUsers);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const loggedUser = useSelector((state: IAppState) => state.generalState.loggedUser);
  const [app, setApp] = useState<React.JSX.Element>(<div></div>);
  const [showNewUserForm, setShowNewUserForm] = useState(false);
  const [isActionInProgress, setIsActionInProgress] = useState(false);

  useEffect(() => {
    let buttonCallback;
    if (hasUsers !== undefined) {
      if (!hasUsers) {
        dispatch(loadUserEntitlementsForAccount(loggedUser.id, mspAccountLoggedIn.id));
      }
      buttonCallback = hasUsers ? () => logout() : () => setShowNewUserForm(true);
      setApp(<WelcomeMessage newUserAdded={""} alreadyHasUsers={hasUsers} username={mspAccountLoggedIn.name} callback={buttonCallback} />);
    } else {
      if (mspAccountLoggedIn.id > 0) {
        setApp(<div />);
      } else {
        setApp(<Navigate to="/" />);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUsers]);

  const addUser = (acc: IAccount, user: IUser) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(addNewPartnerUserAction(acc, user));
      resolve(success);
    });

  const handleOnSumbitClicked = (user: IUser) => {
    setIsActionInProgress(true);
    addUser(mspAccountLoggedIn, user).then(success => {
      setIsActionInProgress(false);
      if (success) {
        dispatch(setDuplicateEmailErrorAction(false));
        setShowNewUserForm(false);
        setApp(<WelcomeMessage newUserAdded={user.email} alreadyHasUsers={false} username={mspAccountLoggedIn.name} callback={() => logout()} />);
      }
    });
  };

  const handleOnCancel = () => {
    dispatch(setDuplicateEmailErrorAction(false));
    setShowNewUserForm(false);
  };

  const onCloseMessage = () => {
    dispatch(setError(""));
  };

  return (
    <div data-testid={"newUserPage"} className="mspBackground">
      {errorMessage && errorMessage.length > 0 && <ErrorMessage message={errorMessage} onCloseMessage={onCloseMessage} />}
      <Grid container xs={12} className="mspBackgroundContainer">
        {showNewUserForm ? (
          <AddEditLoginUserDialog
            showDialog={showNewUserForm}
            isEdit={false}
            user={undefined}
            dialogTitle={"ADD LOGIN"}
            onDelete={() => {
              /*no action*/
            }}
            onCancel={handleOnCancel}
            isActionInProgress={isActionInProgress}
            isAddFirstUser={true}
            onSubmit={(user: IUser) => handleOnSumbitClicked({ ...user, role: UserRole.Admin })}
            userParentAccount={mspAccountLoggedIn}
          />
        ) : (
          <Paper elevation={3} style={{ width: "460px" }}>
            <img src={BarracudaMspLogo} alt="Icon Layers" style={{ width: "100%", padding: "26px 26px 0px 26px" }} className="center" />
            {app}
          </Paper>
        )}
      </Grid>
    </div>
  );
};

export default NewUser;

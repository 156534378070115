import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Checkbox } from "@cuda-networks/bds-core";
import React, { useState } from "react";
import { enterKey, handleBackdropClick } from "../../../utility";
import IProduct from "../../../models/Products/IProduct";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import BackTo from "../../BackTo";

interface ISubmitDeactivateDialogProps {
  onBack: () => void;
  onCancel: () => void;
  onSubmitDeleteSerial: () => void;
  showDeleteSerialDialog: boolean;
  isActionInProgress: boolean;
  selectedProduct: IProduct | undefined;
}
const ShowDeleteSerialDialog: React.FC<ISubmitDeactivateDialogProps> = ({ showDeleteSerialDialog, onBack, onSubmitDeleteSerial, isActionInProgress, selectedProduct, onCancel }) => {
  const [shouldDeleteSerial, setShouldDeleteSerial] = useState(false);
  const onHandleCheckDeleteSerial = (checked: boolean) => {
    setShouldDeleteSerial(checked);
  };
  return (
    <Dialog onKeyUp={(event: any) => enterKey(event, onBack)} disableEscapeKeyDown={isActionInProgress} className="deactivateDialog" data-testid="deleteSerialDialog" open={showDeleteSerialDialog} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onBack)}>
      <DialogTitle data-testid="deleteSerialTitle"> {"Confirm Deletion of " + selectedProduct?.serial} </DialogTitle>
      <DialogContent>
        <div style={{ width: "530px" }}>
          <Grid container alignItems="center" direction="row" style={{ paddingBottom: "10px" }}>
            <Grid item xs={12} style={{ padding: "0px 6px 6px 6px" }}>
              <BackTo
                text={`Edit ${selectedProduct?.subname}`}
                callback={(e: any) => {
                  isActionInProgress ? e.preventDefault() : onBack();
                }}
                testId="goBackToEditSerial"
                disable={isActionInProgress}
              />
            </Grid>
            <Grid item className={"DeleteSerialCheckboxGrid"}>
              <Checkbox data-testid="deleteSerialCheckbox" size="medium" checked={shouldDeleteSerial} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onHandleCheckDeleteSerial(ev.currentTarget.checked)} />
            </Grid>
            <Grid item xs>
              <Typography data-testid="deleteSerialMessage" variant="subtitle2">
                I am aware that deleting this serial number is a permanent action that cannot be undone. All usage data associated with this serial number will be deleted.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <div>
          <Button variant="text" data-testid="closeDeleteSerialDialog" size="large" onClick={onCancel} disabled={isActionInProgress}>
            CANCEL
          </Button>
          <Button data-testid="confirmDeleteSerial" primary="true" isLoading={isActionInProgress} disabled={isActionInProgress || !shouldDeleteSerial} type={"submit"} onClick={onSubmitDeleteSerial}>
            CONFIRM
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ShowDeleteSerialDialog;
